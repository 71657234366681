import React, { MouseEvent, ChangeEvent, useState, useEffect, useContext } from 'react';
import { BorderExternal, BorderWhite, WalletIcon, InputAdd, WalletHoverItem } from '../../components/Faucet/Index';
import Success from '../../components/Faucet/Successful';
import AdvancedSwapDetailsDropdown from '../../components/Faucet/DropDown';
import { RowFlat, RowBetween } from '../../components/Row';
import { Text } from 'rebass';
import { ButtonPrimary } from '../../components/Button';
import Loader from '../../components/Loader';
import { useActiveWeb3React } from '../../hooks';
import { useTranslation } from 'react-i18next';
// import { useWalletModalToggle } from '../../state/application/hooks';
import { isAddress } from '../../utils';
import { useCurrencyBalance } from '../../state/wallet/hooks';
import { useHttp } from '../../hooks/useHttps';
import { ETHER } from '@huckleberry/sdk';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components'
import { ThemeContext } from 'styled-components';

const FaucetCon = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`

declare global {
  interface Window {
    grecaptcha: any;
  }
}
export default function Faucet() {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const faucetAddress = '0x30b4A1Fb82172bFc9dA35195FE0C42241b3BbD5F';
  let api = useHttp();
  
  const balance = useCurrencyBalance(faucetAddress, ETHER);
  let faucetNum = balance ? balance.toSignificant(6) : 0;
  // console.log('faucetNum', faucetNum, balance, typeof balance)

  let [myAddress, setMyAddress] = useState('');
  let [showRobot, setShowRobot] = useState(0);
  let [canGetMovr, setCanGetMovr] = useState(0);
  let [transactionId, setTransactionId] = useState('');
  let [load, setLoad] = useState(0);
  
  let [succ, setSucc] = useState((-1));
  // const toggleWalletModal = useWalletModalToggle();

  let grecaptcha = window.grecaptcha;
  let [googleId, setGoogleId] = useState(-1);

  const getMovrFn = async (event: MouseEvent) => {
    event.preventDefault();
    if (!canGetMovr) return;
    if (load) return;
    setLoad(1);
    if (googleId === -1) {
      setGoogleId(grecaptcha.render('robot', {
        'sitekey': '6Ld69MUcAAAAAM7vobtrF5MhDk0ULrGr_ovqPOxQ',
        'theme': 'light',
        'size': 'normal',
        'callback': successFn,
        'expired-callback': expiredFn,
        'error-callback': errorFn
      }));
    } else {
      grecaptcha.reset(googleId);
    }
    setShowRobot(1);
  }

  useEffect(() => {
    setCanGetMovr(+isAddress(myAddress))
  }, [myAddress]);

  const successFn = async (args:any) => {
    // console.log('success');
    setShowRobot(0);
    let resData = await api({
      url: 'https://api.huckleberry.finance/api/faucet',
      params: {
        token: args,
        address: myAddress
      }
    })
    // console.log(resData)
    // console.log(resData.success, typeof resData.success)
    if (resData.success) {
      setTransactionId(resData.transactionHash);
      setSucc(1);
    } else {
      setSucc(0);
    }
    setLoad(0);
  };
  const expiredFn = (args:any) => {
    setLoad(0)
    // console.log(args)
    // console.log('expired');
  };
  const errorFn = (args:any) => {
    setLoad(0)
    // console.log(args)
    // console.log('error');
  };

  return (
    <FaucetCon>
      {
        succ < 1 ?
          <BorderExternal style={{backgroundColor: theme.faucetBg, backdropFilter: 'blur(20px)', margin: '0 auto', borderColor: theme.faucetBorder}}>
            <Text fontSize={24} fontWeight={'bold'} color={'#fff'} marginBottom={'28px'}>Faucet</Text>
            <BorderWhite style={{marginBottom: '20px'}}>
              <Text fontSize={14} fontWeight={400} color={'#FFC70B'} lineHeight={'22px'} marginBottom={'10px'}>{t('faucetPresentation')}</Text>
              <Text fontSize={12} fontWeight={400} color={'#FFC70B'} lineHeight={'22px'} marginBottom={'1.25rem'} paddingBottom={'1.25rem'} style={{borderBottom: '1px solid rgba(255, 255, 255, 0.15)'}}>{t('faucePrompt')}</Text>
              <Text fontSize={16} fontWeight={400} color={'#fff'} marginBottom={'12px'}>{t('faucetAddress')}</Text>
              <Text fontSize={16} fontWeight={400} color={'#fff'} marginBottom={'1.25rem'} paddingBottom={'1.25rem'} style={{borderBottom: '1px solid rgba(255, 255, 255, 0.15)', wordBreak: 'break-all'}}>{faucetAddress}</Text>
              <RowBetween>
                <Text fontSize={16} fontWeight={400} color={'#fff'}>{t('faucetBalance')}</Text>
                <RowFlat>
                  <Text fontSize={18} fontWeight={400} color={!!faucetNum ? '#4ABDDD' : '#fff'}>{faucetNum}</Text>
                  <Text fontSize={12} fontWeight={400} color={'#fff'}>&nbsp;{t('faucetMovr')}</Text>
                </RowFlat>
              </RowBetween>
            </BorderWhite>
            <BorderExternal style={{marginBottom: '30px', padding: '20px 30px'}}>
              <RowBetween>
                <RowBetween style={{flexDirection: 'column', alignItems: 'start', flex: 1, paddingRight: '14px'}}>
                  <Text fontSize={16} fontWeight={400} color={'#fff'} marginBottom={'12px'}>{t('myAddress')}</Text>
                  <InputAdd value={myAddress} placeholder={t('faucetAddrHolder')} onChange={(event: ChangeEvent<HTMLInputElement>) => setMyAddress(event.target.value)} />
                </RowBetween>
                <WalletIcon isConnect={Boolean(account)} onClick={() => !!account? setMyAddress(account) : void 0}>
                  <WalletHoverItem>
                    {t('faucetWalletHT')}
                  </WalletHoverItem>
                </WalletIcon>
              </RowBetween>
            </BorderExternal>
            {/* {
              !account ?
                <>
                  <div style={{padding: '0 30px 20px'}}>
                    <Text fontSize={14} fontWeight={400} color={"#00CF1F"} lineHeight={'22px'}>A Faucet is a tool that provides a small amount of MOVR for users that used the bridge to start using Solarbeam.io </Text>
                  </div>
                  <ButtonLight onClick={toggleWalletModal}>{t('connectWallet')}</ButtonLight>
                </>
              :
                <ButtonPrimary disabled={Boolean(canGetMovr)} onClick={(event: MouseEvent) => getMovrFn(event)}>{t('getMovr')}</ButtonPrimary>
            } */}
            <div style={{padding: '0 30px 20px'}}>
              <Text fontSize={14} fontWeight={400} color={"#00CF1F"} lineHeight={'22px'} style={{wordBreak: 'break-all'}}>{t('faucetMovrToFeaucet')}</Text>
            </div>
            <RowFlat style={{marginBottom: '20px', display: showRobot ? 'block' : 'none'}}>
              <div
                id="robot"
                className="g-recaptcha"
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'row' : 'row-reverse',
                  maxWidth: 'calc(100vw - 80px - 34px)',
                  transform: isMobile ? 'scale(0.7)' : 'scale(1)'
                }}
              ></div>
            </RowFlat>
            <ButtonPrimary
              style={{cursor: !Boolean(canGetMovr) || Boolean(load) ? 'not-allowed' : 'pointer' }}
              onClick={(event: MouseEvent) => getMovrFn(event)}
            >
              {t('getMovr')}&nbsp;&nbsp;&nbsp;&nbsp;
              {load ? <Loader /> : null}
            </ButtonPrimary>
            <AdvancedSwapDetailsDropdown show={Number(succ) === 0} detail={t('faucetError')} />
          </BorderExternal>
        :
        <Success transactionId={transactionId}></Success>
      }
    </FaucetCon>
  )
}