import { ChainId, JSBI, Percent, Token, WETH } from '@huckleberry/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { 
  // fortmatic, 
  injected, walletconnect, 
  // portis, walletconnect, walletlink 
} from '../connectors'
import { BRIDGE_TOKEN_ADDRESS, FINNBAR_ADDRESS } from './abis/bridge'

export const ROUTER_ADDRESS = '0x2d4e873f9Ab279da9f1bb2c532d4F06f67755b77'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const wanBTC = new Token(ChainId.MOON_MAINNET, '0x78F811A431D248c1EDcF6d95ec8551879B2897C3', 8, 'BTC.m', 'BTC.m')
export const wanETH = new Token(ChainId.MOON_MAINNET, '0x576fDe3f61B7c97e381c94e7A03DBc2e08Af1111', 18, 'ETH.m', 'ETH.m')
export const wanLTC = new Token(ChainId.MOON_MAINNET, '0xDD7f2BC5f0Ef210CA183349Ae90785fdfb374AE7', 8, 'LTC.m', 'LTC.m')
export const wanXRP = new Token(ChainId.MOON_MAINNET, '0x9D5bc9B873AeD984e2B6A64d4792249D68BbA2Fe', 6, 'XRP.m', 'XRP.m')
export const WAN = new Token(ChainId.MOON_MAINNET, '0x41562ae242d194247389152aCAa7a9397136b09F', 18, 'WAN.m', 'WAN.m')
export const USDT = new Token(ChainId.MOON_MAINNET, '0xe936caa7f6d9f5c9e907111fcaf7c351c184cda7', 6, 'USDT.m', 'USDT.m')
export const USDT1 = new Token(ChainId.MOON_TESTNET, '0x2715aA7156634256aE75240C2c5543814660CD04', 6, 'USDT.m', 'USDT.m')
export const USDC = new Token(ChainId.MOON_MAINNET, '0x748134b5f553f2bcbd78c6826de99a70274bdeb3', 6, 'USDC.m', 'USDC.m')
export const USDC2 = new Token(ChainId.MOON_MAINNET, '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D', 6, 'USDC', 'USDC')
export const DOT = new Token(ChainId.MOON_MAINNET, '0x15b9ca9659f5dff2b7d35a98dd0790a3cbb3d445', 10, 'DOT.m', 'DOT.m')
export const MIM = new Token(ChainId.MOON_MAINNET, '0x0cae51e1032e8461f4806e26332c030e34de3adb', 18, 'MIM', 'MIM')



// TODO this is only approximate, it's actually based on blocks
export const PROPOSAL_LENGTH_IN_DAYS = 7

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

const UNI_ADDRESS = '0x69120197b77b51d32fFA5eAfe16b3d78115640c6'
export const FINN: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, BRIDGE_TOKEN_ADDRESS[ChainId.MOON_MAINNET], 18, 'FINN', 'Huckleberry'),
  [ChainId.MOON_MAINNET]: new Token(ChainId.MOON_MAINNET, BRIDGE_TOKEN_ADDRESS[ChainId.MOON_MAINNET], 18, 'FINN', 'Huckleberry'),
  [ChainId.MOON_TESTNET]: new Token(ChainId.MOON_TESTNET, BRIDGE_TOKEN_ADDRESS[ChainId.MOON_TESTNET], 18, 'FINN', 'Huckleberry')
}

export const TOM: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, FINNBAR_ADDRESS[ChainId.MOON_MAINNET], 18, 'TOM', 'TOM'),
  [ChainId.MOON_MAINNET]: new Token(ChainId.MOON_MAINNET, FINNBAR_ADDRESS[ChainId.MOON_MAINNET], 18, 'TOM', 'TOM'),
  [ChainId.MOON_TESTNET]: new Token(ChainId.MOON_TESTNET, FINNBAR_ADDRESS[ChainId.MOON_TESTNET], 18, 'TOM', 'TOM')
}

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [UNI_ADDRESS]: 'UNI',
  [GOVERNANCE_ADDRESS]: 'Governance',
  [TIMELOCK_ADDRESS]: 'Timelock'
}

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e'
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.MOON_MAINNET]: [WETH[ChainId.MOON_MAINNET]],
  [ChainId.MOON_TESTNET]: [WETH[ChainId.MOON_TESTNET]],

}
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MOON_MAINNET]: [
    ...WETH_ONLY[ChainId.MOON_MAINNET], 
    FINN[ChainId.MOON_MAINNET], 
    USDC, 
    USDT,
    MIM,
  ]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MOON_MAINNET]: [...WETH_ONLY[ChainId.MOON_MAINNET], FINN[ChainId.MOON_MAINNET]]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MOON_MAINNET]: [...WETH_ONLY[ChainId.MOON_MAINNET], FINN[ChainId.MOON_MAINNET]]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      USDT,
      USDC,
    ],
  ],
  [ChainId.MOON_MAINNET]: [
    [WETH[ChainId.MOON_MAINNET], WAN],
    [
      USDT,
      USDC,
    ],
    [
      USDC2,
      USDC,
    ],
    [
      DOT,
      FINN[ChainId.MOON_MAINNET],
    ],
  ],
  [ChainId.MOON_TESTNET]: [
    [
      new Token(ChainId.MOON_TESTNET, '0xd459Dad367788893c17c09e17cFBF0bf25c62833', 6, 'USDC', 'USDC'),
      new Token(ChainId.MOON_TESTNET, '0x2715aA7156634256aE75240C2c5543814660CD04', 6, 'USDT', 'USDT')
    ],
    [
      new Token(ChainId.MOON_TESTNET, '0x09cdfc56439643d151585b77899d0dc0f982bcd2', 18, 'wanETH', 'wanETH'),
      new Token(ChainId.MOON_TESTNET, '0x2715aA7156634256aE75240C2c5543814660CD04', 6, 'wanUSDT', 'wanUSDT')
    ],
    [
      new Token(ChainId.MOON_TESTNET, '0x50133f6836cb91237e17177e2985cbdad51f0cf2', 18, 'WAN', 'WAN'),
      new Token(ChainId.MOON_TESTNET, '0x372d0695e75563d9180f8ce31c9924d7e8aaac47', 18, 'WMOVR', 'WMOVR')
    ],
    [
      new Token(ChainId.MOON_TESTNET, '0x2715aa7156634256ae75240c2c5543814660cd04', 6, 'wanUSDT', 'wanUSDT'),
      new Token(ChainId.MOON_TESTNET, '0x372d0695e75563d9180f8ce31c9924d7e8aaac47', 18, 'WMOVR', 'WMOVR')
    ],
    [
      new Token(ChainId.MOON_TESTNET, '0x50133f6836cb91237e17177e2985cbdad51f0cf2', 18, 'WAN', 'WAN'),
      new Token(ChainId.MOON_TESTNET, '0x70114d2a0ec788bafee869acf7fd1f8c76491799', 6, 'wanXRP', 'wanXRP')
    ],
    [
      new Token(ChainId.MOON_TESTNET, '0x31b1644f8379a22d25f845a67f1ab346e76001aa', 18, 'FINN', 'FINN'),
      new Token(ChainId.MOON_TESTNET, '0x50133f6836cb91237e17177e2985cbdad51f0cf2', 18, 'WAN', 'WAN')
    ],
    [
      new Token(ChainId.MOON_TESTNET, '0x31b1644f8379a22d25f845a67f1ab346e76001aa', 18, 'FINN', 'FINN'),
      new Token(ChainId.MOON_TESTNET, '0x372d0695e75563d9180f8ce31c9924d7e8aaac47', 18, 'WMOVR', 'WMOVR')
    ],
    [
      new Token(ChainId.MOON_TESTNET, '0x09cdfc56439643d151585b77899d0dc0f982bcd2', 18, 'wanETH', 'wanETH'),
      new Token(ChainId.MOON_TESTNET, '0x372d0695e75563d9180f8ce31c9924d7e8aaac47', 18, 'WMOVR', 'WMOVR')
    ],
    [
      new Token(ChainId.MOON_TESTNET, '0x09cdfc56439643d151585b77899d0dc0f982bcd2', 18, 'wanETH', 'wanETH'),
      new Token(ChainId.MOON_TESTNET, '0x50133f6836cb91237e17177e2985cbdad51f0cf2', 18, 'WAN', 'WAN')
    ],
    [
      new Token(ChainId.MOON_TESTNET, '0x31b1644f8379a22d25f845a67f1ab346e76001aa', 18, 'FINN', 'FINN'),
      new Token(ChainId.MOON_TESTNET, '0x2715aa7156634256ae75240c2c5543814660cd04', 6, 'wanUSDT', 'wanUSDT')
    ],
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  MATHWALLET: {
    connector: injected,
    name: 'MathWallet',
    iconName: 'mathwallet.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5'
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 150

export const INITIAL_GAS_PRICE = 1

// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much MOVR so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 MOVR
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
export const BUNDLE_ID = '1'
export const TRACKED_OVERRIDES = [
  '0x9928e4046d7c6513326ccea028cd3e7a91c7590a',
  '0x87da823b6fc8eb8575a235a824690fda94674c88',
  '0xcd7989894bc033581532d2cd88da5db0a4b12859',
  '0xe1573b9d29e2183b1af0e743dc2754979a40d237',
  '0x45804880de22913dafe09f4980848ece6ecbaf78',
  '0x709f7b10f22eb62b05913b59b92ddd372d4e2152',
]

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}


export const STAKE_TOM_EARN_FINN_PID: { readonly [chainId in ChainId]?: number } = {
  [ChainId.MAINNET]: 20,
  [ChainId.MOON_MAINNET]: 20,
  [ChainId.MOON_TESTNET]: 8
}

export const _SUGGEST_GAS_PRICE = '0xee6b2800'; // 4 gwei

export function SUGGEST_GAS_PRICE() {
  console.log('getDefaultGasPrice', window.defaultGasPrice);
  if (window.defaultGasPrice) {
    return window.defaultGasPrice;
  } else {
    return _SUGGEST_GAS_PRICE;
  }
}
