import React, { useState, useEffect, useMemo, useContext } from 'react';
import { ModalLend } from '../Modal';
import { X } from 'react-feather';
import styled, { css, ThemeContext } from 'styled-components';
import { RowBetween } from '../Row';
import { TYPE } from '../../theme';
import { useTranslation } from 'react-i18next';
import useLend, { MarketItem, LendAccountTokens, Markets } from '../../state/lend/hooks';
import CurrencyLogo from '../CurrencyLogo';
import { useCurrency } from '../../hooks/Tokens';
import { Currency, ETHER } from '@huckleberry/sdk';
import { commafy2, commafy2Lend } from '../../utils/commafy';
import { useActiveWeb3React } from '../../hooks';
import { useCurrencyBalance } from '../../state/wallet/hooks';
import { SUGGEST_GAS_PRICE } from '../../constants';
import { useLendContractV1, useLendContractV2 } from '../../hooks/useContract';
import { BigNumber } from 'bignumber.js';
import { useTransactionAdder } from '../../state/transactions/hooks';
import { TransactionResponse } from '@ethersproject/providers';
import { Input as NumericalInput } from '../NumericalInput';
import { useLendApproveCallback, ApprovalState } from '../../hooks/useApproveCallback';
import Loader from '../Loader';
import { ArrowRight } from 'react-feather';
import { isMobile } from 'react-device-detect';

const StyledLoader = styled(Loader)`
  margin-right: 1rem;
`

const ModalContent = styled.div`
  width: 100%;
`;
const Title = styled.div`
  padding: 24px 40px;
  width: 100%;
  ${
    isMobile && css`
      padding: 24px 20px;
    `
  }
`;
const Content = styled.div`
  padding: 14px 40px 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;

  ${
    isMobile && css`
      padding: 14px 20px 30px;
    `
  }
`;
const Nav = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`;
const NavItem = styled.div<{active?:boolean}>`
  padding: 15px 0;
  margin-right: 30px;
  border-bottom: 2px solid ${({theme, active}) => active ? theme.text9 : 'rgba(0, 0, 0, 0)'};
  color: ${({theme, active}) => active ? theme.text9 : '#fff'};
  font-size: 20px;
  cursor: pointer;
  ${
    isMobile && css`
      padding: 6px 0 10px;
      font-size: 1rem;
    `
  }
`;
const NavTypeContent = styled.div`
  margin-top: -1px;
`;
const NavTypeItem = styled(RowBetween)`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px 0;
  ${
    isMobile && css`
      padding: 1rem 0;
    `
  }
`;
const NavBalance = styled.div`
  display: flex;
`;
const Button = styled.button`
  background: ${({theme}) => theme.text9 };
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 16px;
  font-size: 22px;
  width: 100%;
  cursor: pointer;
  border: none;

  :disabled {
    background: #4D4D4D;
    color: #999999;
    cursor: not-allowed;
  }

  > * {
    user-select: none;
  }
  ${
    isMobile && css`
      font-size: 1rem;
      height: 3.5rem;
      color: #172329;
    `
  }
`;
const InpCon = styled.div`
  padding: 14px 20px;
  display: flex;
  border: 1px solid ${({theme}) => theme.text9 };
  border-radius: 16px;
  align-items: center;
`;
const InpBtnGroup = styled.div`
  display: flex;
  align-items: center;
`;
const InpNumber = styled(NumericalInput)`
  width: 0;
  margin-right: 10px;
  flex: 1;
  font-size: 24px;
  color: ${({theme}) => theme.text9 };
  border: none;
  background: none;
  outline: none;

  ::placeholder {
    color: ${({theme}) => theme.text8 };
  }
`;
const MaxBtn = styled.div`
  cursor: pointer;
  padding: 2px 8px;
  background: ${({theme}) => theme.text9 };
  color: #172329;
  font-size: 14px;
  border-radius: 8px;
  white-space: nowrap;
  font-weight: 400;
`;
const MobileMaxBtn = styled.div`
  background: ${({theme}) => theme.text9 };
  color: #172329;
  font-size: 14px;
  border-radius: 8px;
  white-space: nowrap;
  height: 2rem;
  margin-top: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LimitColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const LimitRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;
const LimitArrow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4px;
`;
const MaxLine = styled.p`
  width: 1px;
  background: ${({theme}) => theme.text9 };
  margin: 0 6px;
`;


const toNonExponential = (num: string) => {
  // console.log(num)
  const m = num.match(/\d(?:\.(\d*))?e([+-]\d+)/);
  const m1 = (m && m[1]) ? m[1].length : 0;
  const m2 = (m && m[2]) ? m[2] : 0;
  return Number(num).toFixed(Math.max(0, m1 - Number(m2)));
}
const BorrowModal = (
  {
    showBorrowModal,
    wrappedOnDismiss,
    info,
    personalAccount,
    markets,
    type,
    lendApyState
  }:{
    showBorrowModal: boolean
    wrappedOnDismiss: () => void
    info: MarketItem
    personalAccount: LendAccountTokens | null
    markets?: Markets
    type?: number
    lendApyState: boolean
  }
) => {
  const theme = useContext(ThemeContext);
  const gas = 0.01;
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const { useLimitPer, useMaxBorrow, useTotalBorrowBalance } = useLend();
  const getLimitFn = useLimitPer;
  const getMaxBorrow = useMaxBorrow;
  const getTotalBorrowBalance = useTotalBorrowBalance;
  const [approve, approveCallback] = useLendApproveCallback(info);
  const userAccount: string | undefined = account ? account : undefined;

  let currencyId: string | undefined = '';
  if (info?.underlying_symbol === 'MOVR') {
    currencyId = 'MOVR';
  } else {
    const underlyingAdress: string | undefined = info ? info.underlying_address : undefined;
    currencyId = underlyingAdress;
  }
  const currency: Currency | null |undefined = useCurrency(currencyId);
  const userCurrency: Currency | undefined = currency ? currency : undefined;
  const balance = useCurrencyBalance(userAccount, userCurrency);
  const borrowBalance = useMemo(() => {
    if (!personalAccount || !info) return 0;
    const item = personalAccount.tokens.find(v => v.token_address === info.token_address);
    if (!item) return 0;
    return item.borrow_balance_underlying;
  }, [info, personalAccount])

  const [curIndex, setCurIndex] = useState<number>(type ? type : 0);
  const [banBtn, setBanBtn] = useState(false);
  const [showAround, setShowAround] = useState(false);
  const [isMax, setIsMax] = useState(false);
  const [inpNum, setInpNum] = useState('');
  const [limitNum, setLimitNum] = useState('0');
  const [limitMaxNum, setLimitMaxNum] = useState('0');
  const [maxBorrow, setMaxBorrow] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [borrowLimitUse, setBorrowLimitUse] = useState(0);
  const [totalBorrowBalance, setTotalBorrowBalance] = useState(0);
  const [limitUsedChange, setLimitUsedChange] = useState('0');
  const [showWarn, setShowWarn] = useState(false);
  // const [limitChange, setLimitChange] = useState('0');
  // const [limitUnderlyingChange, setLimitUnderlyingChange] = useState('0');
  const [borrowBalanceChange, setBorrowBalanceChange] = useState('0');
  const [walletBalanceChange, setWalletBalanceChange] = useState('0');

  const address: string | undefined = info?.token_address ? info?.token_address : undefined;
  let contract1 = useLendContractV1(address);
  let contract2 = useLendContractV2(address);
  const LendContract = currency === ETHER ? contract2 : contract1;
  const addTransaction = useTransactionAdder();
  const availableLiquidity = useMemo(() => {
    let al = new BigNumber(0);
    let cash = new BigNumber(info.cash);
    if (+info.borrowCap) {
      let capNum = new BigNumber(info.borrowCap).minus(info.total_borrows);
      al = capNum.minus(cash).gt(0) ? cash : capNum;
    } else {
      al = cash;
    }
    const alNum = al.gt(0) ? al.toString() : '0';
    return alNum;
  }, [info.borrowCap, info.cash, info.total_borrows])

  const borrowFn = async () => {
    if (!LendContract || !info) return;
    const amount = new BigNumber(inpNum);
    const inpAmount = `0x${amount.shiftedBy(Number(info.underlying_decimals)).toString(16)}`;
    setShowLoading(true);
    await LendContract
      .borrow(inpAmount, { gasLimit: 2000000, gasPrice: SUGGEST_GAS_PRICE(), value: '0' })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Borrow ${info.underlying_symbol}`
        })
        setShowLoading(false);
        wrappedOnDismiss();
      })
      .catch((error: any) => {
        console.error(error)
        setShowLoading(false);
      })
  }
  const repayFn = async () => {
    if (!LendContract || !info) return;
    const isMovr = userCurrency === ETHER;
    const amount = new BigNumber(inpNum);
    const inpAmount = `0x${amount.shiftedBy(Number(info.underlying_decimals)).toString(16)}`;
    const sdkAmount = isMovr ? inpAmount : showAround ? `0x${'f'.repeat(64)}` : inpAmount;
    setShowLoading(true);
    if (isMovr) {
      await LendContract
        .repayBorrow({ gasLimit: 2000000, gasPrice: SUGGEST_GAS_PRICE(), value: sdkAmount })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Repay ${info.underlying_symbol}`
          })
          setShowLoading(false);
          wrappedOnDismiss();
        })
        .catch((error: any) => {
          console.error(error)
          setShowLoading(false);
        })
    } else {
      await LendContract
        .repayBorrow(sdkAmount, { gasLimit: 2000000, gasPrice: SUGGEST_GAS_PRICE() })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Repay ${info.underlying_symbol}`
          })
          setShowLoading(false);
          wrappedOnDismiss();
        })
        .catch((error: any) => {
          console.error(error)
          setShowLoading(false);
        })
    }
  }
  const maxFn = () => {
    if (!personalAccount || !info || balance === void 0) return;
    const tokens = personalAccount.tokens;
    const item = tokens.find(v => v.token_address === info.token_address);
    let txt;
    if (!item) {
      setInpNum('0');
      limitUsedByInpFn('0');
      return;
    }
    const borrowBalance = new BigNumber(item.borrow_balance_underlying);
    const rate = new BigNumber(info.borrow_rate).abs().times(20).div(2628000).plus(1);
    let curBalanceStr =  borrowBalance.times(rate).toFixed(+info.underlying_decimals, BigNumber.ROUND_UP);
    curBalanceStr = curBalanceStr.includes('e') ? toNonExponential(curBalanceStr) : curBalanceStr;
    const curBalance = new BigNumber(curBalanceStr);
    let balanceNum;
    const allBalance = new BigNumber(balance ? balance.toExact() : 0);
    if (info.underlying_symbol === 'MOVR') {
      // balanceNum = allBalance.gte(curBalance.plus(gas)) ? curBalance : allBalance.gte(gas) ? allBalance.minus(gas) : new BigNumber(0);
      if (allBalance.gte(curBalance.plus(gas))) {
        setShowAround(true);
        balanceNum = curBalance;
      } else {
        if (allBalance.gte(gas)) {
          balanceNum = allBalance.minus(gas);
        } else {
          balanceNum = new BigNumber(0);
        }
      }
    } else {
      if (allBalance.gte(curBalance)) {
        setShowAround(true);
        balanceNum = curBalance;
      } else {
        balanceNum = allBalance;
      }
    }
    if (balanceNum.eq(0)) {
      txt = '0';
    } else {
      txt = balanceNum.toString();
    }
    console.log('repayAmount==', curBalanceStr, 'walletBalance', allBalance.toString(), 'txt', txt, 'balanceNum', balanceNum.toString(), 'borrowBalance', borrowBalance.toString())
    setInpNum(txt);
    // if (balanceNum.gte(borrowBalance)) {
    //   setShowAround(true);
    // }
    limitUsedByInpFn(txt);
    setIsMax(true);
  }
  const limitFn = () => {
    if (!availableLiquidity) return;
    const limitCurNum = new BigNumber(availableLiquidity).gt(limitNum) ? limitNum : availableLiquidity;
    const txt = new BigNumber(limitCurNum).eq(0) ? '0' : limitCurNum;
    setInpNum(txt);
    limitUsedByInpFn(txt);
    setIsMax(true);
  }
  const limitUsedByInpFn = (inpStr: string) => {
    const maxBorrowBalanceBigNum = new BigNumber(maxBorrow);
    const totalBorrowBalanceBigNum = new BigNumber(totalBorrowBalance);
    const inpNumbBigNum = new BigNumber(inpStr);
    const inpNumUnderlyingBigNum = inpNumbBigNum.times(info.underlying_price);
    const borrowBalanceBigNum = new BigNumber(borrowBalance);
    const walletBalanceBigNum = new BigNumber(balance ? balance.toExact() : 0);
    setIsMax(false);
    setShowWarn(false);
    if (curIndex) {
      // const chagneLimitNum = maxBorrowBalanceBigNum;
      // setLimitChange('$' + commafy2Lend(chagneLimitNum.toString(), info.underlying_decimals));
      // const limitUnderlyingChangeNum = chagneLimitNum.div(info.underlying_price)
      // setLimitUnderlyingChange(commafy2Lend(limitUnderlyingChangeNum.toString(), info.underlying_decimals));
      const walletBalanceChangeNum = walletBalanceBigNum.minus(inpNumbBigNum);
      setWalletBalanceChange(walletBalanceChangeNum.gt(0) ? commafy2Lend(walletBalanceChangeNum.toString(), 6) : '0');
      const totalLeftBorrowBalanceBigNum = totalBorrowBalanceBigNum.minus(inpNumUnderlyingBigNum)
      if (totalLeftBorrowBalanceBigNum.lt(0)) {
        setLimitUsedChange('0%');
        setBorrowBalanceChange('0');
        return;
      }
      const chagneLimitUsedNum = maxBorrowBalanceBigNum.gt(0) ? totalLeftBorrowBalanceBigNum.div(maxBorrowBalanceBigNum).times(100).toString() : '0';
      const chagneLimitUsedBigNum = new BigNumber(chagneLimitUsedNum);
      chagneLimitUsedBigNum.gt(80) && setShowWarn(true);
      setLimitUsedChange(chagneLimitUsedBigNum.gt(100) ? 'Liquidation' : commafy2(chagneLimitUsedNum) + '%');
      const borrowBalanceChangeNum = borrowBalanceBigNum.minus(inpNumbBigNum);
      setBorrowBalanceChange(commafy2Lend(borrowBalanceChangeNum.gt(0) ? borrowBalanceChangeNum.toString() : '0', 6));
    } else {
      const borrowBalanceChangeNum = borrowBalanceBigNum.plus(inpNumbBigNum);
      setBorrowBalanceChange(commafy2Lend(borrowBalanceChangeNum.toString(), 6));
      const walletBalanceChangeNum = walletBalanceBigNum.plus(inpNumbBigNum);
      setWalletBalanceChange(commafy2Lend(walletBalanceChangeNum.toString(), 6));
      const isLimit = inpNumUnderlyingBigNum.gt(maxBorrowBalanceBigNum);
      if (isLimit) {
        // setLimitChange('$0');
        // setLimitUnderlyingChange('0');
        setLimitUsedChange('Liquidation');
        setShowWarn(true);
        return;
      }
      // const chagneLimitNum = maxBorrowBalanceBigNum.minus(inpNumUnderlyingBigNum);
      // setLimitChange('$' + commafy2Lend(chagneLimitNum.toString(), info.underlying_decimals));
      // const limitUnderlyingChangeNum = chagneLimitNum.div(info.underlying_price)
      // setLimitUnderlyingChange(commafy2Lend(limitUnderlyingChangeNum.toString(), info.underlying_decimals));
      const chagneLimitUsedNum = maxBorrowBalanceBigNum.lte(0) ? 0 : totalBorrowBalanceBigNum.plus(inpNumUnderlyingBigNum).div(maxBorrowBalanceBigNum).times(100);
      const chagneLimitUsedBigNum = new BigNumber(chagneLimitUsedNum);
      chagneLimitUsedBigNum.gt(80) && setShowWarn(true);
      setLimitUsedChange(chagneLimitUsedBigNum.gt(100) ? 'Liquidation' : commafy2(chagneLimitUsedNum.toString()) + '%');
    }
  }

  useEffect(() => {
    if (!personalAccount || !info || !markets) return;
    let enough = true;
    getLimitFn(personalAccount, markets, info.token_address, 0.8)
      .then(res => {
        res && enough && setLimitNum(String(res));
      })
    getLimitFn(personalAccount, markets, info.token_address, 1)
      .then(res => {
        res && enough && setLimitMaxNum(String(res));
      })
    return () => {
      enough = false;
    }
  }, [getLimitFn, info, markets, personalAccount]);

  useEffect(() => {
    if (!personalAccount || !markets) return;
    let enough = true;
    getTotalBorrowBalance(personalAccount, markets)
      .then(res => {
        setTotalBorrowBalance(res);
      })
    getMaxBorrow(personalAccount, markets)
      .then(res => {
        res && enough && setMaxBorrow(res);
      })
    return () => {
      enough = false;
    }
  }, [getMaxBorrow, getTotalBorrowBalance, markets, personalAccount]);

  useEffect(() => {
    let isUnmount = false;
    const changeBorrowLimitFn = () => {
      if (isUnmount) return;
      const num = maxBorrow ? totalBorrowBalance / maxBorrow * 100 : 0;
      setBorrowLimitUse(num);
    }
    changeBorrowLimitFn();
    return () => {
      isUnmount = true;
    };
  }, [maxBorrow, totalBorrowBalance]);
  
  useEffect(() => {
    let enough = true;
    
    if (!enough) return;
    setInpNum('');
    setShowAround(false);
    setShowLoading(false);
    setIsMax(false);

    return () => {
      enough = false;
    }
  }, [curIndex]);

  useEffect(() => {
    let enough = true;

    if (!enough) return;
    const inpNumber = new BigNumber(inpNum);
    const isInp = inpNumber.eq(0);
    if (curIndex) {
      if (!personalAccount) return;
      const tokens = personalAccount.tokens;
      const item = tokens.find(v => v.token_address === info.token_address);
      if (!item) return;
      const borrowBalance = new BigNumber(item.borrow_balance_underlying);
      const rate = new BigNumber(info.borrow_rate).abs().times(20).div(2628000).plus(1);
      const curBalance =  borrowBalance.times(rate);
      let balanceNumber = new BigNumber(balance ? balance.toExact() : 0);
      balanceNumber = info.underlying_symbol === 'MOVR' ? balanceNumber.minus(gas) : balanceNumber;
      // console.log('inpNumber==', inpNumber.toString())
      // console.log('curBalance==', curBalance.toString())
      // console.log('balanceNumber==', balanceNumber.toString())
      // console.log('banBtn', isInp , inpNumber.gt(curBalance) , inpNumber.gt(balanceNumber) , showLoading)
      console.log('banBtn', isInp , inpNumber.gt(balanceNumber) , showLoading)
      console.log('banBtn', 'inp==', inpNumber.toString(), 'walletBalance', balanceNumber.toString())
      if (isMax) {
        setBanBtn(isInp || inpNumber.gt(balanceNumber) || showLoading);
      } else {
        setBanBtn(isInp || inpNumber.gt(curBalance) || inpNumber.gt(balanceNumber) || showLoading);
      }
    } else {
      const limitNumber = new BigNumber(limitMaxNum);
      const liquiteNumber = new BigNumber(info?.cash);
      setBanBtn(isInp || inpNumber.gt(limitNumber) || inpNumber.gt(liquiteNumber) || showLoading);
    }

    return () => {
      enough = false;
    }
  }, [borrowBalance, curIndex, info, inpNum, limitMaxNum, balance, personalAccount, showLoading, isMax]);

  return (
    <ModalLend
      bg={theme.lendModalBg}
      border={'1px solid rgba(255, 255, 255, 0.2)'}
      newEnLarge={true}
      isOpen={showBorrowModal}
      onDismiss={wrappedOnDismiss}
    >
      <ModalContent>
        <Title>
          <RowBetween style={{marginBottom: '18px'}}>
            <InpBtnGroup>
              <CurrencyLogo currency={userCurrency} size={'42px'} style={{marginRight: '10px'}}></CurrencyLogo>
              <TYPE.white fontSize={16}>{info?.underlying_symbol}</TYPE.white>
            </InpBtnGroup>
            <X size={26} color={'#fff'} onClick={wrappedOnDismiss}></X>
          </RowBetween>
          {
            curIndex ?
              approve === ApprovalState.APPROVED ?
                (
                  <>
                    <InpCon>
                      {
                        showAround && <TYPE.white color={'#FFC70B'} style={{marginRight: '2px'}}>≈</TYPE.white>
                      }
                      <InpNumber value={inpNum} placeholder=" " onUserInput={(input) => {
                        let txt = input;
                        const len = input.length - 1;
                        const pointPos = input.includes('.') ? input.indexOf('.') : len;
                        const subPos = pointPos + +info?.underlying_decimals + 1;
                        txt = txt.substring(0, subPos);
                        limitUsedByInpFn(txt);
                        setInpNum(txt);
                        setShowAround(false);
                      }} />
                      <InpBtnGroup>
                        { !isMobile && <MaxBtn onClick={maxFn}>MAX</MaxBtn>}
                        { !isMobile && <MaxLine /> }
                        <TYPE.yellow_o>{info?.underlying_symbol}</TYPE.yellow_o>
                      </InpBtnGroup>
                    </InpCon>
                    { isMobile && <MobileMaxBtn onClick={maxFn}>MAX</MobileMaxBtn>}
                  </>
                )
              :
                <TYPE.white fontSize={12}>{t(`To Deposit or Repay ${info?.underlying_symbol}, you need to enable it first.`)}</TYPE.white>
            :
              (
                <>
                  <InpCon>
                    <InpNumber value={inpNum} placeholder=" " onUserInput={(input) => {
                      let txt = input;
                      const len = input.length - 1;
                      const pointPos = input.includes('.') ? input.indexOf('.') : len;
                      const subPos = pointPos + +info?.underlying_decimals + 1;
                      txt = txt.substring(0, subPos);
                      limitUsedByInpFn(txt);
                      setInpNum(txt);
                    }} />
                    <InpBtnGroup>
                      { !isMobile && <MaxBtn onClick={limitFn}>80% LIMIT</MaxBtn> }
                      { !isMobile && <MaxLine /> }
                      <TYPE.yellow_o>{info?.underlying_symbol}</TYPE.yellow_o>
                    </InpBtnGroup>
                  </InpCon>
                  { isMobile && <MobileMaxBtn onClick={limitFn}>80% LIMIT</MobileMaxBtn>}
                </>
              )
          }
          
        </Title>
        <Content>
          {
            <Nav>
              <NavItem active={curIndex === 0} onClick={() => setCurIndex(0)}>{t('Borrow')}</NavItem>
              <NavItem active={curIndex === 1} onClick={() => setCurIndex(1)}>{t('Repay')}</NavItem>
            </Nav>
          }
          <NavTypeContent>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={isMobile ? 14 : 16}>{t('Borrow APR')}</TYPE.white_o1>
              <TYPE.yellow_o fontSize={isMobile ? 14 : 16}>{commafy2(Number(lendApyState ? info?.borrow_rate_with_reward : info?.borrow_rate) * 100)}%</TYPE.yellow_o>
            </NavTypeItem>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={isMobile ? 14 : 16}>{t('Borrow Limit')}</TYPE.white_o1>
              {/* maxBorrow */}
              <LimitRight>
                <LimitColumn>
                  <TYPE.yellow_o fontSize={isMobile ? 14 : 16}>${commafy2Lend(maxBorrow)}</TYPE.yellow_o>
                  <TYPE.white_o1 fontSize={isMobile ? 12 : 14}>{commafy2Lend(maxBorrow / +info.underlying_price, !isMobile ? info.underlying_decimals : null)}&nbsp;{info.underlying_symbol}</TYPE.white_o1>
                </LimitColumn>
              </LimitRight>
            </NavTypeItem>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={isMobile ? 14 : 16}>{t('Borrow Limit Used')}</TYPE.white_o1>
              <LimitRight>
                {/* totalBorrowBalance/maxBorrow */}
                <TYPE.yellow_o fontSize={isMobile ? 14 : 16}>{commafy2(borrowLimitUse)}%</TYPE.yellow_o>
                {
                  +inpNum ?
                    (
                      <LimitArrow>
                        <ArrowRight size={isMobile ? 14 : 16} color={'#FFC70B'}></ArrowRight>
                      </LimitArrow>
                    )
                  :
                    null
                }
                {
                  +inpNum ?
                    (
                      <TYPE.yellow_o color={showWarn ? '#FF5B45' : '#FFC70B'} fontSize={isMobile ? 14 : 16}>{limitUsedChange}</TYPE.yellow_o>
                    )
                  :
                    null
                }
              </LimitRight>
            </NavTypeItem>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={isMobile ? 14 : 16}>{t('Borrow Balance')}</TYPE.white_o1>
              <NavBalance>
                <LimitRight>
                  <NavBalance>
                    {/* borrow_underlyinig_balance */}
                    <TYPE.yellow_o fontSize={isMobile ? 14 : 16}>{commafy2Lend(borrowBalance, 6)}</TYPE.yellow_o>&nbsp;
                    <TYPE.white fontSize={isMobile ? 14 : 16}>{info?.underlying_symbol}</TYPE.white>
                  </NavBalance>
                  {
                    +inpNum ?
                      (
                        <LimitArrow>
                          <ArrowRight size={16} color={'#FFC70B'}></ArrowRight>
                        </LimitArrow>
                      )
                    :
                      null
                  }
                  {
                    +inpNum ?
                      (
                        <NavBalance>
                          <TYPE.yellow_o fontSize={isMobile ? 14 : 16}>{borrowBalanceChange}</TYPE.yellow_o>&nbsp;
                          <TYPE.white fontSize={isMobile ? 14 : 16}>{info?.underlying_symbol}</TYPE.white>
                        </NavBalance>
                      )
                    :
                      null
                  }
                </LimitRight>
              </NavBalance>
            </NavTypeItem>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={isMobile ? 14 : 16}>{t('Wallet Balance')}</TYPE.white_o1>
              <LimitRight>
              <NavBalance>
                <TYPE.yellow_o fontSize={isMobile ? 14 : 16}>{commafy2Lend(balance?.toExact(), 6)}</TYPE.yellow_o>&nbsp;
                <TYPE.white fontSize={isMobile ? 14 : 16}>{info?.underlying_symbol}</TYPE.white>
              </NavBalance>
                {
                  +inpNum ?
                    (
                      <LimitArrow>
                        <ArrowRight size={16} color={'#FFC70B'}></ArrowRight>
                      </LimitArrow>
                    )
                  :
                    null
                }
                {
                  +inpNum ?
                    (
                      <NavBalance>
                        <TYPE.yellow_o fontSize={isMobile ? 14 : 16}>{walletBalanceChange}</TYPE.yellow_o>&nbsp;
                        <TYPE.white fontSize={isMobile ? 14 : 16}>{info?.underlying_symbol}</TYPE.white>
                      </NavBalance>
                    )
                  :
                    null
                }
              </LimitRight>
            </NavTypeItem>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={isMobile ? 14 : 16}>{t('Available Liquidity')}</TYPE.white_o1>
              <NavBalance>
                {/* cash */}
                <TYPE.yellow_o fontSize={isMobile ? 14 : 16}>{commafy2Lend(availableLiquidity)}</TYPE.yellow_o>&nbsp;
                <TYPE.white fontSize={isMobile ? 14 : 16}>{info?.underlying_symbol}</TYPE.white>
              </NavBalance>
            </NavTypeItem>
          </NavTypeContent>
          {
            curIndex ?
              approve !== ApprovalState.APPROVED ?
                <Button disabled={!account || approve === ApprovalState.PENDING} onClick={approveCallback}>
                  {
                    approve === ApprovalState.PENDING &&
                    <StyledLoader stroke={'#fff'} />
                  }
                  {t('Enable')}
                </Button>
              :
                <Button disabled={banBtn} onClick={repayFn}>
                  {
                    showLoading && <StyledLoader stroke={'#fff'} />
                  }
                  {t('Repay')}
                </Button>
            :
              <Button disabled={banBtn} onClick={borrowFn}>
                {
                  showLoading && <StyledLoader stroke={'#fff'} />
                }
                {t('Borrow')}
              </Button>
          }
        </Content>
      </ModalContent>
    </ModalLend>
  )
};

export default BorrowModal;