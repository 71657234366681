import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
// import Polling from '../components/Header/Polling'
import URLWarning from '../components/Header/URLWarning'
import LackOfMovrWarning from '../components/Header/LackOfMovrWarning'
import SideBar from '../components/Header/SideBar.jsx'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import Earn from './Earn'
import Excursion from './Excursion'
import Activity from './Activity'
import Manage from './Earn/Manage'
import ManageHive from './Earn/ManageExcursion'
import ManageHiveActivity from './Earn/ManageActivity'
import ManageStakeTomEarnFinn from './Earn/ManageStakeTomEarnRiver'
import ManageStakeAutoTom from './Earn/ManageStakeAutoTom'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { OpenClaimAddressModalAndRedirectToSwap, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Faucet from './Faucet'
import FinnBar from './FinnBar'
import Lend from './Lend'
import Reservoir from './Lend/Reservoir'
import ReservoirInfo from './Lend/ReservoirInfo'
import Deposit from './Lend/Deposit'
import Borrow from './Lend/Borrow'
import Dashboard from './Lend/Dashboard'
// import { useMedia } from 'react-use'
import { isMobile } from 'react-device-detect'

// import PromptModal from '../components/PromptModal'

import Vote from './Vote'
import VotePage from './Vote/VotePage'
import { useDefaultGasPrice } from '../state/user/hooks'

// import Chrismas from '../components/Chrismas'

const AppWrapper = styled.div<{
  isMobile: boolean
}>`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  height: 100vh;
  margin-left: ${props => props.isMobile ? '0px' : '250px'};
  width: 100%;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  padding-top: 55px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 1.5rem;
    padding-bottom: ${isMobile ? '4.6rem' : '16px'};
    margin-bottom: ${isMobile ? '5.6rem' : '0'};
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}


const HaechiAudit = styled.div`
  position:fixed;
  bottom:-5px;
  right: 35px;
  width: 170px;
  height: 42px;
  padding: 0 10px;
  text-align: center;
  line-height: 52px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px 8px 0px 0px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 100;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  z-index:99;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  bottom:68px;
  right:15px;
  display:none;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  display:none;
  `}; 
`

declare global {
  interface Window {
    defaultGasPrice: any;
  }
}

export default function App() {
  const [defaultGasPrice] = useDefaultGasPrice()
  console.log('defaultGasPrice', defaultGasPrice);

  if (defaultGasPrice) {
    window.defaultGasPrice = '0x' + Number(defaultGasPrice*1e9).toString(16).split('.')[0];
  }

  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      {/* <div id="deco_bg_buttom"></div> */}
      <div id="deco_bg_top"></div>
      <HaechiAudit>
        <Bg />
        <a style={{ textDecoration: 'none', display: 'inline-block', width: '100%' }} target="_blank" rel="noopener noreferrer" href="https://github.com/HuckleberryDex/audit-reports">
          <img width="20px" alt="audit" src="haechiaudit.svg"/>
          <Audited>Audited by Beosin</Audited>
        </a>
      </HaechiAudit>
      {/* <PromptModal></PromptModal> */}
      {/* <Chrismas></Chrismas> */}
      <URLWarning />
      <LackOfMovrWarning />
      <Container>
        <SideBar />
        <AppWrapper isMobile={isMobile}>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            {/* <Polling /> */}
            <TopLevelModals />
            <Web3ReactManager>
              <Switch>
                <Route exact strict path="/swap" component={Swap} />
                <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/pool" component={Pool} />
                <Route exact strict path="/farm" component={Earn} />
                <Route exact strict path="/excursion" component={Excursion} />
                <Route exact strict path="/activity" component={Activity} />
                <Route exact strict path="/vote" component={Vote} />
                <Route exact strict path="/faucet" component={Faucet} />
                <Route exact strict path="/tomhouse" component={FinnBar} />
                <Route path="/lend" render={
                  () => (
                    <Lend>
                      <Route exact path="/" component={Reservoir} />
                      <Route exact path="/lend/reservoir" component={Reservoir} />
                      <Route exact path="/lend/reservoir/info/:infoId" component={ReservoirInfo} />
                      <Route exact path="/lend/deposit" component={Deposit} />
                      <Route exact path="/lend/borrow" component={Borrow} />
                      <Route exact path="/lend/dashboard" component={Dashboard} />
                    </Lend>
                  )
                } />
                <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                <Route exact path="/add" component={AddLiquidity} />
                <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact path="/create" component={AddLiquidity} />
                <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />
                <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/migrate/v1" component={MigrateV1} />
                <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />
                <Route exact strict path="/farm/:currencyIdA/:currencyIdB" component={Manage} />
                <Route exact strict path="/excursion/:currencyIdA/:pid" component={ManageHive} />
                <Route exact strict path="/activity/:currencyIdA/:pid" component={ManageHiveActivity} />
                <Route exact strict path="/stakeTomEarnFinn/:currencyIdA" component={ManageStakeTomEarnFinn} />
                <Route exact strict path="/stakeTomEarnTom/:currencyIdA" component={ManageStakeAutoTom} />
                <Route exact strict path="/vote/:id" component={VotePage} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Web3ReactManager>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </Container>

    </Suspense>
  )
}

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`

const Audited = styled.span`
  color: #fff;
  font-size: 12px;
  position: relative;
  top: -8px;
  margin-left: 10px;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
`