import React, { useMemo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { RowBetween } from '../../components/Row';
import { RouteComponentProps } from 'react-router-dom'
import Panel from '../../components/Panel';
import { TYPE } from '../../theme';
import { useTranslation } from 'react-i18next';
import ProgressCircel from '../../components/Lend/CircleProgress';
import { ButtonBlueCircle } from '../../components/Button';
import depositIcon from '../../assets/png/Lend/deposit.svg';
import borrowIcon from '../../assets/png/Lend/borrow.svg';
import Web3Status from '../../components/Web3Status';
import { useActiveWeb3React } from '../../hooks';
import useLend, { MarketItem, LendAccountTokensItem, DepositMarketsItem } from '../../state/lend/hooks';
import { commafy2, commafy2Lend } from '../../utils/commafy';
import { useCurrency } from '../../hooks/Tokens';
import { Currency } from '@huckleberry/sdk';
import { useCurrencyBalance } from '../../state/wallet/hooks';
import DepositModal from '../../components/Lend/DepositModal';
import BorrowModal from '../../components/Lend/BorrowModal';
import { BigNumber } from 'bignumber.js';
import LocalLoader from '../../components/LocalLoader';
import { isMobile, browserName } from 'react-device-detect';
import RateView from '../../components/Lend/RateView';
import { LendApyContext } from '../../components/Lend/LendApyBtn';

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`
const ReservoirInfoCon = styled(RowBetween)`
  ${
    isMobile && css`
      flex-direction: column;
      align-items: flex-start;
    `
  }
`;
const PanelCon = styled(Panel)`
  width: 510px;
  max-width: 510px;
  min-height: 476px;
  margin-right: 0;
  padding: 0;
  height: auto;
  background: ${({theme}) => theme.lendBg};
  backdrop-filter: blur(20px);
  border-radius: 16px;
  position: relative;

  ${
    isMobile && css`
      width: 100%;
      margin-bottom: 1.25rem !important;
    `
  }
`
const PanelConLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 510px;
  max-width: 510px;
  min-height: 476px;
  margin-right: 0;
  padding: 0;
  height: auto;
  background: ${({theme}) => theme.lendBg};
  backdrop-filter: blur(20px);
  border: 1px solid ${({theme}) => theme.lendNavBorder};
  border-radius: 16px;
  position: relative;

  ${
    isMobile && css`
      width: 100%;
      margin-bottom: 1.25rem !important;
    `
  }
`
const PanelTitle = styled(RowBetween)`
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid ${ ({theme}) => theme.text8};
`;
const TxtLine = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
`;
const ApyLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const ApyLineMobile = styled.div`
  padding: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ApyInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
const LiquidityInfo = styled(ApyInfo)`
  align-items: ${ isMobile ? 'flex-start' : 'flex-end' };
  margin-right: 20px;
`;
const BorrowInfo = styled(ApyInfo)`
  align-items: ${ isMobile ? 'flex-end' : 'flex-start' };
  margin-left: 20px;
`;
const IconCon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${ ({theme}) => theme.text8 };
  margin-bottom: 8px;
`;
const Icon = styled.div<{type?:string}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-image: url(${ ({type}) => type === 'deposit' ? depositIcon : borrowIcon });
  background-size: contain;
  background-repeat: no-repeat;
`;
const TotalInfo = styled.div`
  padding: 0 20px;
`;
const TotalInfoLine = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid ${ ({theme}) => theme.text8 };
  padding: 14px 12px 0;
  border-radius: 12px;
`;
const TotalItemLine = styled(RowBetween)`
  margin-bottom: 14px;
`;
const InformationCon = styled.div`
  padding: ${ isMobile ? '22px 20px 0' : '26px 20px 0' };
`;
const BorderLine = styled.p`
  width: 100%;
  height: 1px;
  background: ${({theme}) => theme.text8 };
`;
const PayBtn = styled(ButtonBlueCircle)`
  width: 88px;
  height: 30px;
  font-size: 14px;
  border-radius: 8px;

  ${
    isMobile && css`
      width: 49%;
      height: 1.5rem;
      font-size: 0.75rem;
    `
  }
`;
const InformationLine = styled(RowBetween)<{marginBottom?: string}>`
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '13px'};
`
const BtnGroup = styled.div`
  display: flex;
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius:10px;
  white-space: nowrap;
  width: 100%;
  height: 52px;
  margin-bottom: 20px;
  cursor: pointer;
`

const ReservoirInfo = (props: RouteComponentProps<{ infoId: string }>) => {
  const {
    match: {
      params: { infoId }
    }
  } = props;
  const match: RegExpMatchArray | null = infoId.match(/infoId(\S*)/);
  const tokenAddress: string = match ? match[1] : '';
  const { markets, personalAccount, useLimitPer } = useLend();
  const getLimitFn = useLimitPer;
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showBorrowModal, setShowBorrowModal] = useState(false);
  const [depositType, setDepositType] = useState(0);
  const [borrowType, setBorrowType] = useState(0);
  const [available, setAvailable] = useState(0);
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();

  const coinInfo: MarketItem = useMemo(() => {
    return markets[tokenAddress as keyof typeof markets]
  }, [markets, tokenAddress]);

  const accountInfo: LendAccountTokensItem | null = useMemo(() => {
    const tokens = personalAccount?.tokens;
    const info: LendAccountTokensItem | null | undefined = tokens?.find((item:LendAccountTokensItem) => {
      return item.token_address === tokenAddress;
    });
    return info ? info : null;
  }, [personalAccount, tokenAddress]);

  const depositInfo: DepositMarketsItem | undefined = useMemo(() => {
    const info = Object.assign({
      is_entered: accountInfo ? accountInfo.is_entered : false
    }, coinInfo);
    return info;
  }, [accountInfo, coinInfo])

  const userAccount: string | undefined = account ? account : undefined;
  const underlyingAdress: string | undefined = coinInfo ? coinInfo.underlying_address : undefined;
  const currencyId: string | undefined = coinInfo?.underlying_symbol === 'MOVR' ? 'MOVR' : underlyingAdress;
  const currency: Currency | undefined | null = useCurrency(currencyId);
  const userCurrency: Currency | undefined = currency ? currency : undefined;
  const balance = useCurrencyBalance(userAccount, userCurrency);

  const closeDepositFn = () => {
    setShowDepositModal(false);
  };
  const rate2commafyFn = (num: number | string) => {
    if (Number(num) !== +num) return '--';
    const rate = new BigNumber(num).times(100).toString();
    return commafy2(rate);
  }

  useEffect(() => {
    if (!personalAccount || !depositInfo || !markets) return;
    let enough = true;
    getLimitFn(personalAccount, markets, depositInfo.token_address, 1)
      .then(res => {
        res && enough && setAvailable(Number(res));
      })
    return () => {
      enough = false;
    }
  }, [getLimitFn, depositInfo, markets, personalAccount]);

  return (
    <ReservoirInfoCon>
      <LendApyContext.Consumer>
        {
          state => (
            <>
              {
                coinInfo ?
                  (
                    <PanelCon>
                      { browserName === 'Firefox' && <Bg /> }
                      <PanelTitle>
                        <TxtLine>
                          <TYPE.white fontSize={16}>{coinInfo?.underlying_symbol}</TYPE.white>&nbsp;&nbsp;
                          { !isMobile && <TYPE.white_o1 fontSize={12}>{t('(Reservoir Status & Configuration)')}</TYPE.white_o1> }
                        </TxtLine>
                        <TxtLine>
                          {
                            isMobile ?
                              <>
                                <TYPE.white_o7 fontSize={12}>{commafy2(Number(coinInfo?.utilization) * 100)}%</TYPE.white_o7>&nbsp;
                                <TYPE.white_o7 fontSize={12} fontWeight={400}>{t('(Usage Rate)')}</TYPE.white_o7>
                              </>
                            :
                              <>
                                <TYPE.white_o1 fontSize={12}>{commafy2(Number(coinInfo?.utilization) * 100)}%</TYPE.white_o1>&nbsp;
                                <TYPE.white_o1 fontSize={12} fontWeight={400}>{t('(Utilisation Ratio)')}</TYPE.white_o1>
                              </>
                          }
                        </TxtLine>
                      </PanelTitle>
                      {
                        isMobile ?
                          (
                            <ApyLineMobile>
                              <ProgressCircel
                                liquid={coinInfo?.cash}
                                borrow={coinInfo?.total_borrows}
                                name={coinInfo?.underlying_symbol}
                                address={coinInfo?.underlying_address}
                              ></ProgressCircel>
                              <RowBetween marginTop={'10px'}>
                                <LiquidityInfo>
                                  <IconCon>
                                    <Icon type="deposit"></Icon>
                                  </IconCon>
                                  <TYPE.white_o1 fontSize={12} marginBottom={'10px'}>Available Liquidity</TYPE.white_o1>
                                  <TYPE.body fontSize={16} marginBottom={'10px'}>${commafy2Lend(Number(coinInfo?.cash) * Number(coinInfo?.underlying_price))}</TYPE.body>
                                  <TYPE.white_o1 fontSize={12} marginBottom={'10px'}>{commafy2Lend(coinInfo?.cash)}&nbsp;{coinInfo?.underlying_symbol}</TYPE.white_o1>
                                </LiquidityInfo>
                                <BorrowInfo>
                                  <IconCon>
                                    <Icon type="borrow"></Icon>
                                  </IconCon>
                                  <TYPE.white_o1 fontSize={12} marginBottom={'10px'}>Total Borrowed</TYPE.white_o1>
                                  <TYPE.yellow_o fontSize={16} marginBottom={'10px'}>${commafy2Lend(Number(coinInfo?.total_borrows) * Number(coinInfo?.underlying_price))}</TYPE.yellow_o>
                                  <TYPE.white_o1 fontSize={12} marginBottom={'10px'}>{commafy2Lend(coinInfo?.total_borrows)}&nbsp;{coinInfo?.underlying_symbol}</TYPE.white_o1>
                                </BorrowInfo>
                              </RowBetween>
                            </ApyLineMobile>
                          )
                        :
                          (
                            <ApyLine>
                              <LiquidityInfo>
                                <IconCon>
                                  <Icon type="deposit"></Icon>
                                </IconCon>
                                <TYPE.white_o1 fontSize={12} marginBottom={'10px'}>Available Liquidity</TYPE.white_o1>
                                <TYPE.body fontSize={16} marginBottom={'10px'}>${commafy2Lend(Number(coinInfo?.cash) * Number(coinInfo?.underlying_price))}</TYPE.body>
                                <TYPE.white_o1 fontSize={12} marginBottom={'10px'}>{commafy2Lend(coinInfo?.cash)}&nbsp;{coinInfo?.underlying_symbol}</TYPE.white_o1>
                              </LiquidityInfo>
                              <ProgressCircel
                                liquid={coinInfo?.cash}
                                borrow={coinInfo?.total_borrows}
                                name={coinInfo?.underlying_symbol}
                                address={coinInfo?.underlying_address}
                              ></ProgressCircel>
                              <BorrowInfo>
                                <IconCon>
                                  <Icon type="borrow"></Icon>
                                </IconCon>
                                <TYPE.white_o1 fontSize={12} marginBottom={'10px'}>Total Borrowed</TYPE.white_o1>
                                <TYPE.yellow_o fontSize={16} marginBottom={'10px'}>${commafy2Lend(Number(coinInfo?.total_borrows) * Number(coinInfo?.underlying_price))}</TYPE.yellow_o>
                                <TYPE.white_o1 fontSize={12} marginBottom={'10px'}>{commafy2Lend(coinInfo?.total_borrows)}&nbsp;{coinInfo?.underlying_symbol}</TYPE.white_o1>
                              </BorrowInfo>
                            </ApyLine>
                          )
                      }
                      <TotalInfo>
                        <TotalInfoLine>
                          <TotalItemLine>
                            <TYPE.white_o1 fontSize={14}>{t('Deposit APR')}</TYPE.white_o1>
                            <div style={{display: 'flex'}}>
                              <RateView rate={state ? coinInfo?.supply_rate_with_reward : coinInfo?.supply_rate} />
                            </div>
                          </TotalItemLine>
                          <TotalItemLine>
                            <TYPE.white_o1 fontSize={14}>{t('Borrow APR')}</TYPE.white_o1>
                            <div style={{display: 'flex'}}>
                              <RateView rate={state ? coinInfo?.borrow_rate_with_reward : coinInfo?.borrow_rate} />
                            </div>
                          </TotalItemLine>
                          <TotalItemLine>
                            <TYPE.white_o1 fontSize={14}>{t('Reserve Factor')}</TYPE.white_o1>
                            <TYPE.white fontSize={14}>{rate2commafyFn(Number(coinInfo?.reserve_factor))}%</TYPE.white>
                          </TotalItemLine>
                          <TotalItemLine>
                            <TYPE.white_o1 fontSize={14}>{t('Collateral Factor')}</TYPE.white_o1>
                            <TYPE.white fontSize={14}>{rate2commafyFn(Number(coinInfo?.collateral_factor))}%</TYPE.white>
                          </TotalItemLine>
                          <TotalItemLine>
                            <TYPE.white_o1 fontSize={14}>{t('Liquidation penalty')}</TYPE.white_o1>
                            <TYPE.white fontSize={14}>{commafy2(new BigNumber(+coinInfo?.liquidation_incentive).minus(1).times(100).toString())}%</TYPE.white>
                          </TotalItemLine>
                          <TotalItemLine>
                            <TYPE.white_o1 fontSize={14}>{t('Used as collateral')}</TYPE.white_o1>
                            {
                              Number(coinInfo?.collateral_factor) === 0 ?
                              <TYPE.red_o fontSize={14}>No</TYPE.red_o>
                              :
                              <TYPE.green fontSize={14}>Yes</TYPE.green>
                            }
                          </TotalItemLine>
                        </TotalInfoLine>
                      </TotalInfo>
                    </PanelCon>
                  )
                :
                  (
                    <PanelConLoading>
                      { browserName === 'Firefox' && <Bg /> }
                      <LocalLoader fill={undefined} />
                    </PanelConLoading>
                  )
              }
              {
                coinInfo ?
                  (
                    <PanelCon>
                      { browserName === 'Firefox' && <Bg /> }
                      <PanelTitle>
                        <TxtLine>
                          <TYPE.white fontSize={16}>{t('Your Information')}</TYPE.white>
                        </TxtLine>
                      </PanelTitle>
                      {
                        account ? 
                          (
                            <>
                              <InformationCon>
                                <InformationLine marginBottom={'22px'}>
                                  <TYPE.body fontSize={16}>{t('Deposits')}</TYPE.body>
                                  {
                                    !isMobile && (
                                      <BtnGroup>
                                        <PayBtn disabled={!account || !depositInfo} marginRight={'10px'} onClick={() => {
                                          setDepositType(0);
                                          setShowDepositModal(true);
                                        }}>
                                          <TYPE.white marginTop={'-1px'}>{t('Deposit')}</TYPE.white>
                                        </PayBtn>
                                        <PayBtn disabled={!account || !depositInfo} onClick={() => {
                                          setDepositType(1);
                                          setShowDepositModal(true);
                                        }}>{t('Withdraw')}</PayBtn>
                                      </BtnGroup>
                                    )
                                  }
                                </InformationLine>
                                <InformationLine>
                                  <TYPE.white_o1 fontSize={14}>{t('Wallet Balance')}</TYPE.white_o1>
                                  <BtnGroup>
                                    <TYPE.body fontSize={14}>{commafy2Lend(balance?.toExact())}</TYPE.body>&nbsp;
                                    <TYPE.white fontSize={14}>{coinInfo?.underlying_symbol}</TYPE.white>
                                  </BtnGroup>
                                </InformationLine>
                                <InformationLine>
                                  <TYPE.white_o1 fontSize={14}>{t('Current Deposits')}</TYPE.white_o1>
                                  <BtnGroup>
                                    <TYPE.body fontSize={14}>{commafy2Lend(accountInfo?.supply_balance_underlying)}</TYPE.body>&nbsp;
                                    <TYPE.white fontSize={14}>{coinInfo?.underlying_symbol}</TYPE.white>
                                  </BtnGroup>
                                </InformationLine>
                                {
                                  isMobile && (
                                    <RowBetween>
                                      <PayBtn disabled={!account || !depositInfo} marginRight={'10px'} onClick={() => {
                                        setDepositType(0);
                                        setShowDepositModal(true);
                                      }}>
                                        <TYPE.white marginTop={'-1px'}>{t('Deposit')}</TYPE.white>
                                      </PayBtn>
                                      <PayBtn disabled={!account || !depositInfo} onClick={() => {
                                        setDepositType(1);
                                        setShowDepositModal(true);
                                      }}>{t('Withdraw')}</PayBtn>
                                    </RowBetween>
                                  )
                                }
                                <BorderLine />
                              </InformationCon>
                              <InformationCon>
                                <InformationLine marginBottom={'22px'}>
                                  <TYPE.yellow_o fontSize={16}>{t('Borrowings')}</TYPE.yellow_o>
                                  {
                                    !isMobile && (
                                      <BtnGroup>
                                        <PayBtn disabled={!account || !coinInfo} marginRight={'10px'} onClick={() => {
                                          setBorrowType(0);
                                          setShowBorrowModal(true)
                                        }}>{t('Borrow')}</PayBtn>
                                        <PayBtn disabled={!account || !coinInfo} onClick={() => {
                                          setBorrowType(1);
                                          setShowBorrowModal(true)
                                        }}>
                                          <TYPE.white marginTop={'-1px'}>{t('Repay')}</TYPE.white>
                                          </PayBtn>
                                      </BtnGroup>
                                    )
                                  }
                                </InformationLine>
                                <InformationLine>
                                  <TYPE.white_o1 fontSize={14}>{t('Borrowed')}</TYPE.white_o1>
                                  <BtnGroup>
                                    {/* sdk.totalBorrowBalance */}
                                    <TYPE.body fontSize={14}>{commafy2Lend(accountInfo?.borrow_balance_underlying)}</TYPE.body>&nbsp;
                                    <TYPE.white fontSize={14}>{coinInfo?.underlying_symbol}</TYPE.white>
                                  </BtnGroup>
                                </InformationLine>
                                <InformationLine marginBottom={isMobile ? '1.25rem' : '30px'}>
                                  <TYPE.white_o1 fontSize={14}>{t('Available')}</TYPE.white_o1>
                                  <BtnGroup>
                                    <TYPE.body fontSize={14}>{commafy2Lend(available, coinInfo?.underlying_decimals)}</TYPE.body>&nbsp;
                                    <TYPE.white fontSize={14}>{coinInfo?.underlying_symbol}</TYPE.white>
                                  </BtnGroup>
                                </InformationLine>
                                {
                                  isMobile && (
                                    <RowBetween style={{marginBottom: '1.25rem'}}>
                                      <PayBtn disabled={!account || !coinInfo} marginRight={'10px'} onClick={() => {
                                        setBorrowType(0);
                                        setShowBorrowModal(true)
                                      }}>{t('Borrow')}</PayBtn>
                                      <PayBtn disabled={!account || !coinInfo} onClick={() => {
                                        setBorrowType(1);
                                        setShowBorrowModal(true)
                                      }}>
                                        <TYPE.white marginTop={'-1px'}>{t('Repay')}</TYPE.white>
                                        </PayBtn>
                                    </RowBetween>
                                  )
                                }
                                <AccountElement active={!!account}>
                                  <Web3Status />
                                </AccountElement>
                              </InformationCon>
                            </>
                          )
                        :
                          (
                            <>
                              <InformationCon>
                                <InformationLine marginBottom={'22px'}>
                                  <TYPE.body fontSize={16}>{t('Deposits')}</TYPE.body>
                                  {
                                    !isMobile && (
                                      <BtnGroup>
                                        <PayBtn disabled={true} marginRight={'10px'}>
                                          <TYPE.white marginTop={'-1px'}>{t('Deposit')}</TYPE.white>
                                        </PayBtn>
                                        <PayBtn disabled={true}>{t('Withdraw')}</PayBtn>
                                      </BtnGroup>
                                    )
                                  }
                                </InformationLine>
                                <InformationLine>
                                  <TYPE.white_o1 fontSize={14}>{t('Wallet Balance')}</TYPE.white_o1>
                                  <BtnGroup>
                                    <TYPE.body fontSize={14}>--</TYPE.body>&nbsp;
                                    <TYPE.white fontSize={14}>{coinInfo?.underlying_symbol}</TYPE.white>
                                  </BtnGroup>
                                </InformationLine>
                                <InformationLine>
                                  <TYPE.white_o1 fontSize={14}>{t('Current Deposits')}</TYPE.white_o1>
                                  <BtnGroup>
                                    <TYPE.body fontSize={14}>--</TYPE.body>&nbsp;
                                    <TYPE.white fontSize={14}>{coinInfo?.underlying_symbol}</TYPE.white>
                                  </BtnGroup>
                                </InformationLine>
                                {
                                  isMobile && (
                                    <RowBetween>
                                      <PayBtn disabled={true} marginRight={'10px'}>
                                        <TYPE.white marginTop={'-1px'}>{t('Deposit')}</TYPE.white>
                                      </PayBtn>
                                      <PayBtn disabled={true}>{t('Withdraw')}</PayBtn>
                                    </RowBetween>
                                  )
                                }
                                <BorderLine />
                              </InformationCon>
                              <InformationCon>
                                <InformationLine marginBottom={'22px'}>
                                  <TYPE.yellow_o fontSize={16}>{t('Borrowings')}</TYPE.yellow_o>
                                  {
                                    !isMobile && (
                                      <BtnGroup>
                                        <PayBtn disabled={true} marginRight={'10px'}>{t('Borrow')}</PayBtn>
                                        <PayBtn disabled={true}>
                                          <TYPE.white marginTop={'-1px'}>{t('Repay')}</TYPE.white>
                                        </PayBtn>
                                      </BtnGroup>
                                    )
                                  }
                                </InformationLine>
                                <InformationLine>
                                  <TYPE.white_o1 fontSize={14}>{t('Borrowed')}</TYPE.white_o1>
                                  <BtnGroup>
                                    {/* sdk.totalBorrowBalance */}
                                    <TYPE.body fontSize={14}>--</TYPE.body>&nbsp;
                                    <TYPE.white fontSize={14}>{coinInfo?.underlying_symbol}</TYPE.white>
                                  </BtnGroup>
                                </InformationLine>
                                <InformationLine marginBottom={isMobile ? '1.25rem' : '30px'}>
                                  <TYPE.white_o1 fontSize={14}>{t('Available')}</TYPE.white_o1>
                                  <BtnGroup>
                                    <TYPE.body fontSize={14}>--</TYPE.body>&nbsp;
                                    <TYPE.white fontSize={14}>{coinInfo?.underlying_symbol}</TYPE.white>
                                  </BtnGroup>
                                </InformationLine>
                                {
                                  isMobile && (
                                    <RowBetween style={{marginBottom: '1.25rem'}}>
                                      <PayBtn disabled={true} marginRight={'10px'}>{t('Borrow')}</PayBtn>
                                      <PayBtn disabled={true}>
                                        <TYPE.white marginTop={'-1px'}>{t('Repay')}</TYPE.white>
                                        </PayBtn>
                                    </RowBetween>
                                  )
                                }
                                <AccountElement active={!!account}>
                                  <Web3Status />
                                </AccountElement>
                              </InformationCon>
                            </>
                          )
                      }
                    </PanelCon>
                  )
                :
                  (
                    <PanelConLoading>
                      { browserName === 'Firefox' && <Bg /> }
                      <LocalLoader fill={undefined} />
                    </PanelConLoading>
                  )
              }
              {
                showDepositModal && depositInfo ?
                  <DepositModal
                    showDepositModal={showDepositModal}
                    wrappedOnDismiss={closeDepositFn}
                    info={depositInfo}
                    personalAccount={personalAccount}
                    markets={markets}
                    type={depositType}
                    lendApyState={state}
                  ></DepositModal>
                :
                  null
              }
              {
                showBorrowModal && coinInfo ?
                  <BorrowModal
                    showBorrowModal={showBorrowModal}
                    wrappedOnDismiss={() => setShowBorrowModal(false)}
                    personalAccount={personalAccount}
                    info={coinInfo}
                    markets={markets}
                    type={borrowType}
                    lendApyState={state}
                  ></BorrowModal>
                :
                  null
              }

            </>
          )
        }
      </LendApyContext.Consumer>
    </ReservoirInfoCon>
  )
}

export default ReservoirInfo;