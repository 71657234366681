import React, { createContext } from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import Btn from './Button';
import { TYPE } from '../../theme';
import { useTranslation } from 'react-i18next';

const LendApyCon = styled.div`
  width: 156px;
  background: ${({theme}) => theme.lendBg};
  border-radius: 100px;
  // min-width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  height: 34px;
  margin-right: 16px;

  ${
    isMobile && css`
      margin-bottom: 20px;
      margin-right: 0;
      width: 100%;
    `
  }
`;
const BtnCon = styled.div`
  min-width: 40px;
`
const Txt = styled.div`
  display: flex;
  flex: 1;
  min-width: 100px;

  ${
    isMobile && css`
      flex-direction: row-reverse;
    `
  }
`;

export const LendApyContext = createContext(false);

function LendApyBtn({
  apyState,
  setApyRateState
}: {
  apyState: boolean,
  setApyRateState: () => void
}) {
  const { t } = useTranslation();

  return (
    <LendApyCon>
      <BtnCon>
        <Btn
          open={apyState}
          fn={ () => { setApyRateState(); } }
        />
      </BtnCon>
      <Txt>
        <TYPE.white fontWeight={400} fontSize={14}>{t('APR With FINN')}</TYPE.white>
      </Txt>
    </LendApyCon>
  );
}

export default LendApyBtn;
