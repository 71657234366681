import React from 'react';
import styled, { css } from 'styled-components';

const BtnCon = styled.div<{open?: boolean}>`
  width: 32px;
  height: 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  position: relative;
  cursor: pointer;
  ${
    ({open}) =>
    open && css`
      border-color: #fff;
      background: #42B5D9;
    `
  }
`;
const BtnCircle = styled.div<{open?: boolean}>`
  width: 14px;
  height: 14px;
  background: rgba(255, 255, 255, 0.05);
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transition: 0.4s all ease;
  position: absolute;
  top: 1px;
  left: 1px;
  ${
    ({open}) =>
    open && css`
      transform: translateX(100%);
      border-color: #fff;
      background: #FFFFFF;
    `
  }
`;

const Btn = (
  {
    open,
    fn
  }:{
    open?: boolean
    fn?: any
  }
) => {
  // const [open, setOpen] = useState(false);
  return (
    <BtnCon open={open} onClick={(e) => {
      e.stopPropagation();
      fn();
    }}>
      <BtnCircle open={open}></BtnCircle>
    </BtnCon>
  )
};

export default Btn;