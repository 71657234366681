import React, { useMemo } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled, { css } from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
// import { ETHER } from '@huckleberry/sdk'
import { ButtonPrimary } from '../Button'
// import { StakingInfo } from '../../state/stake/hooks'
// import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
import { Break, CardNoise, CardBGImage } from './styled'
// import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTranslation } from 'react-i18next'
import CurrencyLogo from '../CurrencyLogo'
// import { Countdown } from '../../pages/Excursion/Countdown'
// import useUSDCPrice from '../../utils/useUSDCPrice'
// import { useActiveWeb3React } from '../../hooks'
// import { FINN } from '../../constants'
import { isMobile } from 'react-device-detect'
import { FINN, TOM } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useFinnBarInfo, useStakeTomEarnFinnInfo } from '../../state/stake/hooks'
import { commafy2 } from '../../utils/commafy'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { TokenAmount } from '@huckleberry/sdk'
// import useUSDCPrice from '../../utils/useUSDCPrice'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  grid-gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    // display: none;
  `};
`

const Wrapper = styled(AutoColumn)<{
  showBackground: boolean,
  bgColor: any,
  displayCus: boolean
}>`
  border-radius:24px;
  width: 100%;
  min-height: 156px;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme }) => theme.cardBg };
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
  backdrop-filter: blur(20px);
  display: ${({ displayCus }) => displayCus ? 'inherit' : 'none'};
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

const MobileRowBetween = styled(RowBetween)`
  ${
    ({theme}) => theme.mediaWidth.upToSmall`
      ${
        isMobile && css`
          flex-wrap: wrap;
          width: 100%;
          & div {
            margin-bottom: 4px;
          }
        `
      }
    `
  }
`

// const APR = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
  ${
    ({theme}) => theme.mediaWidth.upToSmall`
      ${
        isMobile && css`
          flex-wrap: wrap;
          width: 100%;
          & div {
            margin-bottom: 4px;
          }
        `
      }
    `
  }
`

declare global {
  interface Window {
    tvlItems: any;
  }
}

export default function StakeTomCard({ i, onlyStaked }: { i: number, onlyStaked: boolean }) {
  const { chainId } = useActiveWeb3React()

  const token0 = chainId ? TOM[chainId] : TOM[1285]
  const token1 = chainId ? FINN[chainId] : FINN[1285]
  const uni = chainId ? FINN[chainId] : undefined;


  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)

  const { t } = useTranslation()
  const finnInfo = useFinnBarInfo()

  const info = useStakeTomEarnFinnInfo()

  // const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))
  const isStaking = Number(info.stakedAmount) > 0

  // get the color of the token
  // const token = currency0 === ETHER ? token1 : token0
  const backgroundColor = "blue"//useColor(token)

  // const { chainId } = useActiveWeb3React()
  // const uni = chainId ? FINN[chainId] : undefined

  const uniPrice = useUSDCPrice(token1)

  // if (info && uniPrice) {
  //   if (!window.tvlItems) {
  //     window.tvlItems = {}
  //   }
  //   window.tvlItems['excursion'] = (Number(info.totalStaked) * Number(uniPrice.toFixed(8))).toFixed(0)
  // }

  const symbol = 'FINN';

  const tomPrice = useMemo(() => {
    const price = uniPrice && finnInfo.times && !isNaN(finnInfo.times) && new TokenAmount(uni!, (1000000 * Number(finnInfo.times?.toFixed(6)) * Number(uniPrice?.toFixed(4))).toFixed(0)).multiply('1000000000000').toFixed(4)
    return price || '0'
  }, [finnInfo.times, uni, uniPrice])

  const myDeopsitPrice = useMemo(() => {
    let ret = info.stakedAmount.multipliedBy(tomPrice).toFormat(6)

    if (ret.indexOf(".") > 0) {
      ret = ret.replaceAll(/0+?$/g, "");
      ret = ret.replaceAll(/[.]$/g, "");
    }
    return ret
  }, [info.stakedAmount, tomPrice])

  const stakedUsd = info?.totalStaked && tomPrice ? (Number(info?.totalStaked.toExact()) * Number(tomPrice)) : undefined;

  const apy = stakedUsd && info?.totalRewardRate && tomPrice ? (Number(info?.totalRewardRate) * Number(uniPrice?.toFixed(8)) / 7 * 365 * 100 / Number(stakedUsd.toFixed(0))).toFixed(0) : '--' 

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor} displayCus={onlyStaked ? isStaking : true}>
      <CardBGImage desaturate />
      <CardNoise />

      <TopSection>
        <CurrencyLogo currency={currency1} size={'36px'} />
        <TYPE.white fontWeight={600} fontSize={18} style={{ marginLeft: '8px' }}>
          {currency0.symbol + ' → ' + symbol}
          {/* <Countdown exactEnd={stakingInfo?.periodFinish} exactStart={stakingInfo?.periodStart} /> */}
        </TYPE.white>

        <StyledInternalLink to={`/stakeTomEarnFinn/${currencyId(currency0)}`} style={{ width: '100%',color:'transparent' }}>
          <ButtonPrimary padding="8px" borderRadius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      <StatContainer>
        <MobileRowBetween>
          <TYPE.white>{t('totalDeposited')}</TYPE.white>
          <TYPE.yellow_o>
            {`${commafy2(info.totalStaked?info.totalStaked.toExact():0).split('.')[0]} TOM`}&nbsp;|&nbsp;${commafy2(stakedUsd).split('.')[0]}

            {
              apy && apy !== '--' && apy !== '0' ? ' 🔥 ' : null
            }
            {
              apy && apy !== '--' && apy !== '0' ? 'APR: ' + apy + '%' : null
            }
          </TYPE.yellow_o>
        </MobileRowBetween>
        <MobileRowBetween>
          <TYPE.white> Pool rate </TYPE.white>
          <TYPE.color_o>{`${commafy2(info.totalRewardRate).split('.')[0]} ${symbol} / week`}</TYPE.color_o>
        </MobileRowBetween>
      </StatContainer>

      {isStaking && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black fontWeight={500} color={"#909699"} marginBottom={isMobile ? '4px' : ''}>
              <span style={{ fontSize: "14px" }}>My Deposit ≈ </span>
              <span style={{ fontWeight: "bold", fontSize: "18px", color: "#42B5D9" }}>
                {`$${myDeopsitPrice}`}
              </span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={"#909699"}>
              <span style={{ fontSize: "14px" }}>My Rate: </span>
              {/* <span  id="animate-zoom" role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
              ⚡
              </span> */}
              {`${commafy2(info.rewardRate)} ${symbol} / week`}
            </TYPE.black>
          </BottomSection>
        </>
      )}
    </Wrapper>
  )
}
