import React, { useState, useMemo, useEffect, useContext } from 'react';
import { ModalLend } from '../Modal';
import { X } from 'react-feather';
import styled, { css, ThemeContext } from 'styled-components';
import { RowBetween } from '../Row';
import { TYPE } from '../../theme';
import { useTranslation } from 'react-i18next';
import useLend, { DepositMarketsItem, LendAccountTokens, Markets } from '../../state/lend/hooks';
import { commafy2, commafy2Lend } from '../../utils/commafy';
import { useLendContractV1, useLendContractV2 } from '../../hooks/useContract';
import { useActiveWeb3React } from '../../hooks';
import { BigNumber } from 'bignumber.js';
import { SUGGEST_GAS_PRICE } from '../../constants';
import { TransactionResponse } from '@ethersproject/providers';
import { useTransactionAdder } from '../../state/transactions/hooks';
import { useCurrencyBalance } from '../../state/wallet/hooks';
import { Currency, ETHER } from '@huckleberry/sdk';
import { useLendApproveCallback, ApprovalState } from '../../hooks/useApproveCallback';
import CurrencyLogo from '../CurrencyLogo';
import { useCurrency } from '../../hooks/Tokens';
import { Input as NumericalInput } from '../NumericalInput';
import Loader from '../Loader';
import { ArrowRight } from 'react-feather';
import { isMobile } from 'react-device-detect';

const StyledLoader = styled(Loader)`
  margin-right: 1rem;
`
const ModalContent = styled.div`
  width: 100%;
  position: relative;
`;
const Title = styled.div`
  padding: 24px 40px;
  width: 100%;
  ${
    isMobile && css`
      padding: 24px 20px;
    `
  }
`;
const Content = styled.div`
  padding: 14px 40px 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;

  ${
    isMobile && css`
      padding: 14px 20px 30px;
    `
  }
`;
const Nav = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`;
const NavItem = styled.div<{active?:boolean}>`
  padding: 15px 0;
  margin-right: 30px;
  border-bottom: 2px solid ${({theme, active}) => active ? theme.text1 : 'rgba(0, 0, 0, 0)'};
  color: ${({theme, active}) => active ? theme.text1 : '#fff'};
  font-size: 20px;
  cursor: pointer;
  ${
    isMobile && css`
      padding: 6px 0 10px;
      font-size: 1rem;
    `
  }
`;
const NavTypeContent = styled.div`
  margin-top: -1px;
`;
const NavTypeItem = styled(RowBetween)`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px 0;
  ${
    isMobile && css`
      padding: 1rem 0;
    `
  }
`;
const NavBalance = styled.div`
  display: flex;
`;
const Button = styled.button`
  border: none;
  background: #41B5D8;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 16px;
  font-size: 22px;
  width: 100%;
  cursor: pointer;
  
  :disabled {
    background: #4D4D4D;
    color: #999999;
    cursor: not-allowed;
  }

  > * {
    user-select: none;
  }
  ${
    isMobile && css`
      font-size: 1rem;
      height: 3.5rem;
    `
  }
`;
const InpCon = styled(RowBetween)`
  padding: 14px 20px;
  display: flex;
  border: 1px solid ${({theme}) => theme.text1 };
  border-radius: 16px;
  align-items: center;
`;
const InpBtnGroup = styled.div`
  display: flex;
  align-items: center;
`;
const InpNumber = styled(NumericalInput)`
  width: 0;
  margin-right: 10px;
  flex: 1;
  font-size: 24px;
  color: ${({theme}) => theme.text1 };
  border: none;
  background: none;
  outline: none;

  ::placeholder {
    color: ${({theme}) => theme.text8 };
  }
`;
const MaxBtn = styled.div`
  cursor: pointer;
  padding: 2px 8px;
  background: #42B4D9;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
`;
const MobileMaxBtn = styled.div`
  background: #42B4D9;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  white-space: nowrap;
  height: 2rem;
  margin-top: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MaxLine = styled.p`
  width: 1px;
  background: ${({theme}) => theme.text1 };
  margin: 0 6px;
`;
const LimitColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const LimitRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;
const LimitArrow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4px;
`;


const toNonExponential = (num: string) => {
  // console.log(num)
  const m = num.match(/\d(?:\.(\d*))?e([+-]\d+)/);
  const m1 = (m && m[1]) ? m[1].length : 0;
  const m2 = (m && m[2]) ? m[2] : 0;
  return Number(num).toFixed(Math.max(0, m1 - Number(m2)));
}
const DepositModal = (
  {
    showDepositModal,
    wrappedOnDismiss,
    info,
    personalAccount,
    markets,
    type,
    lendApyState
  }:{
    showDepositModal: boolean
    wrappedOnDismiss: () => void
    info: DepositMarketsItem
    personalAccount: LendAccountTokens | null
    markets?: Markets
    type?: number
    lendApyState: boolean
  }
) => {
  const theme = useContext(ThemeContext);
  const gas = 0.01;
  const { t } = useTranslation();
  const [showLoading, setShowLoading] = useState(false);
  const [showEnableLoading, setShowEnableLoading] = useState(false);
  const { account } = useActiveWeb3React();
  const userAccount: string | undefined = account ? account : undefined;
  const [approve, approveCallback] = useLendApproveCallback(info);
  const { useMaxFreeReedemAmount, useAllMaxFreeReedemAmount, useTotalBorrowBalance, useMaxBorrow } = useLend();
  const getMaxFn = useMaxFreeReedemAmount;
  const getAllMaxFn = useAllMaxFreeReedemAmount;
  const getTotalBorrowBalance = useTotalBorrowBalance;
  const getMaxBorrow = useMaxBorrow;
  
  const [curIndex, setCurIndex] = useState<number>(type ? type : 0);
  const [banBtn, setBanBtn] = useState(false);
  const [inpNum, setInpNum] = useState('');
  const [maxNum, setMaxNum] = useState('0');
  const [maxShowNum, setMaxShowNum] = useState('0');
  const [allMaxNum, setAllMaxNum] = useState('0');
  const [showAround, setShowAround] = useState(false);
  const [totalBorrowBalance, setTotalBorrowBalance] = useState(0);
  const [maxBorrow, setMaxBorrow] = useState(0);
  const [borrowLimitUse, setBorrowLimitUse] = useState(0);
  const [limitUsedChange, setLimitUsedChange] = useState('0');
  const [limitChange, setLimitChange] = useState('0');
  const [limitUnderlyingChange, setLimitUnderlyingChange] = useState('0');
  const [depositBalanceChange, setDepositBalanceChange] = useState('0');
  const [walletBalanceChange, setWalletBalanceChange] = useState('0');
  const [liquidityChange, setLiquidityChange] = useState('0');
  const [withdrawMethod, setWithdrawMethod] = useState('redeem');
  const [showWarn, setShowWarn] = useState(false);
  const [isMax, setIsMax] = useState(false);

  let currencyId: string | undefined = '';
  if (info?.underlying_symbol === 'MOVR') {
    currencyId = 'MOVR';
  } else {
    const underlyingAdress: string | undefined = info ? info.underlying_address : undefined;
    currencyId = underlyingAdress;
  }
  const currency: Currency | null |undefined = useCurrency(currencyId);
  const userCurrency: Currency | undefined = currency ? currency : undefined;
  const balance = useCurrencyBalance(userAccount, userCurrency);

  const address: string | undefined = info?.token_address ? info?.token_address : undefined;
  const contractV1 = useLendContractV1(address);
  const contractV2 = useLendContractV2(address);
  const LendContract = currency === ETHER ? contractV2 : contractV1;
  const addTransaction = useTransactionAdder();

  const curCoinInfo = useMemo(() => {
    const tokens = personalAccount?.tokens;
    const item =  tokens?.find(item => {
      return item.token_address === info.token_address;
    })
    return item;
  }, [info, personalAccount]);
  console.log('info-=', info)

  const depositFn = async () => {
    if (!LendContract || !info || !balance) return;
    const amount = new BigNumber(inpNum);
    const balanceBignum = new BigNumber(balance.toExact())
    if (amount.gt(balanceBignum)) return;
    const inpAmount = `0x${amount.shiftedBy(Number(info.underlying_decimals)).toString(16)}`;
    setShowLoading(true);
    if (currency === ETHER) {
      await LendContract
        .mint({ gasLimit: 2000000, gasPrice: SUGGEST_GAS_PRICE(), value: inpAmount })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Deposit ${info.underlying_symbol}`
          })
          wrappedOnDismiss();
          setShowLoading(false);
        })
        .catch((error: any) => {
          console.error(error)
          setShowLoading(false);
        })
    } else {
      await LendContract
        .mint(inpAmount, { gasLimit: 2000000, gasPrice: SUGGEST_GAS_PRICE(), value: '0' })
        // .mint({})
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Deposit ${info.underlying_symbol}`
          })
          wrappedOnDismiss();
          setShowLoading(false);
        })
        .catch((error: any) => {
          console.error(error)
          setShowLoading(false);
        })
    }
  }
  const withdrawFn = async () => {
    if (!LendContract || !info || !curCoinInfo) return;
    setShowLoading(true);
    if (withdrawMethod === 'redeem' && isMax) {
      const supplyBalance = new BigNumber(curCoinInfo.supply_balance);
      const supplyAmount = `0x${supplyBalance.shiftedBy(info.decimals).toString(16)}`;
      await LendContract
        .redeem(supplyAmount, { gasLimit: 2000000, gasPrice: SUGGEST_GAS_PRICE(), value: '0' })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw ${info.underlying_symbol}`
          })
          setShowLoading(false);
          wrappedOnDismiss();
        })
        .catch((error: any) => {
          console.error(error)
          setShowLoading(false);
        })
    } else {
      const amount = new BigNumber(isMax ? maxNum : inpNum);
      const inpAmount = `0x${amount.shiftedBy(Number(info.underlying_decimals)).toString(16)}`;
      await LendContract
        .redeemUnderlying(inpAmount, { gasLimit: 2000000, gasPrice: SUGGEST_GAS_PRICE(), value: '0' })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw ${info.underlying_symbol}`
          })
          setShowLoading(false);
          wrappedOnDismiss();
        })
        .catch((error: any) => {
          console.error(error)
          setShowLoading(false);
        })
    }
  }

  const useMaxFn = () => {
    let txt;
    if (curIndex) {
      if (new BigNumber(maxNum).gte(Number(curCoinInfo?.supply_balance_underlying))) {
        setShowAround(true);
      }
      setInpNum(maxShowNum);
      txt = maxNum;
    } else {
      let balanceNum: number = balance ? Number(balance.toExact()) : 0;
      if (info.underlying_symbol === 'MOVR') {
        const sBalanceNum = new BigNumber(balanceNum).minus(gas);
        balanceNum = sBalanceNum.gt(0) ? Number(sBalanceNum.toString()) : 0;
        txt = String(balanceNum)
        setInpNum(txt);
      } else {
        txt = balance ? balance.toExact() : '0'
        setInpNum(txt);
      }
    }
    limitUsedByInpFn(txt);
    setIsMax(true);
  }
  const limitUsedByInpFn = (inpStr: string) => {
    setIsMax(false);
    if (Number(inpStr) === 0) return;
    const maxBorrowBalanceBigNum = new BigNumber(maxBorrow);
    const leftBorrowLimitBigNum = maxBorrowBalanceBigNum.minus(totalBorrowBalance);
    const leftBorrowLimitStr = leftBorrowLimitBigNum.toString();
    const leftBorrowLimitLen = leftBorrowLimitStr.length - (leftBorrowLimitStr.includes('.') ? leftBorrowLimitStr.indexOf('.') : 0);
    const totalBorrowBalanceBigNum = new BigNumber(totalBorrowBalance);
    const inpNumBigNum = new BigNumber(inpStr);
    const inpNumUnderlying = inpNumBigNum.times(info.underlying_price).times(info.collateral_factor);
    let inpNumUnderlyingStr = inpNumUnderlying.toString();
    inpNumUnderlyingStr = inpNumUnderlyingStr.includes('e') ? toNonExponential(inpNumUnderlyingStr) : inpNumUnderlyingStr;
    let inpSplitLen = !maxBorrow ? +info.underlying_decimals : leftBorrowLimitLen;
    const inpNumUnderlyingSplitStr = inpNumUnderlyingStr.substring(0, (inpNumUnderlyingStr.includes('.') ? inpNumUnderlyingStr.indexOf('.') : 0) + inpSplitLen);
    const inpNumUnderlyingBigNum = new BigNumber(inpNumUnderlyingSplitStr);
    const depositBalanceBigNum = new BigNumber(curCoinInfo ? curCoinInfo.supply_balance_underlying : 0);
    const walletBalanceBigNum = new BigNumber(balance ? balance.toExact() : 0);
    const liquidityBigNum = new BigNumber(info.cash);
    setShowWarn(false);
    if (curIndex) {
      const liquidityNum = liquidityBigNum.minus(inpNumBigNum).gt(0) ? liquidityBigNum.minus(inpNumBigNum).toString() : '0';
      setLiquidityChange(liquidityNum);
    } else {
      const liquidityNum = liquidityBigNum.plus(inpNumBigNum).toString();
      setLiquidityChange(liquidityNum);
    }
    if (info.is_entered) {
      if (curIndex) {
        const depositChangeNum = depositBalanceBigNum.minus(inpNumBigNum);
        setDepositBalanceChange(depositChangeNum.gt(0) ? commafy2Lend(depositChangeNum, 6) : '0');
        const walletBalanceChangeBigNum = walletBalanceBigNum.plus(inpNumBigNum);
        setWalletBalanceChange(commafy2Lend(walletBalanceChangeBigNum.toString(), 6));
        const isLimit = inpNumUnderlyingBigNum.gt(maxBorrowBalanceBigNum);
        // console.log('inpNumUnderlyingBigNum', inpNumUnderlyingBigNum.toString(), 'maxBorrowBalanceBigNum', maxBorrowBalanceBigNum.toString(), 'isLimit', isLimit)
        if (isLimit) {
          setLimitChange('$0');
          setLimitUnderlyingChange('0');
          setLimitUsedChange('Liquidation');
          setShowWarn(true);
          return;
        }
        const chagneLimitNum = maxBorrowBalanceBigNum.minus(inpNumUnderlyingBigNum);
        // console.log('chagneLimitNum', chagneLimitNum.toString(), 'totalBorrowBalanceBigNum', totalBorrowBalanceBigNum.toString())
        setLimitChange('$' + commafy2Lend(chagneLimitNum.toString()));
        const limitUnderlyingChangeNum = chagneLimitNum.div(info.underlying_price)
        setLimitUnderlyingChange(commafy2Lend(limitUnderlyingChangeNum.toString()));
        const chagneLimitUsedNum = chagneLimitNum.gt(0) ? totalBorrowBalanceBigNum.div(chagneLimitNum).times(100) : new BigNumber(0);
        chagneLimitUsedNum.gt(80) && setShowWarn(true);
        setLimitUsedChange(chagneLimitUsedNum.gt(100) ? 'Liquidation' : commafy2(chagneLimitUsedNum) + '%');
      } else {
        const chagneLimitNum = maxBorrowBalanceBigNum.plus(inpNumUnderlyingBigNum);
        setLimitChange('$' + commafy2Lend(chagneLimitNum.toString()));
        const limitUnderlyingChangeNum = chagneLimitNum.div(info.underlying_price)
        setLimitUnderlyingChange(commafy2Lend(limitUnderlyingChangeNum.toString()));
        const chagneLimitUsedNum = chagneLimitNum.gt(0) ? totalBorrowBalanceBigNum.div(chagneLimitNum).times(100) : new BigNumber(0);
        // console.log(totalBorrowBalanceBigNum.toString(), maxBorrowBalanceBigNum.toString(), chagneLimitNum.toString())
        chagneLimitUsedNum.gt(80) && setShowWarn(true);
        setLimitUsedChange(chagneLimitUsedNum.gt(100) ? 'Liquidation' : commafy2(chagneLimitUsedNum.toString()) + '%');
        const depositChangeNum = depositBalanceBigNum.plus(inpNumBigNum);
        setDepositBalanceChange(commafy2Lend(depositChangeNum, 6));
        const walletBalanceChangeBigNum = walletBalanceBigNum.minus(inpNumBigNum);
        setWalletBalanceChange(walletBalanceChangeBigNum.gt(0) ? commafy2Lend(walletBalanceChangeBigNum.toString(), 6) : '0');

      }
    } else {
      setLimitChange('$' + commafy2Lend(maxBorrowBalanceBigNum.toString()));
      const limitUnderlyingChangeNum = maxBorrowBalanceBigNum.div(info.underlying_price)
      setLimitUnderlyingChange(commafy2Lend(limitUnderlyingChangeNum.toString()));
      setLimitUsedChange(commafy2(borrowLimitUse) + '%');
      const depositChangeNum = curIndex ? depositBalanceBigNum.minus(inpNumBigNum) : depositBalanceBigNum.plus(inpNumBigNum);
      setDepositBalanceChange(depositChangeNum.gt(0) ? commafy2Lend(depositChangeNum, 6) : '0');
      const walletBalanceChangeBigNum = curIndex ? walletBalanceBigNum.plus(inpNumBigNum) : walletBalanceBigNum.minus(inpNumBigNum);
      setWalletBalanceChange(walletBalanceChangeBigNum.gt(0) ? commafy2Lend(walletBalanceChangeBigNum.toString(), 6) : '0');
    }
  }
  useEffect(() => {
    if (!personalAccount || !markets || !info || !curCoinInfo) return;
    getMaxFn(personalAccount, markets, info.token_address)
      .then(res => {
        if (!res) return;
        const {
          amount,
          method
        }: {
          amount: string
          method: string
        } = res;
        setWithdrawMethod(method);
        // console.log(res, method)
        const num = new BigNumber(amount).gt(info.cash) ? info.cash : amount;
        const min = new BigNumber(num).gt(curCoinInfo.supply_balance_underlying) ? curCoinInfo.supply_balance_underlying : num;
        const minBigNum = new BigNumber(min)
        console.log('cash', info.cash, 'res', res, 'supply', curCoinInfo.supply_balance_underlying)
        setMaxNum(minBigNum.gt(0) ? minBigNum.toFixed(+info.underlying_decimals, BigNumber.ROUND_FLOOR) : '0');
        setMaxShowNum(minBigNum.gt(0) ? minBigNum.toFixed(+info.underlying_decimals, BigNumber.ROUND_UP) : '0')
      })
    getAllMaxFn(personalAccount, markets, info.token_address)
      .then(res => {
        if (!res) return;
        const {
          amount
        }: {
          amount: string
        } = res;
        const num = new BigNumber(amount);
        console.log('allMax-=-=-', num.gt(0) ? num.toFixed(+info.underlying_decimals, BigNumber.ROUND_FLOOR) : '0')
        setAllMaxNum(num.gt(0) ? num.toFixed(+info.underlying_decimals, BigNumber.ROUND_FLOOR) : '0')
      })
  }, [curCoinInfo, getAllMaxFn, getMaxFn, info, markets, personalAccount]);

  useEffect(() => {
    if (!personalAccount || !markets) return;
    getTotalBorrowBalance(personalAccount, markets)
      .then(res => {
        setTotalBorrowBalance(res);
      })
    getMaxBorrow(personalAccount, markets)
      .then(res => {
        setMaxBorrow(res);
      })
  }, [getMaxBorrow, getTotalBorrowBalance, markets, personalAccount]);

  useEffect(() => {
    let isUnmount = false;
    const changeBorrowLimitFn = () => {
      if (isUnmount) return;
      const num = maxBorrow ? totalBorrowBalance / maxBorrow * 100 : 0;
      setBorrowLimitUse(num);
    }
    changeBorrowLimitFn();
    return () => {
      isUnmount = true;
    };
  }, [maxBorrow, totalBorrowBalance]);
  
  useEffect(() => {
    setInpNum('');
    setShowAround(false);
    setShowLoading(false);
  }, [curIndex]);

  useEffect(() => {
    let enough = true;

    if (!enough) return;
    const isInp = !+inpNum;
    const inpNumber = new BigNumber(inpNum);
    const maxBorrowBigNum = new BigNumber(maxBorrow);
    const totalBorrowBalanceBigNum = new BigNumber(totalBorrowBalance);
    const leftBorrowLimitBigNum = maxBorrowBigNum.minus(totalBorrowBalance);
    const leftBorrowLimitStr = leftBorrowLimitBigNum.toString();
    const leftBorrowLimitLen = leftBorrowLimitStr.length - (leftBorrowLimitStr.includes('.') ? leftBorrowLimitStr.indexOf('.') : 0);
    const depositBalanceBigNumber = new BigNumber(curCoinInfo ? curCoinInfo.supply_balance_underlying : 0);
    if (curIndex) {      
      const depositUnderlying = inpNumber.times(info.underlying_price).times(info.collateral_factor);
      let depositUnderlyingStr = depositUnderlying.toString();
      // console.log('depositUnderlyingStr====', depositUnderlyingStr)
      let inpSplitLen = !maxBorrow ? +info.underlying_decimals : leftBorrowLimitLen;
      depositUnderlyingStr = depositUnderlyingStr.includes('e') ? toNonExponential(depositUnderlyingStr) : depositUnderlyingStr;
      const inpNumUnderlyingSplitStr = depositUnderlyingStr.substring(0, (depositUnderlyingStr.includes('.') ? depositUnderlyingStr.indexOf('.') : 0) + inpSplitLen);
      const depositUnderlyingBigNum = new BigNumber(inpNumUnderlyingSplitStr);
      // console.log('dfdfdf-=-=-', leftBorrowLimitBigNum.toString(), depositUnderlyingBigNum.toString(), inpNumUnderlyingSplitStr, depositUnderlyingStr)
      const depositNotEnough = curCoinInfo?.is_entered && totalBorrowBalanceBigNum.gt(0) && depositUnderlyingBigNum.gt(leftBorrowLimitBigNum);
      // const depositNotEnough = totalBorrowBalanceBigNum.gt(0) && depositUnderlyingBigNum.gt(leftBorrowLimitBigNum);
      const cashBigNum = new BigNumber(info.cash);
      // console.log('cashBigNum===', cashBigNum.toString());
      const allMaxBigNum = new BigNumber(allMaxNum);
      // console.log('cashBigNum===', cashBigNum.toString());
      // console.log('allMaxBigNum===', allMaxBigNum.toString());
      // console.log(isInp , inpNumber.gt(depositBalanceBigNumber) , depositNotEnough , showLoading)
      console.log(isInp , inpNumber.gt(depositBalanceBigNumber), inpNumber.gt(cashBigNum), inpNumber.gt(allMaxBigNum), depositNotEnough, showLoading)
      setBanBtn(isInp || inpNumber.gt(depositBalanceBigNumber) || inpNumber.gt(cashBigNum) || inpNumber.gt(allMaxBigNum) || depositNotEnough || showLoading);
    } else {
      const balanceNumber = new BigNumber(balance ? balance.toExact() : 0);
      setBanBtn(isInp || inpNumber.gt(balanceNumber) || showLoading);
    }
    
    return () => {
      enough = false;
    }
  }, [balance, curIndex, inpNum, maxNum, showLoading, totalBorrowBalance, info, maxBorrow, curCoinInfo, allMaxNum]);

  return (
    <ModalLend
      bg={theme.lendModalBg}
      border={'1px solid rgba(255, 255, 255, 0.2)'}
      newEnLarge={true}
      isOpen={showDepositModal}
      onDismiss={wrappedOnDismiss}
    >
      <ModalContent>
        <Title>
          <RowBetween style={{marginBottom: '18px'}}>
            <InpBtnGroup>
              <CurrencyLogo currency={userCurrency} size={'42px'} style={{marginRight: '10px'}}></CurrencyLogo>
              <TYPE.white fontSize={16}>{info?.underlying_symbol}</TYPE.white>
            </InpBtnGroup>
            <X size={26} color={'#fff'} onClick={wrappedOnDismiss}></X>
          </RowBetween>
          {
            !curIndex && (approve !== ApprovalState.APPROVED) ?
              <TYPE.white fontSize={12}>{t(`To Deposit or Repay ${info?.underlying_symbol}, you need to enable it first.`)}</TYPE.white>
            :
              (
                <>
                  <InpCon>
                    { showAround && '≈'}
                    <InpNumber value={inpNum} placeholder=" " onUserInput={(input) => {
                      let txt = input;
                      const len = input.length - 1;
                      const pointPos = input.includes('.') ? input.indexOf('.') : len;
                      const subPos = pointPos + +info?.underlying_decimals + 1;
                      txt = txt.substring(0, subPos);
                      setInpNum(txt);
                      limitUsedByInpFn(txt);
                      setShowAround(false);
                    }} />
                    <InpBtnGroup>
                      { !isMobile && <MaxBtn onClick={useMaxFn}>{curIndex === 1 ? '80% LIMIT' : 'MAX'}</MaxBtn> }
                      <MaxLine></MaxLine>
                      <TYPE.body>{info?.underlying_symbol}</TYPE.body>
                    </InpBtnGroup>
                  </InpCon>
                  { isMobile && <MobileMaxBtn onClick={useMaxFn}>{curIndex === 1 ? '80% LIMIT' : 'MAX'}</MobileMaxBtn> }
                </>
              )
          }
        </Title>
        <Content>
          <Nav>
            <NavItem active={curIndex === 0} onClick={() => setCurIndex(0)}>{t('Deposit')}</NavItem>
            <NavItem active={curIndex === 1} onClick={() => setCurIndex(1)}>{t('Withdraw')}</NavItem>
          </Nav>
          <NavTypeContent>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={16}>{t('Deposit APR')}</TYPE.white_o1>
              <TYPE.body fontSize={16}>{commafy2(Number(lendApyState ? info?.supply_rate_with_reward : info?.supply_rate) * 100)}%</TYPE.body>
            </NavTypeItem>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={16}>{t('Borrow Limit')}</TYPE.white_o1>
              <LimitRight>
                {/* maxBorrow */}
                <LimitColumn>
                  <TYPE.body fontSize={16}>${commafy2Lend(maxBorrow)}</TYPE.body>
                  <TYPE.white_o1 fontSize={14}>{commafy2Lend(maxBorrow / +info.underlying_price)}&nbsp;{info.underlying_symbol}</TYPE.white_o1>
                </LimitColumn>
                {
                  +inpNum ?
                    (
                      <LimitArrow>
                        <ArrowRight size={16} color={'#4DD4FE'}></ArrowRight>
                      </LimitArrow>
                    )
                  :
                    null
                }
                {
                  +inpNum ?
                    (
                      <LimitColumn>
                        <TYPE.body fontSize={16}>{limitChange}</TYPE.body>
                        <TYPE.white_o1 fontSize={14}>{limitUnderlyingChange}&nbsp;{info.underlying_symbol}</TYPE.white_o1>
                      </LimitColumn>
                    )
                  :
                    null
                }
              </LimitRight>
            </NavTypeItem>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={16}>{t('Borrow Limit Used')}</TYPE.white_o1>
              <LimitRight>
                {/* totalBorrowBalance/maxBorrow */}
                <TYPE.body fontSize={16}>{commafy2(borrowLimitUse)}%</TYPE.body>
                {
                  +inpNum ?
                    (
                      <LimitArrow>
                        <ArrowRight size={16} color={'#4DD4FE'}></ArrowRight>
                      </LimitArrow>
                    )
                  :
                    null
                }
                {
                  +inpNum ?
                    (
                      <TYPE.body color={showWarn ? '#FF5B45' : '#4DD4FE'} fontSize={16}>{limitUsedChange}</TYPE.body>
                    )
                  :
                    null
                }
              </LimitRight>
            </NavTypeItem>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={16}>{t('Deposit Balance')}</TYPE.white_o1>
              <LimitRight>
                <NavBalance>
                  {/* totalBorrowBalance/maxBorrow */}
                  <TYPE.body fontSize={16}>{curCoinInfo?.supply_balance_underlying ? commafy2Lend(curCoinInfo?.supply_balance_underlying, 6) : 0}</TYPE.body>&nbsp;
                  <TYPE.white fontSize={16}>{info?.underlying_symbol}</TYPE.white>
                </NavBalance>
                {
                  +inpNum ?
                    (
                      <LimitArrow>
                        <ArrowRight size={16} color={'#4DD4FE'}></ArrowRight>
                      </LimitArrow>
                    )
                  :
                    null
                }
                {
                  +inpNum ?
                    (
                      <NavBalance>
                        <TYPE.body fontSize={16}>{depositBalanceChange}</TYPE.body>&nbsp;
                        <TYPE.white fontSize={16}>{info?.underlying_symbol}</TYPE.white>
                      </NavBalance>
                    )
                  :
                    null
                }
              </LimitRight>
            </NavTypeItem>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={16}>{t('Wallet Balance')}</TYPE.white_o1>
              <LimitRight>
                <NavBalance>
                  <TYPE.body fontSize={16}>{commafy2Lend(balance?.toExact(), 6)}</TYPE.body>&nbsp;
                  <TYPE.white fontSize={16}>{info?.underlying_symbol}</TYPE.white>
                </NavBalance>
                {
                  +inpNum ?
                    (
                      <LimitArrow>
                        <ArrowRight size={16} color={'#4DD4FE'}></ArrowRight>
                      </LimitArrow>
                    )
                  :
                    null
                }
                {
                  +inpNum ?
                    (
                      <NavBalance>
                        <TYPE.body fontSize={16}>{walletBalanceChange}</TYPE.body>&nbsp;
                        <TYPE.white fontSize={16}>{info?.underlying_symbol}</TYPE.white>
                      </NavBalance>
                    )
                  :
                    null
                }
              </LimitRight>
            </NavTypeItem>
            <NavTypeItem>
              <TYPE.white_o1 fontSize={16}>{t('Available Liquidity')}</TYPE.white_o1>
              <LimitRight>
                <NavBalance>
                  <TYPE.body fontSize={16}>{commafy2Lend(info.cash, 6)}</TYPE.body>&nbsp;
                  <TYPE.white fontSize={16}>{info?.underlying_symbol}</TYPE.white>
                </NavBalance>
                {
                  +inpNum ?
                    (
                      <LimitArrow>
                        <ArrowRight size={16} color={'#4DD4FE'}></ArrowRight>
                      </LimitArrow>
                    )
                  :
                    null
                }
                {
                  +inpNum ?
                    (
                      <NavBalance>
                        <TYPE.body fontSize={16}>{commafy2Lend(liquidityChange, 6)}</TYPE.body>&nbsp;
                        <TYPE.white fontSize={16}>{info?.underlying_symbol}</TYPE.white>
                      </NavBalance>
                    )
                  :
                    null
                }
              </LimitRight>
            </NavTypeItem>
          </NavTypeContent>
          {
            curIndex ?
              <Button disabled={banBtn} onClick={withdrawFn}>
                {
                  showLoading && <StyledLoader stroke={'#fff'} />
                }
                {t('Withdraw')}
              </Button>
            :
              approve === ApprovalState.APPROVED ?
                <Button disabled={banBtn} onClick={depositFn}>
                  {
                    showLoading && <StyledLoader stroke={'#fff'} />
                  }
                  {t('Deposit')}
                </Button>
              :
                <Button disabled={!account || approve === ApprovalState.PENDING || showEnableLoading} onClick={() => {
                  setShowEnableLoading(true);
                  approveCallback().catch(() => {
                    setShowEnableLoading(false);
                  });
                }}>
                  {
                    (approve === ApprovalState.PENDING || showEnableLoading) &&
                    <StyledLoader stroke={'#fff'} />
                  }
                  {t('Enable')}
                </Button>
          }
        </Content>
      </ModalContent>
    </ModalLend>
  )
};

export default DepositModal;