import React from 'react';
import styled, { css } from 'styled-components';

const DropBoottom = styled.div<{ show: boolean }>`
  margin-top: -1rem;
  width: 100%;
  max-width: 80%;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: -2;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  height: 0;
  overflow: hidden;
  // transition: all 300ms ease-in-out;
  font-size: 14px;
  font-weight: 400;
  color: #FF662E;
  line-height: 22px;
  backdrop-filter: blur(20px);
  ${
    ({ show }) => show && css`
      height: auto;
      padding: 20px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-shadow: 2px 2px 8px #0000002e;
    `
  }
`

export default function AdvancedSwapDetailsDropdown({ show, detail }: {show: boolean, detail: string}) {

  return (
    <DropBoottom show={Boolean(show)}>
      {detail}
    </DropBoottom>
  )
}
