import React, { useMemo } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled, { css, keyframes } from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
// import { ETHER } from '@huckleberry/sdk'
import { ButtonPrimary } from '../Button'
// import { StakingInfo } from '../../state/stake/hooks'
// import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
import { Break, CardNoise, CardBGImage } from './styled'
// import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTranslation } from 'react-i18next'
import CurrencyLogo from '../CurrencyLogo'
// import { Countdown } from '../../pages/Excursion/Countdown'
// import useUSDCPrice from '../../utils/useUSDCPrice'
// import { useActiveWeb3React } from '../../hooks'
// import { FINN } from '../../constants'
import { isMobile } from 'react-device-detect'
import { TOM, FINN } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useFinnBarInfo, useStakeTomEarnTomInfo, useStakeTomEarnFinnInfo } from '../../state/stake/hooks'
import { commafy2, commafy3 } from '../../utils/commafy'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { TokenAmount } from '@huckleberry/sdk'
// import useUSDCPrice from '../../utils/useUSDCPrice'
import BN from 'bignumber.js'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  grid-gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    // display: none;
  `};
`

const AnimationName = keyframes`
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
`

const animation = css`
  ${AnimationName} 20s ease infinite;
`

const Wrapper = styled(AutoColumn)<{
  showBackground: boolean,
  bgColor: any,
  displayCus: boolean
}>`
  border-radius:24px;
  width: 100%;
  min-height: 110px;
  overflow: hidden;
  position: relative;
  padding: 2px;
  background: linear-gradient(270deg, #28e0b0, #c328e0, #28a1e0, #e0286b, #3EB3F4, #C53EF4, #6C3AD8, #3AD8B2)  center / cover;
  background-size: 800% 800%;
  animation: ${animation};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
  backdrop-filter: blur(20px);
  display: ${({ displayCus }) => displayCus ? 'inherit' : 'none'};
`

const WrapperContent = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.cardBg };
  border-radius: 22px;
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

const MobileRowBetween = styled(RowBetween)`
  ${
    ({theme}) => theme.mediaWidth.upToSmall`
      ${
        isMobile && css`
          flex-wrap: wrap;
          width: 100%;
          & div {
            margin-bottom: 4px;
          }
        `
      }
    `
  }
`

// const APR = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
  ${
    ({theme}) => theme.mediaWidth.upToSmall`
      ${
        isMobile && css`
          flex-wrap: wrap;
          width: 100%;
          & div {
            margin-bottom: 4px;
          }
        `
      }
    `
  }
`

const MagicTxt = styled.span`
  background: linear-gradient(270deg, #28e0b0, #c328e0, #f2fe4d)  center / cover;
  -webkit-background-clip: text;
  color: transparent;
`

declare global {
  interface Window {
    tvlItems: any;
  }
}

export default function StakeAutoTomCard({ i, onlyStaked }: { i: number, onlyStaked: boolean }) {
  const { chainId } = useActiveWeb3React()

  const token0 = chainId ? TOM[chainId] : TOM[1285]
  const token1 = chainId ? FINN[chainId] : FINN[1285]
  const uni = chainId ? TOM[chainId] : undefined;


  const currency0 = unwrappedToken(token0)

  const { t } = useTranslation()
  const finnInfo = useFinnBarInfo()

  const info = useStakeTomEarnTomInfo()
  const tom2finnInfo = useStakeTomEarnFinnInfo()

  // const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))
  const isStaking = Number(info.stakedAmount) > 0

  // get the color of the token
  // const token = currency0 === ETHER ? token1 : token0
  const backgroundColor = "blue"//useColor(token)

  // const { chainId } = useActiveWeb3React()
  // const uni = chainId ? FINN[chainId] : undefined

  const uniPrice = useUSDCPrice(token1);

  // if (info && uniPrice) {
  //   if (!window.tvlItems) {
  //     window.tvlItems = {}
  //   }
  //   window.tvlItems['excursion'] = (Number(info.totalStaked) * Number(uniPrice.toFixed(8))).toFixed(0)
  // }

  // const symbol = 'TOM';
  const tomPrice = useMemo(() => {
    const price = uniPrice && finnInfo.times && !isNaN(finnInfo.times) && new TokenAmount(uni!, (1000000 * Number(finnInfo.times?.toFixed(6)) * Number(uniPrice?.toFixed(4))).toFixed(0)).multiply('1000000000000').toFixed(4)
    return price || '0'
  }, [finnInfo.times, uni, uniPrice])

  const myDeopsitPrice = useMemo(() => {
    let ret = info.stakedAmount.multipliedBy(tomPrice).toFormat(6)

    if (ret.indexOf(".") > 0) {
      ret = ret.replaceAll(/0+?$/g, "");
      ret = ret.replaceAll(/[.]$/g, "");
    }
    return ret
  }, [info.stakedAmount, tomPrice])

  const stakedFinnUsd = tom2finnInfo?.totalStaked && tomPrice ? (Number(tom2finnInfo?.totalStaked.toExact()) * Number(tomPrice)) : undefined;
  const stakedTomUsd = info?.totalStaked && tomPrice ? (Number(info?.totalStaked.toString()) * Number(tomPrice)) : undefined;

  const apr = stakedFinnUsd && tom2finnInfo?.totalRewardRate && tomPrice ? (Number(tom2finnInfo?.totalRewardRate) * Number(uniPrice?.toFixed(8)) / 7 * 365 * 100 / Number(stakedFinnUsd.toFixed(0))).toFixed(0) : '';
  const apy = apr === '' ? '--' : new BN(apr).div(365).div(100).plus(1).exponentiatedBy(365).minus(1).times(100).times(info?.apyRate).toFixed(0);


  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor} displayCus={onlyStaked ? isStaking : true}>
      <WrapperContent>
        <CardBGImage desaturate />
        <CardNoise />

        <TopSection>
          <CurrencyLogo currency={currency0} size={'36px'} />
          <TYPE.white fontWeight={600} fontSize={18} style={{ marginLeft: '8px' }}>
          au<MagicTxt>
              TOM
            </MagicTxt>atic driftin’
            {/* <Countdown exactEnd={stakingInfo?.periodFinish} exactStart={stakingInfo?.periodStart} /> */}
            <TYPE.black fontWeight={400} fontSize={'13px'}>
              Stake TOM. Earn TOM. Auto-compounded.
            </TYPE.black>
          </TYPE.white>

          <StyledInternalLink to={`/stakeTomEarnTom/${currencyId(currency0)}`} style={{ width: '100%',color:'transparent' }}>
            <ButtonPrimary padding="8px" borderRadius="8px">
              {isStaking ? 'Manage' : 'Deposit'}
            </ButtonPrimary>
          </StyledInternalLink>
        </TopSection>

        <StatContainer>
          <MobileRowBetween>
            <TYPE.white>{t('totalDeposited')}</TYPE.white>
            <TYPE.yellow_o>
              {`${commafy2(info.totalStaked?info.totalStaked.toString():0).split('.')[0]} TOM`}&nbsp;|&nbsp;${commafy2(stakedTomUsd).split('.')[0]}

              {
                apy && apy !== '--' && apy !== '0' ? ' 🔥 ' : null
              }
              {
                apy && apy !== '--' && apy !== '0' ? 'APY: ' + commafy3(apy) + '%' : null
              }
            </TYPE.yellow_o>
          </MobileRowBetween>
        </StatContainer>

        {isStaking && (
          <>
            <Break />
            <BottomSection showBackground={true}>
              <TYPE.black fontWeight={500} color={"#909699"} marginBottom={isMobile ? '4px' : ''}>
                <span style={{ fontSize: "14px" }}>My Deposit ≈ </span>
                <span style={{ fontWeight: "bold", fontSize: "18px", color: "#42B5D9" }}>
                  {`$${myDeopsitPrice}`}
                </span>
              </TYPE.black>

              <TYPE.black style={{ textAlign: 'right' }} color={"#909699"}></TYPE.black>
            </BottomSection>
          </>
        )}
      </WrapperContent>
    </Wrapper>
  )
}
