import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { RowBetween } from '../Row';
import { TYPE } from '../../theme';
import { useTranslation } from 'react-i18next';
import depositIcon from '../../assets/png/Lend/deposit.svg';
import borrowIcon from '../../assets/png/Lend/borrow.svg';
// import Btn from './Button';
import { CountUp } from 'use-count-up';
import useLend from '../../state/lend/hooks';
import { commafy2Lend } from '../../utils/commafy';
import { isMobile, browserName } from 'react-device-detect';

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`
const InfoCon = styled(RowBetween)`
  margin-bottom: 20px;

  ${
    isMobile && css`
      flex-direction: column;
      margin-bottom: 0;
    `
  }
`;
const APYInfoLine = styled.div`
  width: 49%;
  border: 1px solid ${({theme}) => theme.text8 };
  border-radius: 16px;
  background: ${({theme}) => theme.lendBg};
  backdrop-filter: blur(20px);
  position: relative;

  ${
    isMobile && css`
      width: 100%;
      margin-bottom: 1.25rem;
    `
  }
`;
const APYInfoCon = styled.div`
  flex: 1;
  padding: 0 20px;
`;
const APYHeiLine = styled.p`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin: 0;
`;
const Progress = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.05);
`;
const widthYellowOut = (width: number) => {
  return keyframes`
  0% {
    width: 0;
    background: #FFC70B;
  }
  90% {
    width: ${width*0.9}%;
    background: ${width*0.9 <= 90 ? '#FFC70B' : '#FF0B0B' };
  }
  100% {
    width: ${width}%;
    background: ${width <= 90 ? '#FFC70B' : '#FF0B0B' };
  }
`
}
const ProgressLine = styled.div<{width: number}>`
  width: ${({width}) => width}%;
  height: 100%;
  position: abolute;
  top: 0;
  left: 0;
  border-radius: 5px;
`;
const ProgressBorrowLine = styled(ProgressLine)<{width: number}>`
  background: ${({width}) => width < 90 ? '#FFC70B' : '#FF0B0B' };
  animation: ${({width}) => widthYellowOut(width)} 0.7s linear;
`;
const BalanceInfo = styled(RowBetween)`
  flex: 1;
  padding: 16px 20px;
`;
const Icon = styled.div<{
  type?: string
  marginRight?: string
  marginLeft?: string
}>`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.text8};
  background-image: url(${ ({type}) => type === 'deposit' ? depositIcon : borrowIcon });
  background-size: 36px 40px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: ${({marginRight}) => marginRight ? marginRight : '0px'};
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};
`;
const BalanceInfoCon = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;
// const BtnGroup = styled.div`
//   display: flex;
//   justify-content: space-between;
//   flex-direction: column;
//   align-items: center;
//   padding: 10px 0;
// `;

const InfoContent = ({
  lendApyState
}: {
  lendApyState: boolean
}) => {
  const { t } = useTranslation();
  // const [open, setOpen] = useState(false);
  const { totalInfo } = useLend();
  
  return (
    <InfoCon>
      <APYInfoLine>
        { browserName === 'Firefox' && <Bg /> }
        <BalanceInfo>
          <BalanceInfoCon>
            <TYPE.body marginBottom={'10px'} fontSize={16}>{t('Deposit Balance')}</TYPE.body>
            <TYPE.white fontSize={24} color={+totalInfo?.totalSupplyBalance ? '#fff' : 'rgba(255, 255, 255, 0.7)'}>${commafy2Lend(totalInfo?.totalSupplyBalance)}</TYPE.white>
          </BalanceInfoCon>
          <Icon type="deposit"></Icon>
        </BalanceInfo>
        {/* <BtnGroup>
          <Btn open={open} fn={() => setOpen(!open)}></Btn>
          <TYPE.white_o fontSize={14}>{t('APY With FINN')}</TYPE.white_o>
        </BtnGroup> */}
        <APYHeiLine />
        <BalanceInfo>
          <BalanceInfoCon>
            <TYPE.yellow_o marginBottom={'10px'}>{t('Borrow Balance')}</TYPE.yellow_o>
            <TYPE.white fontSize={24} color={+totalInfo?.totalBorrowBalance ? '#fff' : 'rgba(255, 255, 255, 0.7)'}>${commafy2Lend(totalInfo?.totalBorrowBalance)}</TYPE.white>
          </BalanceInfoCon>
          <Icon type="borrow"></Icon>
        </BalanceInfo>
      </APYInfoLine>
      <APYInfoLine>
        { browserName === 'Firefox' && <Bg /> }
        <APYInfoCon style={{paddingTop: '38px', paddingBottom: '42px'}}>
          <RowBetween>
            <TYPE.white fontSize={16}>{t('Net APR')}</TYPE.white>
            <TYPE.white fontSize={16} color={+totalInfo?.totalNetApr ? '#fff' : 'rgba(255, 255, 255, 0.7)'}>
              <CountUp
                isCounting
                decimalPlaces={2}
                start={0}
                end={(lendApyState ? totalInfo?.totalNetAprWithReward : totalInfo?.totalNetApr) * 100}
                duration={1}
              />%
            </TYPE.white>
          </RowBetween>
        </APYInfoCon>
        <APYHeiLine />
        <APYInfoCon>
          <TYPE.white marginBottom={'6px'} paddingTop={'24px'}>{t('Borrow Limit')}</TYPE.white>
          <Progress>
            <ProgressBorrowLine width={parseInt(String(totalInfo.maxBorrow ? totalInfo.totalBorrowBalance/totalInfo.maxBorrow*100 : 0), 10)}></ProgressBorrowLine>
          </Progress>
          <RowBetween marginBottom={'14px'}>
            <TYPE.white color={+totalInfo?.totalBorrowBalance ? '#fff' : 'rgba(255, 255, 255, 0.7)'}>${commafy2Lend(totalInfo.totalBorrowBalance)}</TYPE.white>
            <TYPE.white color={+totalInfo?.maxBorrow && +totalInfo?.totalBorrowBalance ? '#fff' : 'rgba(255, 255, 255, 0.7)'}>
              <CountUp
                isCounting
                decimalPlaces={2}
                start={0}
                end={totalInfo.maxBorrow ? totalInfo.totalBorrowBalance/totalInfo.maxBorrow*100 : 0}
                duration={1}
              />%
            </TYPE.white>
          </RowBetween>
        </APYInfoCon>
      </APYInfoLine>
    </InfoCon>
  )
};

export default InfoContent;