import React, { useEffect, useMemo } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled, { css } from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import { ETHER, JSBI, TokenAmount } from '@huckleberry/sdk'
import { ButtonPrimary } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { useTranslation } from 'react-i18next'
import { FINN } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
// import Fire from '../../assets/svg/fire.svg'
import { isMobile } from 'react-device-detect'
import { commafy } from '../../utils/commafy'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  grid-gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  /*display: none;*/
`};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  /* box-shadow:rgba(0, 0, 0, 0.18) 10px 8px 15px 5px, rgba(255, 255, 255, 0.565) -8px -10px 15px 5px; */
  border-radius:24px;
  width: 100%;
  min-height: 156px;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme }) => theme.cardBg };
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

// const APR = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
  ${
    ({ theme }) => theme.mediaWidth.upToSmall`
      ${
        isMobile && css`
          width: 100%;
          flex-wrap: wrap;
        `
      }
    `
  }
`

declare global {
  interface Window {
    tvlItems: any;
  }
}

export const POOLNAME = [
  'La Plata River',     // 20
  'Yangtze River',      // 19
  'Amazon River',       // 18
  'Nile River',         // 17
  'Mississippi River',  // 3
  'The Everglades',     // 16
  'Ripple River',       // 9
  'Wood River',         // 7
  'Missouri River',     // 4
  'Onyx River',         // 8
  'Congo River',        // 5
  'Belém River',        // 13
  'Blue Nile',          // 6
  'Rio Chili',          // 12
  'Mantaro River',      // 14
  'Wu River',           // 15
  'Budhi Ganga Rivr',   // 11
  'Shinano River',      // 10
  'Phantom River',      // 2
  'Hijikawa River',     // 1
  'The Grand Canal',    // 0
  'Meguro River',       // -1
  'The Danube',
  'Light River',
  'Madeira River',
  'Mystic River',
  'Yodo River',
  'The Rhine',
  'Mana River',
  'Hudson River',
  'Walla Walla River',
  'Araguaia River',
  'Still River',
  'Donets River',
  'The Ganges',
]

export const sequence = [117,118,119,5,1,3,6,112,2,121,122,106,101,114,102,103,111,105,123,109,124,107,115,104,108,113,125,126,116,127,128,110,4,129,130];
const dynamic = [3,4,5,8,33];

export default function PoolCard({ stakingInfo, index, hide, totalDeposit }: { stakingInfo: StakingInfo, index: number, hide?: Boolean, totalDeposit: object }) {
  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]

  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)
  const { t } = useTranslation()

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // get the color of the token
  const token = currency0 === ETHER ? token1 : token0
  const WETH = currency0 === ETHER ? token0 : token1
  const backgroundColor = useColor(token)

  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.token)
  const [, stakingTokenPair] = usePair(...stakingInfo.tokens)
  const baseAllocPoint = 100;
  const multiplier = stakingInfo.allocPoint && Number(stakingInfo.allocPoint?.toString()) / baseAllocPoint;

  // let returnOverMonth: Percent = new Percent('0')
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  //@ts-ignore
  // let valueOfTotalStakedAmountInWLSP: TokenAmount | undefined
  let valueOfSelfStakedAmountInWETH: TokenAmount | undefined
  let valueOfSelfStakedAmountInWLSP: TokenAmount | undefined

  if (totalSupplyOfStakingToken && stakingTokenPair && Number(totalSupplyOfStakingToken.toExact()) > 0) {
    // take the total amount of LP tokens staked, multiply by MOVR value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
    // valueOfTotalStakedAmountInWLSP = new TokenAmount(
    //   WETH,
    //   JSBI.multiply(stakingInfo.totalStakedAmount.raw, JSBI.BigInt(1))
    // )
  }

  if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH && Number(totalSupplyOfStakingToken.toExact()) > 0) {
    // take the total amount of LP tokens staked, multiply by MOVR value of all LP tokens, divide by all LP tokens
    valueOfSelfStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.stakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
    valueOfSelfStakedAmountInWLSP = new TokenAmount(
      WETH,
      JSBI.multiply(stakingInfo.stakedAmount.raw, JSBI.BigInt(1))
    )
  }

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)

  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)
  const valueOfSelfStakedAmountInUSDC =
    valueOfSelfStakedAmountInWETH && USDPrice?.quote(valueOfSelfStakedAmountInWETH)

  const { chainId } = useActiveWeb3React()
  const uni = chainId ? FINN[chainId] : undefined
  const uniPrice = useUSDCPrice(uni)

  const weekReward = stakingInfo.totalRewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(0)
  const apy = valueOfTotalStakedAmountInUSDC && weekReward && uniPrice ? (Number(weekReward) * Number(uniPrice?.toFixed(8)) / Number(valueOfTotalStakedAmountInUSDC.toFixed(0)) / 7 * 365 * 100).toFixed(0) : '--' 
  // console.debug('apy', apy.toString(), 
  //   'valueOfTotalStakedAmountInUSDC', valueOfTotalStakedAmountInUSDC && valueOfTotalStakedAmountInUSDC.toFixed(0),
  //   'valueOfTotalStakedAmountInWETH', valueOfTotalStakedAmountInWETH && valueOfTotalStakedAmountInWETH.toFixed(0),
  //   'USDPrice', USDPrice&&USDPrice.toFixed(8),
  //   'stakingTokenPair', stakingTokenPair && stakingTokenPair,
  //   'reserveOf', stakingTokenPair && stakingTokenPair.reserveOf(WETH).toFixed(8),
  //   'WETH', WETH.address,
  //   'totalStakedAmount', stakingInfo && stakingInfo.totalStakedAmount.toFixed(8),
  //   'totalSupplyOfStakingToken', totalSupplyOfStakingToken && totalSupplyOfStakingToken.toFixed(8),
  //   weekReward.toString(), uniPrice?.toFixed(8));

  if (valueOfTotalStakedAmountInUSDC && stakingTokenPair) {
    if (!window.tvlItems) {
      window.tvlItems = {}
    }
    window.tvlItems[stakingTokenPair!.liquidityToken.address] = valueOfTotalStakedAmountInUSDC.toFixed(0)
  }

  useEffect(() => {
    //@ts-ignore
    totalDeposit[index] = valueOfSelfStakedAmountInUSDC ? valueOfSelfStakedAmountInUSDC.toFixed(0) : '0'
  }, [index, totalDeposit, valueOfSelfStakedAmountInUSDC])

  const currency0InSort = useMemo(() => {
    let pairHasFINN = [currency0.symbol, currency1.symbol].includes('FINN');
    let pairHasMOVR = [currency0.symbol, currency1.symbol].includes('MOVR');

    if (!pairHasFINN) {
      return !pairHasMOVR ? currency0 : currency0.symbol === 'MOVR' ? currency1 : currency0
    } else {
      if (!pairHasMOVR) {
        return currency0.symbol === 'FINN' ? currency0 : currency1
      } else {
        return currency0.symbol === 'FINN' ? currency0 : currency1
      }
    }
  }, [currency0, currency1])

  const currency1InSort = useMemo(() => {
    let pairHasFINN = [currency0.symbol, currency1.symbol].includes('FINN');
    let pairHasMOVR = [currency0.symbol, currency1.symbol].includes('MOVR');

    if (!pairHasFINN) {
      return !pairHasMOVR ? currency1 : currency0.symbol === 'MOVR' ? currency0 : currency1
    } else {
      if (!pairHasMOVR) {
        return currency0.symbol === 'FINN' ? currency1 : currency0
      } else {
        return currency0.symbol === 'FINN' ? currency1 : currency0
      }
    }
  }, [currency0, currency1])

  const isActive = Boolean(stakingInfo.totalRewardRate.greaterThan('0'));

  // if (currency0?.symbol === 'USDC' || currency1?.symbol === 'USDC') {
  //   console.log('!valueOfTotalStakedAmountInUSDC', valueOfTotalStakedAmountInUSDC?.toExact(), valueOfTotalStakedAmountInWETH?.toExact(), USDPrice?.toFixed(4), currency0?.symbol, currency1?.symbol, WETH?.symbol);
  // }

  return (
    <React.Fragment>
    {
      !hide &&
      <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
      <Bg />
      <TopSection>
        <DoubleCurrencyLogo currency0={currency0InSort} currency1={currency1InSort} size={36} />
        <TYPE.white fontFamily={"Pacifico-Regular"} fontWeight={400} fontSize={24} style={{ marginLeft: '30px' }}>
          { index < POOLNAME.length ? POOLNAME[index] : `${currency0InSort.symbol} / ${currency1InSort.symbol}` }
          {
              !isActive && <Multiplier1>{t("Inactive")}</Multiplier1>
          }
          {
            dynamic.includes(stakingInfo.pid) &&
            <Multiplier>
              Dynamic
            </Multiplier>
          }
          {
            Number(multiplier) >= 1 ?
            <Multiplier>
              {
                multiplier+'x'
              }
            </Multiplier> : Number(multiplier) === 0 ? null :<Multiplier>
              {'<1x'}
            </Multiplier>
          }
        </TYPE.white>

        <StyledInternalLink to={`/farm/${currencyId(currency0)}/${currencyId(currency1)}`} style={{ width: '100%',color:'transparent' }}>
          <ButtonPrimaryCus padding="8px" borderRadius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonPrimaryCus>
        </StyledInternalLink>
      </TopSection>

      <StatContainer>
        <RowBetween>
          <TYPE.white>{t('totalDeposited')}</TYPE.white>
          <TYPE.yellow_o>
              {
                // valueOfTotalStakedAmountInUSDC ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}` : ''
                valueOfTotalStakedAmountInUSDC ? commafy(valueOfTotalStakedAmountInUSDC.toExact()) : ''
              }
              {
                apy && apy !== '--' && apy !== '0' ? ' 🔥 ' : null
              }
              {
                apy && apy !== '--' && apy !== '0' ? 'APR: ' + apy + '%' : null
              }
          </TYPE.yellow_o>
        </RowBetween>
        <RowBetween>
          <TYPE.white> Discharge </TYPE.white>
          <TYPE.color_o>{`${stakingInfo.totalRewardRate
            ?.multiply(`${60 * 60 * 24 * 7}`)
            ?.toFixed(0, { groupSeparator: ',' })} FINN / week`}
          </TYPE.color_o>
        </RowBetween>
      </StatContainer>
      {isStaking && (
        <>
          <BreakCus />
          <BottomSection showBackground={true}>
            <TYPE.black fontWeight={500} color={"#909699"} marginBottom={isMobile ? '4px' : ''}>
              <span style={{ fontSize: "14px" }}>My Deposit ≈ </span>
              <span style={{ fontWeight: "bold", fontSize: "18px", color: "#42B5D9" }}>
              {valueOfSelfStakedAmountInUSDC
                ? `$${valueOfSelfStakedAmountInUSDC.toSignificant(6, { groupSeparator: ',' })}`
                : `${valueOfSelfStakedAmountInWLSP?.toSignificant(6, { groupSeparator: ',' }) ?? '-'} HBLP`}
              </span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={"#909699"}>
              {/* <span  id="animate-zoom" role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
              💗
              </span> */}
              <span style={{ fontSize: "14px" }}>My Rate: </span>
              <span style={{ fontWeight: "bold", fontSize: "18px", color: "#42B5D9" }}>
              {`${stakingInfo.rewardRate
                ?.multiply(`${60 * 60 * 24 * 7}`)
                ?.toFixed(0, { groupSeparator: ',' })}`}
              </span>
              <span style={{ fontSize: "14px" }}> FINN / week</span>
            </TYPE.black>
          </BottomSection>
        </>
      )}
    </Wrapper>
    }
</React.Fragment>
  )
}

const ButtonPrimaryCus = styled(ButtonPrimary)`
  background: #42B5D9;
  border-radius: 8px;
`

const Multiplier = styled.span`
    font-weight: 400;
    text-align: center;
    border-radius: 8px;
    min-width: 45px;
    display: inline-block;
    height: 24px;
    line-height: 13px;
    margin-left: 15px;
    padding: 5px;
    font-size: 16px;
    color: #2172E5;
    font-family: Inter-Bold, Inter;
    border: 1px solid #42B5D9;;
    color: #42B5D9;
    background: rgba(216, 216, 216, 0);
    ${({ theme }) => theme.mediaWidth.upToSmall`
      position:absolute;
      bottom:-23px;
      left:5px;
  `};
`

export const BreakCus = styled.div`
  width: calc(100% - 30px);
  margin:0 auto;
  border: 0;
  border-bottom: 1px solid #384347;
  height: 1px;
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`

const Multiplier1 = styled.span`
    font-weight: 400;
    text-align: center;
    border-radius: 8px;
    min-width: 45px;
    display: inline-block;
    height: 24px;
    line-height: 13px;
    margin-left: 15px;
    padding: 5px;
    font-size: 16px;
    color: #2172E5;
    font-family: Inter-Bold, Inter;
    border: 1px solid #30B081;;
    color: #30B081;
    background: rgba(216, 216, 216, 0);
    ${({ theme }) => theme.mediaWidth.upToSmall`
      position:absolute;
      bottom:-23px;
      left:5px;
  `};
`
