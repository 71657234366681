import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { TYPE } from '../../theme';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLend, { MarketItem } from '../../state/lend/hooks';
import { commafy2, commafy2Lend } from '../../utils/commafy';
import { useCurrency } from '../../hooks/Tokens';
import CurrencyLogo from '../CurrencyLogo';
import { BigNumber } from 'bignumber.js';
import LocalLoader from '../LocalLoader';
import { isMobile } from 'react-device-detect';
import { RowBetween } from '../Row';
import { ChevronDown } from 'react-feather';
import RateView from './RateView';

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`
const ListCon = styled.div`
  width: 100%;
  border-radius: 16px;
  position: relative;
`;
const ListLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  backdrop-filter: blur(20px);
  background: ${({theme}) => theme.lendBg};
`;
const TitleLine = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  padding: 16px 20px;
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-bottom-color: ${ ({ theme }) => theme.text10 };
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: ${({theme}) => theme.lendBg};
  backdrop-filter: blur(20px);
`;
const HeaderLine = styled.div`
  padding: 16px 20px;
  display: flex;
  background: ${({theme}) => theme.lendBg};
  backdrop-filter: blur(20px);
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-bottom-color: ${ ({ theme }) => theme.text10 };
  border-top: none;
`;
const HeaderItem = styled.div<{textAlign?:string}>`
  font-size: 14px;
  font-weight: 400;
  color: ${ ({ theme }) => theme.text11 };
  flex: 1;
  text-align: ${ ({textAlign}) => textAlign ? textAlign : 'right'};
  font-family: Inter-Regular, Inter;
`;
const ContentLine = styled(NavLink)<{
  dark?: string
  last?: string
}>`
  display: flex;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  background: ${ ({dark, theme}) => dark ? theme.lendListBg2 : theme.lendBg };
  padding: 0 20px;
  backdrop-filter: blur(20px);
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
  border-bottom: none;
  ${({last}) => last && css `
    
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  `}
`;
const ContentMobileLine = styled.div<{
  dark?: string
  last?: string
}>`
  display: flex;
  background: ${ ({dark}) => dark ? 'rgba(9, 60, 58, 0.95)' : 'rgba(9, 60, 58, 0.8)' };
  padding: 0 20px;
  backdrop-filter: blur(20px);
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
  border-bottom: none;
  ${({last}) => last && css `
    
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  `}
`;
const ContentItem = styled.div<{
  direction?: string
  flexDirection?: string
}>`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  flex: 1;
  min-height: 84px;
  align-items: center;
  justify-content: flex-end;
  ${
    ({flexDirection}) => flexDirection && css`
      flex-direction: ${flexDirection};
      align-items: flex-end;
      justify-content: center;
    `
  }
  ${
    ({direction}) => direction && css`
      justify-content: flex-start;
    `
  }
`;
const ReservoirMobileContent = styled.div`
  width: 100%;
  padding-bottom: 0.5rem;
`;
const ContentMobileItem = styled(RowBetween)`
  width: 100%;
  padding: 0.5rem 0;
  align-items: center;
`;
const LogoInfo = styled.div`
  display: flex;
  align-items: center;
`;
const ContentMobileRightColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const ContentMobileRightRowItem = styled.div`
  display: flex;
`;
const ViewMoreNav = styled(NavLink)`
  outline: none;
  text-decoration: none;
`
const ViewMoreBtn = styled.button`
  width: 100%;
  border-radius: 8px;
  background: ${({theme}) => theme.primary7};
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  border: none;
  margin: 1rem 0 0.5rem;
`;


const rate2commafyFn = (num: number | string) => {
  if (Number(num) !== +num) return '--';
  const rate = new BigNumber(num).times(100).toString();
  return commafy2(rate);
}
const Logo = ({
  address,
  isMovr
}:{
  address: string | undefined
  isMovr: boolean
}) => {
  const oldCurrency = useCurrency(isMovr ? 'MOVR' : address);
  const currency = oldCurrency ? oldCurrency : undefined;
  
  return <CurrencyLogo style={{marginRight: '8px'}} size='36px' currency={currency}></CurrencyLogo>
}

const ReservoirMobileItem = ({
  item,
  lendApyState
}:{
  item: MarketItem,
  lendApyState: boolean
}) => {
  const { t } = useTranslation();
  const [showViewMore, setShowViewMore] = useState(false);
  const depositBanlance = useMemo(() => {
    const supply = new BigNumber(item.total_supply);
    const depositNum = supply.times(item.underlying_price).times(item.exchange_rate);
    const str = `$${commafy2Lend(depositNum.toString())}`;
    return str;
  }, [item]);
  const depositUnderlyingBanlance = useMemo(() => {
    const supply = new BigNumber(item.total_supply).times(item.exchange_rate);
    return commafy2Lend(supply.toString());
  }, [item]);
  const borrowBanlance = useMemo(() => {
    const borrow = new BigNumber(item.total_borrows).times(item.underlying_price);
    return `${commafy2Lend(borrow.toString())}`;
  }, [item]);
  const liquidity = useMemo(() => {
    const liqudityNum = new BigNumber(item.cash).times(item.underlying_price);
    return `$${commafy2Lend(liqudityNum.toString())}`;
  }, [item]);
  return (
    <ReservoirMobileContent>
      <ContentMobileItem onClick={() => setShowViewMore(!showViewMore)} style={{marginTop: '0.5rem'}}>
        <LogoInfo>
          <Logo address={item?.underlying_address} isMovr={item?.underlying_symbol === 'MOVR'}></Logo>
          <TYPE.white fontSize={14}>{item.underlying_symbol}</TYPE.white>
        </LogoInfo>
        <ChevronDown size={18} color='#fff' style={{transform: `rotate(${showViewMore ? '180deg' : '0deg'})`}} />
      </ContentMobileItem>
      {
        showViewMore && (
          <div>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Total Deposit')}</TYPE.white_o5>
              <ContentMobileRightColumnItem>
                <TYPE.white marginBottom={'2px'} fontSize={14} fontWeight={400}>{depositBanlance}</TYPE.white>
                <TYPE.white_o5 fontSize={12} fontWeight={400}>{depositUnderlyingBanlance}&nbsp;{item.underlying_symbol}</TYPE.white_o5>
              </ContentMobileRightColumnItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Deposit APR')}</TYPE.white_o5>
              <ContentMobileRightRowItem>
                <RateView rate={lendApyState ? item.supply_rate_with_reward : item.supply_rate} />
              </ContentMobileRightRowItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Total Borrow')}</TYPE.white_o5>
              <ContentMobileRightColumnItem>
                <TYPE.white marginBottom={'2px'} fontSize={14} fontWeight={400}>${borrowBanlance}</TYPE.white>
                <TYPE.white_o5 fontSize={12} fontWeight={400}>{commafy2Lend(item.total_borrows)}&nbsp;{item.underlying_symbol}</TYPE.white_o5>
              </ContentMobileRightColumnItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Borrow APR')}</TYPE.white_o5>
              <ContentMobileRightRowItem>
                <RateView rate={lendApyState ? item.borrow_rate_with_reward : item.borrow_rate} />
              </ContentMobileRightRowItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Liquidity')}</TYPE.white_o5>
              <ContentMobileRightColumnItem>
                <TYPE.white marginBottom={'2px'} fontSize={14} fontWeight={400}>{liquidity}</TYPE.white>
                <TYPE.white_o5 fontSize={12} fontWeight={400}>{commafy2Lend(item.cash)}&nbsp;{item.underlying_symbol}</TYPE.white_o5>
              </ContentMobileRightColumnItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Utilisation Ratio')}</TYPE.white_o5>
              <TYPE.white fontSize={14}>{rate2commafyFn(item.utilization)}%</TYPE.white>
            </ContentMobileItem>
            <ViewMoreNav
              id={`lend-market-info-nav-link`}
              to={`/lend/reservoir/info/infoId${item.token_address}`}
            >
              <ViewMoreBtn>{t('View More')}</ViewMoreBtn>
            </ViewMoreNav>
          </div>
        )
      }
    </ReservoirMobileContent>
  )
}


const ReservoirList = ({
  updateFn,
  lendApyState
}: {
  updateFn: (a: string, b: string) => void,
  lendApyState: boolean
}) => {
  const { marketList } = useLend();
  // console.log('marketList', marketList)
  const { t } = useTranslation();
  const totalSupply: BigNumber = useMemo((): BigNumber => {
    if (!marketList) return new BigNumber(0);
    return marketList.reduce((a: BigNumber, b: MarketItem) => {
      const price = new BigNumber(b.underlying_price);
      const supply = new BigNumber(b.total_supply);
      const rate = new BigNumber(b.exchange_rate);
      const num = price.times(supply).times(rate);
      return a.plus(num);
    }, new BigNumber(0));
  }, [marketList]);
  const totalBorrow: BigNumber = useMemo((): BigNumber => {
    if (!marketList) return new BigNumber(0);
    return marketList.reduce((a: BigNumber, b: MarketItem) => {
      const price = new BigNumber(b.underlying_price);
      const num = price.times(+b.total_borrows);
      return a.plus(num);
    }, new BigNumber(0));
  }, [marketList]);
  useEffect(() => {
    updateFn(totalSupply.toString(), totalBorrow.toString());
  }, [totalBorrow, totalSupply, updateFn]);
  // console.log('marketList', marketList)


  return (
    <ListCon>
      <Bg />
      <TitleLine>{t('Thatcher’s Reservoir')}</TitleLine>
      {
        isMobile ?
          null
        :
          (
            <HeaderLine>
              <HeaderItem textAlign={'left'}>{t('Asset')}</HeaderItem>
              <HeaderItem>{t('Total Deposit')}</HeaderItem>
              <HeaderItem>{t('Deposit APR')}</HeaderItem>
              <HeaderItem>{t('Total Borrow')}</HeaderItem>
              <HeaderItem>{t('Borrow APR')}</HeaderItem>
              <HeaderItem>{t('Liquidity')}</HeaderItem>
              <HeaderItem>{t('Utilisation Ratio')}</HeaderItem>
            </HeaderLine>
          )
      }
      {
        marketList.length ? 
          marketList.map((item: MarketItem, index) => (
            isMobile ?
              (
                <ContentMobileLine
                  key={index}
                  dark={index % 2 ? '' : 'true'}
                  last={index === marketList.length - 1 ? 'true' : ''}
                >
                  <ReservoirMobileItem
                    item={item}
                    lendApyState={lendApyState}
                  ></ReservoirMobileItem>
                </ContentMobileLine>
              )
            :
              (
                <ContentLine
                  key={index}
                  id={`lend-market-info-nav-link`}
                  to={`/lend/reservoir/info/infoId${item.token_address}`}
                  dark={index % 2 ? '' : 'true'}
                  last={index === marketList.length - 1 ? 'true' : ''}
                >
                  <ContentItem direction={'left'}>
                    <Logo address={item?.underlying_address} isMovr={item?.underlying_symbol === 'MOVR'}></Logo>
                    <TYPE.white fontSize={14}>{item.underlying_symbol}</TYPE.white>
                  </ContentItem>
                  <ContentItem flexDirection={'column'}>
                    <TYPE.white fontSize={14} marginBottom={'2px'}>${commafy2Lend(Number(item.total_supply) * Number(item.underlying_price) * Number(item.exchange_rate))}</TYPE.white>
                    <TYPE.white_o1 fontSize={12}>{commafy2Lend(Number(item.total_supply) * Number(item.exchange_rate))}&nbsp;{item.underlying_symbol}</TYPE.white_o1>
                  </ContentItem>
                  <ContentItem>
                    <RateView rate={lendApyState ? item.supply_rate_with_reward : item.supply_rate} />
                  </ContentItem>
                  <ContentItem flexDirection={'column'}>
                    <TYPE.white fontSize={14} marginBottom={'2px'}>${commafy2Lend(Number(item.total_borrows) * Number(item.underlying_price))}</TYPE.white>
                    <TYPE.white_o1 fontSize={12}>{commafy2Lend(Number(item.total_borrows))}&nbsp;{item.underlying_symbol}</TYPE.white_o1>
                  </ContentItem>
                  <ContentItem>
                    <RateView rate={lendApyState ? item.borrow_rate_with_reward : item.borrow_rate} />
                  </ContentItem>
                  <ContentItem flexDirection={'column'}>
                    <TYPE.white fontSize={14} marginBottom={'2px'}>${commafy2Lend(Number(item.cash) * Number(item.underlying_price))}</TYPE.white>
                    <TYPE.white_o1 fontSize={12}>{commafy2Lend(item.cash)}&nbsp;{item.underlying_symbol}</TYPE.white_o1>
                  </ContentItem>
                  <ContentItem>
                    <TYPE.white fontSize={14}>{rate2commafyFn(item.utilization)}%</TYPE.white>
                  </ContentItem>
                </ContentLine>
              )  
          ))
        :
          (
            <ListLoader>
              <LocalLoader fill={undefined} />
            </ListLoader>
          )
      }
    </ListCon>
  )
}

export default ReservoirList;