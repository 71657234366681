import React, { useMemo } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import styled from 'styled-components';
import CurrencyLogo from '../CurrencyLogo';
import { useCurrency } from '../../hooks/Tokens';

const PieCon = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.text8 };
  position: relative;
`;
const IconCon = styled.div`
  width: 104px;
  height: 104px;
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.text8 };
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = ({
  address,
  isMovr
}:{
  address: string | undefined
  isMovr: boolean
}) => {
  const oldCurrency = useCurrency(isMovr ? 'MOVR' : address);
  const currency = oldCurrency ? oldCurrency : undefined;
  return <CurrencyLogo size='60px' currency={currency}></CurrencyLogo>
}
const ProgressCircel = ({
  liquid,
  borrow,
  name,
  address
}:{
  liquid: string
  borrow: string
  name: string
  address?: string
}) => {
  const data = useMemo(() => {
    return [
      { name: "liquid", value: Number(liquid) },
      { name: "borrow", value: Number(borrow) }
    ];
  }, [liquid, borrow]);
  const color = [
    '#4DD4FE',
    '#FFC70B'
  ]
  return (
    <PieCon>
      <PieChart width={150} height={150}>
        {/* <defs>
          <linearGradient id="ccc">
            <stop offset="0%" stopColor="#4DD4FE" />
            <stop offset="100%" stopColor="#FFC70B" />
          </linearGradient>
        </defs> */}
        <Pie
          data={data}
          cx={'50%'}
          cy={'50%'}
          startAngle={90}
          endAngle={450}
          innerRadius={61}
          outerRadius={70}
          fill={'url(#ccc)'}
          stroke={'none'}
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={index} fill={color[index % color.length]} />)
          }
        </Pie>
      </PieChart>
      <IconCon>
        <Logo address={address} isMovr={name === 'MOVR'}></Logo>
      </IconCon>
    </PieCon>
  );
};

export default ProgressCircel;