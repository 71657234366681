import React from 'react';
import InfoContent from '../../components/Lend/InfoContent';
import BorrowList from '../../components/Lend/BorrowList';
import { LendApyContext } from '../../components/Lend/LendApyBtn';


const Borrow = () => {
  return (
    <>
      <LendApyContext.Consumer>
          {
            state => (
              <>
                <InfoContent lendApyState={state}></InfoContent>
                <BorrowList lendApyState={state}></BorrowList>
              </>
            )
          }
      </LendApyContext.Consumer>
    </>
  );
}


export default Borrow;