import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { TYPE } from '../../theme';
import { useTranslation } from 'react-i18next';
import borrowIcon from '../../assets/png/Lend/borrow.svg';
import BorrowModal from './BorrowModal';
import useLend, { MarketItem, LendAccountTokensItem } from '../../state/lend/hooks';
import { commafy2Lend } from '../../utils/commafy';
import { useCurrencyBalance } from '../../state/wallet/hooks';
import { useActiveWeb3React } from '../../hooks';
import { Token } from '@huckleberry/sdk';
import CurrencyLogo from '../CurrencyLogo';
import { useCurrency } from '../../hooks/Tokens';
import LocalLoader from '../LocalLoader';
import { isMobile } from 'react-device-detect';
import { RowBetween } from '../Row';
import { ChevronDown } from 'react-feather';
import { ButtonBlueCircle } from '../Button';
import RateView from './RateView';

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`
const ListCon = styled.div`
  width: 100%;
  border-radius: 16px;
  position: relative;
`;
const ListLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  backdrop-filter: blur(20px);
  background: ${({theme}) => theme.lendBg};
`;
const TitleLine = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 400;
  color: ${({theme}) => theme.text9};
  padding: 16px 20px;
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-bottom-color: ${ ({ theme }) => theme.text10 };
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: ${({theme}) => theme.lendBg};
  backdrop-filter: blur(20px);
`;
const Icon = styled.div`
  width: 18px;
  height: 20px;
  background-image: url(${borrowIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 6px;
`;
const HeaderLine = styled.div`
  padding: 16px 20px;
  background: ${({theme}) => theme.lendBg};
  display: flex;
  backdrop-filter: blur(20px);
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-bottom-color: ${ ({ theme }) => theme.text10 };
  border-top: none;
`;
const HeaderItem = styled.div<{textAlign?:string}>`
  font-size: 14px;
  font-weight: 400;
  color: ${ ({ theme }) => theme.text11 };
  flex: 1;
  text-align: ${ ({textAlign}) => textAlign ? textAlign : 'right'};
`;
const ContentLine = styled.div<{
  dark?: string
  last?: string
}>`
  display: flex;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  background: ${ ({dark, theme}) => dark ? theme.lendListBg2 : theme.lendBg };
  padding: 0 20px;
  backdrop-filter: blur(20px);
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
  border-bottom: none;
  
  ${({last}) => last && css `
    border: 1px solid ${ ({ theme }) => theme.text8 };
    border-top: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  `}
`;
const ContentItem = styled.div<{
  direction?: string
  flexDirection?: string
}>`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  flex: 1;
  min-height: 84px;
  align-items: center;
  justify-content: flex-end;
  ${
    ({flexDirection}) => flexDirection && css`
      flex-direction: ${flexDirection};
      align-items: flex-end;
      justify-content: center;
    `
  }
  ${
    ({direction}) => direction && css`
      justify-content: flex-start;
    `
  }
`;
const BorrowMobileContent = styled.div`
  width: 100%;
  padding-bottom: 0.5rem;
`;
const ContentMobileItem = styled(RowBetween)`
  width: 100%;
  padding: 0.5rem 0;
  align-items: center;
`;
const LogoInfo = styled.div`
  display: flex;
  align-items: center;
`;
const ContentMobileRightRowItem = styled.div`
  display: flex;
  align-items: center;
`;
const ContentMobileRightColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const PayBtn = styled(ButtonBlueCircle)`
  width: 49%;
  height: 1.875rem;
  font-size: 0.75rem;
  border-radius: 8px;
  background: ${({theme}) => theme.text9 };
  color: #172329;

  &:hover {
    background: ${({theme}) => theme.text9 };
  }
`;

interface BorrowMarketsItem extends MarketItem {
  is_entered: boolean
}

const BalanceCommafy = ({
  account,
  token,
  isMovr
}:{
  account: string
  token: Token
  isMovr: boolean
}) => {
  const oldCurrency = useCurrency(isMovr ? 'MOVR' : token.address)
  const currency = oldCurrency ? oldCurrency : undefined;
  const balance = useCurrencyBalance(account, currency);
  return <TYPE.white fontSize={14}>{commafy2Lend(balance?.toExact())}</TYPE.white>
}
const Logo = ({
  address,
  isMovr
}:{
  address: string | undefined
  isMovr: boolean
}) => {
  const oldCurrency = useCurrency(isMovr ? 'MOVR' : address);
  const currency = oldCurrency ? oldCurrency : undefined;
  return <CurrencyLogo style={{marginRight: '8px'}} size='36px' currency={currency}></CurrencyLogo>
}
const BorrowMobileItem = ({
  item,
  setCurIndex,
  setShowBorrowModal,
  setBorrowType,
  lendApyState
}:{
  item: BorrowMarketsItem
  setCurIndex: () => void
  setShowBorrowModal: () => void
  setBorrowType: (index: number) => void
  lendApyState: boolean
}) => {
  const { t } = useTranslation();
  const { account, chainId } = useActiveWeb3React();
  const [showViewMore, setShowViewMore] = useState(false);
  return (
    <BorrowMobileContent>
      <ContentMobileItem onClick={() => setShowViewMore(!showViewMore)} style={{marginTop: '0.5rem'}}>
        <LogoInfo>
          <Logo address={item?.underlying_address} isMovr={item?.underlying_symbol === 'MOVR'}></Logo>
          <TYPE.white fontSize={14}>{item.underlying_symbol}</TYPE.white>
        </LogoInfo>
        <ChevronDown size={18} color='#fff' style={{transform: `rotate(${showViewMore ? '180deg' : '0deg'})`}} />
      </ContentMobileItem>
      {
        showViewMore && (
          <div>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Borrow Rate')}</TYPE.white_o5>
              <ContentMobileRightRowItem>
                <RateView rate={lendApyState ? item.borrow_rate_with_reward : item.borrow_rate} />
              </ContentMobileRightRowItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Wallet Balance')}</TYPE.white_o5>
              <ContentMobileRightRowItem>
                {
                  account && chainId ?
                    <BalanceCommafy account={account} isMovr={item.underlying_symbol === 'MOVR'} token={new Token(chainId, item.underlying_address, item.decimals)}></BalanceCommafy>
                  :
                    <TYPE.white fontSize={14}>--</TYPE.white>
                }
                <TYPE.white_o5 fontSize={12}>&nbsp;&nbsp;{item?.underlying_symbol}</TYPE.white_o5>
              </ContentMobileRightRowItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Liquidity')}</TYPE.white_o5>
              <ContentMobileRightColumnItem>
                <TYPE.white fontSize={14}>${commafy2Lend(Number(item.cash) * Number(item.underlying_price))}</TYPE.white>
                <TYPE.white_o1 fontSize={12}>{commafy2Lend(Number(item.cash))}&nbsp;{item.underlying_symbol}</TYPE.white_o1>
              </ContentMobileRightColumnItem>
            </ContentMobileItem>
            <RowBetween style={{marginBottom: '1.25rem', marginTop: '1.5rem'}}>
              <PayBtn disabled={!account} marginRight={'10px'} onClick={() => {
                setBorrowType(0);
                setCurIndex();
                setShowBorrowModal();
              }}>
                <TYPE.white marginTop={'-1px'} color={'#172329'} fontWeight={400} fontSize={14}>{t('Borrow')}</TYPE.white>
              </PayBtn>
              <PayBtn disabled={!account} onClick={() => {
                setBorrowType(1);
                setCurIndex();
                setShowBorrowModal();
              }}>
                <TYPE.white marginTop={'-1px'} color={'#172329'} fontWeight={400} fontSize={14}>{t('Repay')}</TYPE.white>
                </PayBtn>
            </RowBetween>
          </div>
        )
      }
    </BorrowMobileContent>
  )
}

const BorrowList = ({
  lendApyState
}:{
  lendApyState: boolean
}) => {
  const { t } = useTranslation();
  const { marketList, personalAccount, markets } = useLend();
  const [showBorrowModal, setShowBorrowModal] = useState(false);
  const [curIndex, setCurIndex] = useState(0);
  const [borrowType, setBorrowType] = useState(0);
  const { account, chainId } = useActiveWeb3React();
  const marketInfo = useMemo(() => {
    let info = {};
    personalAccount?.tokens.map(item => {
      Object.assign(info, {
        [item.token_address]: item
      })
    })
    return info;
  }, [personalAccount]);
  const BorrowMarketsList = useMemo(() => {
    return marketList.map(item => {
      const findresult = Object.keys(marketInfo).includes(item.token_address);
      if (!findresult) return Object.assign({is_entered: false}, item);
      const info: LendAccountTokensItem = marketInfo[item.token_address as keyof typeof marketInfo];
      return Object.assign({is_entered: findresult ? info.is_entered : false}, item);
    })
  }, [marketInfo, marketList]);


  
  return (
    <>
      <ListCon>
        <Bg />
        <TitleLine><Icon />{t('Borrow Markets')}</TitleLine>
        {
          !isMobile && (
            <HeaderLine>
              <HeaderItem textAlign={'left'}>{t('Asset')}</HeaderItem>
              <HeaderItem textAlign={'right'}>{t('Borrow Rate')}</HeaderItem>
              <HeaderItem textAlign={'right'}>{t('Wallet Balance')}</HeaderItem>
              <HeaderItem textAlign={'right'}>{t('Liquidity')}</HeaderItem>
            </HeaderLine>
          )
        }
        {
          BorrowMarketsList && BorrowMarketsList.length ?
            BorrowMarketsList.map((item: BorrowMarketsItem, index) => (
              isMobile ?
                <ContentLine
                  key={index}
                  dark={index % 2 ? '' : 'true'}
                  last={index === BorrowMarketsList.length - 1 ? 'true' : ''}
                >
                  <BorrowMobileItem
                    item={ item }
                    setCurIndex={ () => setCurIndex(index) }
                    setShowBorrowModal={ () => setShowBorrowModal(true) }
                    setBorrowType={ (index: number) => setBorrowType(index)}
                    lendApyState={lendApyState}
                  ></BorrowMobileItem>
                </ContentLine>
              :
                <ContentLine
                  key={index}
                  dark={index % 2 ? '' : 'true'}
                  last={index === BorrowMarketsList.length - 1 ? 'true' : ''}
                  onClick={
                    () => {
                      setCurIndex(index);
                      setShowBorrowModal(true);
                    }
                  }
                >
                  <ContentItem direction={'left'}>
                    <Logo address={item?.underlying_address} isMovr={item?.underlying_symbol === 'MOVR'}></Logo>
                    <TYPE.white fontSize={14}>{item.underlying_symbol}</TYPE.white>
                  </ContentItem>
                  <ContentItem>
                    <RateView rate={lendApyState ? item.borrow_rate_with_reward : item.borrow_rate} />
                  </ContentItem>
                  <ContentItem>
                    {
                      account && chainId ?
                        <BalanceCommafy account={account} isMovr={item.underlying_symbol === 'MOVR'} token={new Token(chainId, item.underlying_address, item.decimals)}></BalanceCommafy>
                      :
                        <TYPE.white fontSize={14}>--</TYPE.white>
                    }
                    <TYPE.white_o1 fontSize={12}>&nbsp;{item?.underlying_symbol}</TYPE.white_o1>
                  </ContentItem>
                  <ContentItem flexDirection='column'>
                    <TYPE.white marginBottom={'2px'}>${commafy2Lend(Number(item.cash) * Number(item.underlying_price))}</TYPE.white>
                    <TYPE.white_o1 fontSize={12}>{commafy2Lend(Number(item.cash))}&nbsp;{item.underlying_symbol}</TYPE.white_o1>
                  </ContentItem>
                </ContentLine>
            ))
          :
            (
              <ListLoader>
                <LocalLoader fill={undefined} />
              </ListLoader>
            )
        }
      </ListCon>
      {
        showBorrowModal ?
          <BorrowModal
            showBorrowModal={showBorrowModal}
            wrappedOnDismiss={() => setShowBorrowModal(false)}
            personalAccount={personalAccount}
            info={BorrowMarketsList[curIndex]}
            markets={markets}
            type={isMobile ? borrowType : 0 }
            lendApyState={lendApyState}
          ></BorrowModal>
        :
          null
      }
    </>
  )
}

export default BorrowList;