import React from 'react';
// import styled from 'styled-components';
import InfoContent from '../../components/Lend/InfoContent';
import DepositList from '../../components/Lend/DepositList';
import { LendApyContext } from '../../components/Lend/LendApyBtn';


const Deposit = () => {
  // const { t } = useTranslation();
  return (
    <>
      <LendApyContext.Consumer>
          {
            state => (
              <>
                <InfoContent lendApyState={state}></InfoContent>
                <DepositList lendApyState={state}></DepositList>
              </>
            )
          }
      </LendApyContext.Consumer>
    </>
  );
}


export default Deposit;