/* eslint-disable react/jsx-pascal-case */
import React, { useContext } from 'react'
import { AutoColumn } from '../../components/Column'
import styled, { css } from 'styled-components'
import { useAllStakingRewardsInfo, useStakingInfo } from '../../state/activity/hooks'
import { TYPE } from '../../theme'
import ActivityCard from '../../components/earnActivity/ActivityCard'
import { RowBetween } from '../../components/Row'
import { CardSection, DataCard } from '../../components/earn/styled'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { ButtonPrimary } from '../../components/Button'
import { useLocalStorageState } from 'ahooks'
import { isMobile } from 'react-device-detect'
import { ThemeContext } from 'styled-components'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

const RowCus = styled(RowBetween)`
  flex-direction: row;
  width: 39%;
  position: relative;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    ${isMobile &&
      css`
      width: 100%;
      margin-bottom: 0.8rem;
    `}
  `}
`
const BtnCon = styled.div`
  display: flex;
  margin-left: 10px;
`

const ButtonPrimaryCus = styled(ButtonPrimary)<{
  isActive: Boolean
}>`
  width: 64px;
  height: 28px;
  line-height: 28px;
  background: ${({ isActive, theme }) => isActive ? theme.riverBtnActiveBg : theme.riverBtnBg };
  font-size: 12px;
  border-radius: 15px;
  padding: 0;
  border: 1px solid ${({ isActive, theme }) => isActive ? theme.riverBtnBorderActive : theme.riverBtnBorder } ;
  color: ${({ isActive, theme }) => isActive ? theme.riverBtnTxtActive : theme.riverBtnTxt };
  &:hover, &:active, &:focus {
    background: ${({ isActive, theme }) => isActive ? theme.riverBtnActiveBg : theme.riverBtnBg };
    color: ${({ isActive, theme }) => isActive ? theme.riverBtnTxtActive : theme.riverBtnTxt };
  }
`

export default function Earn() {
  const theme = useContext(ThemeContext)
  const { chainId } = useActiveWeb3React()
  const stakingInfos = useStakingInfo()
  const stakingRewardsInfo = useAllStakingRewardsInfo()

  const DataRow = styled(RowBetween)`
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
  `

  const [onlyStaked, setOnlyStaked] = useLocalStorageState("excursionOnlyStaked", false);
  const [onlyActive, setOnlyActive] = useLocalStorageState("excursionOnlyActive", true);

  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (stakingRewardsInfo[chainId]?.length ?? 0) > 0)

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <DataCard style={{background: '#3AABC3'}}>
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Stake MOVR to earn rewards!</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  When sailin’ on Huckleberry’s Rivers, you might occasionally feel the need to take a short excursion ashore. Not only do Shore Excursions help you discover all the incredible things Moonriver has to offer, they also give you a chance to reflect.
                 </TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  This holiday season, we are reminded about the importance of givin’. Stake MOVR to earn TOM. High APR with no impermanent loss! Happy holidays to all of Moonriver!
                 </TYPE.white>
              </RowBetween>
            </AutoColumn>
          </CardSection>
        </DataCard>
      </TopSection>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        <DataRow style={{ alignItems: 'baseline' }}>
          <RowCus>
            <TYPE.white color={theme.liquidityTxt} fontSize={"14px"} lineHeight={"20px"} fontWeight={400} style={{ marginTop: '0.5rem', position: "relative", top: "-4px" }}>
              Show Only Staked
            </TYPE.white>
            <BtnCon>
              <ButtonPrimaryCus onClick={() => setOnlyStaked(!onlyStaked)} isActive={onlyStaked}>
              ON
              </ButtonPrimaryCus>
            </BtnCon>
          </RowCus>
          <RowCus style={{justifyContent: isMobile ? '' : 'flex-end'}}>
            <TYPE.white color={theme.liquidityTxt} fontSize={"14px"} lineHeight={"20px"} fontWeight={400} style={{ marginTop: '0.5rem', position: "relative", top: "-4px" }}>
                Show Only Active
            </TYPE.white>
            <BtnCon>
              <ButtonPrimaryCus onClick={() => setOnlyActive(!onlyActive)} isActive={onlyActive}>
              ON
              </ButtonPrimaryCus>
            </BtnCon>
          </RowCus>
        </DataRow>

        <PoolSection>
          {stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            ''
          ) : (
            stakingInfos?.map((stakingInfo, i) => {
              return <ActivityCard key={i} stakingInfo={stakingInfo} i={i}/>
            }).filter(v=>{
              const isStaking = Boolean(v.props.stakingInfo.stakedAmount.greaterThan('0'))
              const isActive = Boolean(v.props.stakingInfo.totalRewardRate.greaterThan('0'));
              if (onlyStaked && !isStaking) {
                return false;
              }
              if (onlyActive && !isActive) {
                return false;
              }
              return true;
            }).reverse()
          )}
        </PoolSection>
      </AutoColumn>
    </PageWrapper>
  )
}
