import React, {  } from 'react'
import { useActiveWeb3React } from '../../hooks'

import { AutoColumn, ColumnCenter } from '../Column'
import styled, {  } from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
// import { ArrowUpCircle } from 'react-feather'
import successIcon from '../../assets/images/success.png'
import tokenLogo from '../../assets/png/FINN/02.png'
import tokenLogoTOM from '../../assets/png/FINN/05.png'
import { getEtherscanLink } from '../../utils'
import { ExternalLink } from '../../theme/components'
import { UniTokenAnimated } from '../../theme'
import { ButtonPrimary } from '../Button'


const ConfirmOrLoadingWrapper = styled.div<{bg?: string}>`
  width: 100%;
  padding: 24px;
  // background: ${({theme}) => theme.lighCardBg}
  background: ${({bg}) => bg ? bg : '#fff'};
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 60px 0;
`

export function LoadingView({ children, onDismiss, isTOM, bg }: { children: any; onDismiss: () => void; isTOM?: boolean; bg?: string }) {
  return (
    <ConfirmOrLoadingWrapper bg={bg}>
      <RowBetween>
        <div />
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <ConfirmedIconCus>
        <img src={isTOM ? tokenLogoTOM : tokenLogo} alt={'icon'} style={{ width: 200, height: 200, position: 'absolute', filter: 'drop-shadow(rgba(0, 0, 0, 0.3) 8px 8px 10px) drop-shadow(rgba(255, 255, 255, 0.565) -5px -5px 10px)' }} />
        <UniTokenAnimated width="200px" src={isTOM ? tokenLogoTOM : tokenLogo} />{' '}
      </ConfirmedIconCus>
      <AutoColumn gap="50px" justify={'center'}>
        {children}
        <TYPE.subHeader>Confirm this transaction in your wallet</TYPE.subHeader>
      </AutoColumn>
    </ConfirmOrLoadingWrapper>
  )
}

export function SubmittedView({
  children,
  onDismiss,
  hash,
  gap,
  bg
}: {
  children: any
  onDismiss: () => void
  hash: string | undefined
  gap?: string
  bg?: string
}) {

  const { chainId } = useActiveWeb3React()

  return (
    <ConfirmOrLoadingWrapper bg={bg}>
      <RowBetween>
        <div />
        <CloseIcon onClick={onDismiss} />
      </RowBetween>
      <ConfirmedIconCus>
        <img src={successIcon} alt={'icon'} style={{ width: 200, height: 200 }} />
      </ConfirmedIconCus>
      <AutoColumn gap={ gap || "45px" } justify={'center'}>
        {children}
        {chainId && hash && (
          <ButtonPrimary
            padding="20px"
            borderRadius="8px"
            width="85%"
          >
            <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')} style={{ marginLeft: '4px', color: "#fff" }}>
              <TYPE.subHeader>View transaction on moonriver.moonscan.io</TYPE.subHeader>
            </ExternalLink>
          </ButtonPrimary>
        )}
      </AutoColumn>
    </ConfirmOrLoadingWrapper>
  )
}

const ConfirmedIconCus = styled(ConfirmedIcon)`
  padding: 25px 0 50px
`