// import React from 'react'
import styled from 'styled-components';
import disconnect from '../../assets/png/Faucet/wallet_01.png';
import connect from '../../assets/png/Faucet/wallet_02.png';
import walletHover from '../../assets/png/Faucet/wallet_03.png';

const BorderBase = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  max-width: 622px;
`

export const BorderExternal = styled(BorderBase)<{margin?: string}>`
  border: 1px solid ${({theme}) => theme ? theme.primary7 : '#42B5D9'};
  padding: 30px 40px;
  border-radius: 24px;
  margin: ${({margin}) => margin ? margin : '0px'};
`
export const BorderWhite = styled(BorderBase)`
  padding: 20px 30px;
  border-radius: 16px;
`

export const Dash = styled.span<{margin?: string}>`
  display: flex;
  flex: 1;
  height: 2px;
  border-top: 1px dashed #fff;
  margin: ${({margin}) => margin ? margin : '0px'};
`

export const WalletIcon = styled.div<{isConnect?: boolean}>`
  width: 48px;
  height: 48px;
  background-image: url(${({isConnect}) => isConnect ? connect : disconnect});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;

  &:hover {
    background-image: url(${walletHover});
  }

  &:hover .wallet-hover-item{
    display: block;
  }
`

export const WalletHoverItem = styled.div.attrs({
  className: 'wallet-hover-item'
})`
  display: none;
  background: #18262c;
  box-shadow: 0px 0px 20px 0px rgba(66, 181, 217, 0.4);
  border: 1px solid #42B5D9;
  padding: 10px 20px;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 14px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  border-radius: 100px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    border-top: 6px solid #42B5D9;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    transform: translate(-50%, 100%);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    border-top: 6px solid #18262c;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    transform: translate(-50%, 90%);
    margin-top: -6px;
  }
`

export const InputAdd = styled.input`
  color: #41B5D8;
  font-size: 16px;
  border: none;
  width: 100%;
  padding: 0;
  outline: none;
  background-color: transparent;
  :focus-visible {
    border: none;
    outline: none;
  }
`