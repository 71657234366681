/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useMemo, useContext } from 'react'
import { AutoColumn } from '../../components/Column'
import styled, { css } from 'styled-components'
import { useAllStakingRewardsInfo, useStakingInfo } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import PoolCard, { sequence } from '../../components/earn/PoolCard'
import { ButtonPrimary } from '../../components/Button'
// import Toggle from '../../components/Toggle'
import { ButtonHarvestAll } from '../../components/Button'
import { RowBetween } from '../../components/Row'
import ClaimAllRewardModal from '../../components/earn/ClaimAllRewardModal'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
// import { Countdown } from './Countdown'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { TokenAmount } from '@huckleberry/sdk'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import Numeral from 'numeral'
import { isMobile } from 'react-device-detect'
import { commafy } from '../../utils/commafy'

import searchImg from '../../assets/images/search.png'
import QuestionHelper from '../../components/QuestionHelper'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { FINN } from '../../constants'
import { useTokenBalance } from '../../state/wallet/hooks'
import { FINNBAR_ADDRESS } from '../../constants/abis/bridge'
import { ThemeContext } from 'styled-components'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 25px;
  width: 100%;
  justify-self: center;
`
declare global {
  interface Window {
    tvlItems: any;
  }
}

export function saveToLocalStorage(key: string, value: string) {
  window.localStorage.setItem(key, value);
}

export function loadFromLocalStorage(key: string) {
  return window.localStorage.getItem(key);
}

const poolSequence = sequence

const totalDeposit = {};

export default function Earn() {
  const theme = useContext(ThemeContext)
  const { chainId, account } = useActiveWeb3React()
  const stakingInfos = useStakingInfo()
  const [search, setSearch] = useState('')
  const stakingRewardsInfo = useAllStakingRewardsInfo()
  const showStaked = loadFromLocalStorage('showStakedHive')
  const showActive = loadFromLocalStorage('showActiveHive')
  const [onlystakedMode, toggleonlystakedMode] = useState(showStaked === 'true')
  const [onlyactivedMode, toggleonlyactivedMode] = useState(showActive === 'true')
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (stakingRewardsInfo[chainId]?.length ?? 0) > 0)

  const [tvlValue, setTvlValue] = useState('TVL: loading...')

  const finn = chainId ? FINN[chainId] : undefined

  const finnPrice = useUSDCPrice(finn)

  const finnInTom: TokenAmount | undefined = useTokenBalance(chainId ? FINNBAR_ADDRESS[chainId] : undefined, finn)


  useEffect(()=>{
    let timer = setInterval(()=>{
      let tvl = 0;
      // let excursion;
      // let i=0;
      for (const key in window.tvlItems) {
        const element = window.tvlItems[key];
        if (!isNaN(Number(element))) {
          tvl += Number(element)
          // console.log('!tvl sub', i++, key, Number(element))
        }
        // if (key === 'excursion') {
        //   excursion = true;
        // }
      }

      if (tvl === 0) {
        return;
      }

      // console.log('!tvl 1', tvl);

      tvl += Number(finnInTom?.toExact()) * Number(finnPrice?.toFixed(4))

      // console.log('!tvl 2', tvl, finnInTom?.toExact());

      // console.log('!99 tvl', tvl, Number(finnPrice?.toFixed(4)), Number(finnInTom?.toExact()))


      // const amount = new TokenAmount(new Token(1, '0x4Cf0A877E906DEaD748A41aE7DA8c220E4247D9e', 0), tvl.toString())
      // 
      // setTvlValue('TVL: $' + amount.toFixed(0, {groupSeparator: ','}) + ' ' + (excursion ? '(In Rivers and Hydrology)' : '(In Rivers)'));
      if (!isNaN(tvl)) {
        setTvlValue('TVL: ' + commafy(tvl.toString()));
      }
    }, 2000)

    return () => {
      clearInterval(timer)
    }
  }, [finnInTom, finnPrice]);

  const totalRate = useMemo(() => {
    const data = stakingInfos.map(i => i.rewardRate
      ?.multiply(`${60 * 60 * 24 * 7}`)
      ?.toFixed(0))
      //@ts-ignore
    return data.reduce((prev, i) => Number(i) + Number(prev), '0')
  }, [stakingInfos])
  return (
    <PageWrapperCus gap="lg" justify="center">
      <TopSection gap="md">
        <DataCard>
          <CardBGImage />
          <CardNoise />
          {/* <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Huckleberry liquidity mining</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  Deposit your Liquidity Provider tokens to receive FINN, the Huckleberry protocol governance token.
                </TYPE.white>
              </RowBetween>{' '}
              <ExternalLink
                style={{ color: 'white', textDecoration: 'underline' }}
                href="https://medium.com/@HuckleberryDEX"
                target="_blank"
              >
                <TYPE.white fontSize={14}>Read more about FINN</TYPE.white>
              </ExternalLink>
             
            </AutoColumn>
          </CardSection> */}
          <CardBGImage />
          <CardNoise />
        </DataCard>

        <DataCardCus>
          <CardBGImage />
          <CardSection style={{padding: isMobile ? '1rem' : ''}}>
            <AutoColumn gap="md">
              {
                isMobile ?
                  (
                <>
                  <div style={{display: 'flex', borderBottom: '1px solid #0088B3'}}>
                    <TYPE.white fontSize={"20px"} fontWeight={600} style={{ marginBottom: '0.1rem', paddingBottom: '1rem' }}>    {tvlValue}
                    </TYPE.white>
                    <QuestionHelper text={"Includes tokens locked in Rivers and Tom's House"} color={"#fff"} />
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <RowBetween style={{ marginBottom: "4px", color: "rgba(255, 255, 255, 0.5)" }}>
                      <span>My Deposit : </span>
                      <span>{ Numeral(Object.values(totalDeposit).reduce((prev, item) => Number(prev) + Number(item), '0')).format('$0,0') }</span>
                    </RowBetween>
                    <RowBetween style={{color: "rgba(255, 255, 255, 0.5)"}}>
                      <span>My Rate : </span>

                      <span>{ Numeral(totalRate).format('0,0') } FINN / week</span>
                    </RowBetween>

                  </div>
                  {account && <ButtonHarvestAll onClick={() => setShowClaimRewardModal(true)}>Harvest all</ButtonHarvestAll>}
                </>
                  
                  )
                :
                  <RowBetween>
                    <AutoColumn>
                      <RowBetween style={{justifyContent:"left"}}>
                      <TYPE.white fontSize={"20px"} fontWeight={600} style={{margin:'0'}}>{tvlValue}</TYPE.white>
                      <QuestionHelper text={"Includes tokens locked in Rivers and Tom's House"} color={"#fff"} />
                      </RowBetween>
                      
                      <RowBetween style={{ marginTop: "10px", color: "rgba(255, 255, 255, 0.5)" }}>
                        <span>My Deposit : { Numeral(Object.values(totalDeposit).reduce((prev, item) => Number(prev) + Number(item), '0')).format('$0,0') }</span>
                        <ColumnLine />
                        <span>My Rate : { Numeral(totalRate).format('0,0') } FINN / week</span>
                      </RowBetween>
                    </AutoColumn>
                    { account && <ButtonHarvestAll onClick={() => setShowClaimRewardModal(true)}>Harvest all</ButtonHarvestAll>}
                  </RowBetween>
              }
                 
            </AutoColumn>
          </CardSection>
        </DataCardCus>


      </TopSection>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        <DataRow style={{ alignItems: isMobile ? 'center' : 'baseline' }}>
          <TYPE.mediumHeader color={theme.liquidityTxt} style={{ marginTop: '0.5rem' }}>River Composition</TYPE.mediumHeader>
          <InputCom value={search} placeholder={"Search e.g. MOVR / FINN"} onChange={e => setSearch(e.target.value)}/>
        </DataRow>

        <DataRow style={{ alignItems: 'baseline' }}>
          <RowCus>
            <TYPE.white color={theme.liquidityTxt} fontSize={"14px"} lineHeight={"20px"} fontWeight={400} style={{ marginTop: '0.5rem', position: "relative", top: "-4px" }}>
              My Voyages
            </TYPE.white>
            <BtnCon>
              <ButtonPrimaryCus onClick={
                onlystakedMode
                ? () => {
                  toggleonlystakedMode(false);
                  saveToLocalStorage('showStakedHive', 'false');
                }
                : () => {
                  toggleonlystakedMode(true);
                  saveToLocalStorage('showStakedHive', 'true');
                }
              } isActive={onlystakedMode}>
                ON
              </ButtonPrimaryCus>
            </BtnCon>
          </RowCus>
          <RowCus1>
            <TYPE.white color={theme.liquidityTxt} fontSize={"14px"} lineHeight={"20px"} fontWeight={400} style={{ marginTop: '0.5rem', position: "relative", top: "-4px" }}>
              Show Only Active
            </TYPE.white>
            <BtnCon>
              <ButtonPrimaryCus onClick={
                onlyactivedMode
                ? () => {
                  toggleonlyactivedMode(false);
                  saveToLocalStorage('showActiveHive', 'false');
                }
                : () => {
                  toggleonlyactivedMode(true);
                  saveToLocalStorage('showActiveHive', 'true');
                }
              } isActive={onlyactivedMode}>
                ON
              </ButtonPrimaryCus>
            </BtnCon>
          </RowCus1>
          {/* <RowCus1>
            <BtnCon>
              <Toggle
                id="toggle-only-staked-button"
                isActive={onlyactivedMode}
                toggle={
                  onlyactivedMode
                    ? () => {
                      toggleonlyactivedMode(false);
                      saveToLocalStorage('showActiveHive', 'false');
                    }
                    : () => {
                      toggleonlyactivedMode(true);
                      saveToLocalStorage('showActiveHive', 'false');
                    }
                }
              />
            </BtnCon>
          </RowCus1> */}
        </DataRow>
        <PoolSection>
          {stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            <TYPE.body fontWeight={400} fontSize={16} color={theme.liquidityTxt}>No active rewards</TYPE.body>
          ) : (
            stakingInfos?.map((stakingInfo, index) => {
              // need to sort by added liquidity here
              const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))
              const isActive = Boolean(stakingInfo.totalRewardRate.greaterThan('0'));
              let hide = false;

              if (search !== '') {
                const currency0 = unwrappedToken(stakingInfo.tokens[0])
                const currency1 = unwrappedToken(stakingInfo.tokens[1])
                //@ts-ignore
                const condition = `${currency0?.symbol} / ${currency1?.symbol}`.toLowerCase()
                hide = condition.search(search.toLowerCase()) === -1 || (onlystakedMode && !isStaking)
              } else {
                hide = onlystakedMode && !isStaking
              }
              if (onlyactivedMode && !isActive) {
                hide = true;
              }
              return <PoolCard index={index} totalDeposit={totalDeposit} key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} hide={hide}/>
            }).sort((a, b)=>{
              return poolSequence[a.props.index] - poolSequence[b.props.index]
            })
          )}
        </PoolSection>
      </AutoColumn>
      {stakingInfos && stakingInfos.length !== 0 && (
        <ClaimAllRewardModal
          isOpen={showClaimRewardModal}
          onDismiss={() => setShowClaimRewardModal(false)}
          stakingInfos={stakingInfos}
        />
      )}
    </PageWrapperCus>
  )
}

const DataCardCus = styled(DataCard)`
  background: #3AABC3
`

const PageWrapperCus = styled(PageWrapper)`
  margin-top: -4%;
`

const RowCus = styled(RowBetween)`
  flex-direction: row;
  width: 39%;
  position: relative;
  justify-content: flex-start;
  ${
    ({ theme }) => theme.mediaWidth.upToSmall`
      ${
        isMobile && css`
          width: 100%;
        `
      }
    `
  }
`

const RowCus1 = styled(RowCus)`
  justify-content: flex-end;
`

const BtnCon = styled.div`
  display: flex;
  margin-left: 10px;
`

const ButtonPrimaryCus = styled(ButtonPrimary)<{
  isActive: Boolean
}>`
  width: 64px;
  height: 28px;
  line-height: 28px;
  background: ${({ isActive, theme }) => isActive ? theme.riverBtnActiveBg : theme.riverBtnBg };
  font-size: 12px;
  border-radius: 15px;
  padding: 0;
  border: 1px solid ${({ isActive, theme }) => isActive ? theme.riverBtnBorderActive : theme.riverBtnBorder } ;
  color: ${({ isActive , theme}) => isActive ? theme.riverBtnTxtActive : theme.riverBtnTxt };
  &:hover, &:active, &:focus {
    background: ${({ isActive, theme }) => isActive ? theme.riverBtnActiveBg : theme.riverBtnBg };
    color: ${({ isActive }) => isActive ? '#fff' : '#81888B' };
  }
`

const InputCom = styled.input`
  width: 192px;
  height: 28px;
  outline: none;
  padding: 0 15px;
  color: ${({theme}) => theme.riverBtnTxt};
  border-radius: 15px;
  background: ${({theme}) => theme.riverBtnBg};
  border: 1px solid ${({theme}) => theme.riverBtnBorder};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 8.125rem;
    font-size: 0.75rem;
    padding-right: 2rem;
    background-image: url(${searchImg});
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: 90% center;
  `};
`

const DataRow = styled(RowBetween)`
  align-items: center;
`

const ColumnLine = styled.div`
  margin: 0 10px;
  border-right: 1px solid #999;
  height: 100%;
`