import React from 'react';
import styled, { css } from 'styled-components';
import { RowBetween } from '../Row';
import { TYPE } from '../../theme';
import depositIcon from '../../assets/png/Lend/deposit.svg';
import borrowIcon from '../../assets/png/Lend/borrow.svg';
import { commafy2Lend } from '../../utils/commafy';
import { isMobile, browserName } from 'react-device-detect';

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`
const BalanceInfo = styled(RowBetween)<{type?: string}>`
  width: 49%;
  padding: 24px 20px;
  background: ${({theme}) => theme.lendBg};
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.lendNavBorder};
  backdrop-filter: blur(20px);
  color: #${ ({type}) => type === 'deposit' ? '4DD4FE' : 'FFC70B'};

  ${
    isMobile && css`
      width: 100%;
      margin-bottom: 20px;
    `
  }
`;
const Icon = styled.div<{type?:string}>`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-image: url(${ ({type}) => type === 'deposit' ? depositIcon : borrowIcon });
  background-size: 42px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
`;
const InfoCon = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: relative;
`;

const Balance = (
  {
    type,
    title,
    value
  }:{
    type: string
    title: string
    value: string
  }
) => {
  return (
    <BalanceInfo type={type}>
      { browserName === 'Firefox' && <Bg /> }
      {
        type === 'deposit' ?
          <InfoCon>
            <TYPE.body fontSize={16} marginBottom={'10px'}>{title}</TYPE.body>
            <TYPE.white fontSize={24}>${commafy2Lend(value)}</TYPE.white>
          </InfoCon>
        :
          <InfoCon>
            <TYPE.yellow_o fontSize={16} marginBottom={'10px'}>{title}</TYPE.yellow_o>
            <TYPE.white fontSize={24}>${commafy2Lend(value)}</TYPE.white>
          </InfoCon>
      }
      <Icon type={type} />
    </BalanceInfo>
  )
}

export default Balance