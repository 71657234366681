import React, { useEffect, useState, useMemo } from 'react';
import { ModalLend } from '../Modal';
import { X } from 'react-feather';
import styled, { css } from 'styled-components';
import { RowBetween } from '../Row';
import { TYPE } from '../../theme';
import { useTranslation } from 'react-i18next';
import CurrencyLogo from '../CurrencyLogo';
import { useCurrency } from '../../hooks/Tokens';
import useLend, { LendAccountTokens, DepositMarketsItem, Markets, LendAccountTokensItem } from '../../state/lend/hooks';
import { commafy2, commafy2Lend } from '../../utils/commafy';
import { ArrowRight } from 'react-feather';
import { BigNumber } from 'bignumber.js';
import { isMobile } from 'react-device-detect';

const TopContent = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  background: ${({theme}) => theme.lendModalBg};
  position: relative;
`;
const ModalContent = styled.div`
  width: 100%;
`;
const Title = styled.div`
  padding: 24px 40px;
  width: 100%;
  ${
    isMobile && css`
      padding: 24px 20px;
    `
  }
`;
const Content = styled.div`
  padding: 14px 40px 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;

  ${
    isMobile && css`
      padding: 14px 20px 30px;
    `
  }
`;
const NavTypeContent = styled.div`
  margin-top: -1px;
`;
const NavTypeItem = styled(RowBetween)<{noBorder?: boolean}>`
  border-top: ${({noBorder}) => noBorder ? 'none' : '1px solid rgba(255, 255, 255, 0.1)' };
  padding: 20px 0;
  ${
    isMobile && css`
      padding: 1rem 0;
    `
  }
`;
const Button = styled.button`
  background: #41B5D8;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 16px;
  font-size: 22px;
  width: 100%;
  cursor: pointer;

  :disabled {
    background: #4D4D4D;
    color: #999999;
    cursor: not-allowed;
  }

  > * {
    user-select: none;
  }
  ${
    isMobile && css`
      font-size: 1rem;
      height: 3.5rem;
    `
  }
`;
const InpBtnGroup = styled.div`
  display: flex;
  align-items: center;
`;
const Bottom = styled.div`
  width: 90%;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-top: none;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background: ${({theme}) => theme.lendModalBg};
  padding: 20px 30px;
`;
const BottomLine = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
`;
const LimitColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const LimitRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;
const LimitArrow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4px;
`;

interface Item extends LendAccountTokensItem {
  underlying_price: string
  collateral_factor: string
}
const Logo = ({
  address,
  isMovr
}:{
  address: string | undefined
  isMovr: boolean
}) => {
  const oldCurrency = useCurrency(isMovr ? 'MOVR' : address);
  const currency = oldCurrency ? oldCurrency : undefined;
  return <CurrencyLogo style={{marginRight: '8px'}} size={'42px'} currency={currency}></CurrencyLogo>
}
const toNonExponential = (num: string) => {
  const m = num.match(/\d(?:\.(\d*))?e([+-]\d+)/);
  const m1 = (m && m[1]) ? m[1].length : 0;
  const m2 = (m && m[2]) ? m[2] : 0;
  return Number(num).toFixed(Math.max(0, m1 - Number(m2)));
}

const IsDepositModal = (
  {
    showIsDepositModal,
    wrappedOnDismiss,
    info,
    personalAccount,
    markets,
    depositFn
  }:{
    showIsDepositModal: boolean
    wrappedOnDismiss: any
    info: DepositMarketsItem
    personalAccount: LendAccountTokens | null
    markets: Markets | null
    depositFn: () => void
  }
) => {
  const { t } = useTranslation();
  const { useTotalBorrowBalance, useMaxBorrow } = useLend();
  const getTotalBorrowBalance = useTotalBorrowBalance;
  const getMaxBorrow = useMaxBorrow;

  const [totalBorrowBalance, setTotalBorrowBalance] = useState(0);
  const [maxBorrow, setMaxBorrow] = useState(0);
  const [borrowLimitUse, setBorrowLimitUse] = useState(0);
  
  const maxBorrowLen = useMemo(() => {
    if (!maxBorrow) return +info.underlying_decimals;
    const maxBorrowStr = maxBorrow.toString();
    const maxBorrowLen = maxBorrowStr.length - (maxBorrowStr.includes('.') ? maxBorrowStr.indexOf('.') : 0);
    return maxBorrowLen;
  }, [maxBorrow, info]);
  const coinAccountInfo: Item | undefined = useMemo(() => {
    const tokens = personalAccount?.tokens;
    const item =  tokens?.find(item => {
      return item.token_address === info.token_address;
    })
    if (!item) return undefined;
    return Object.assign({
      underlying_price: info.underlying_price,
      collateral_factor: info.collateral_factor
    }, item);
  }, [info, personalAccount])

  const limitChange: BigNumber = useMemo(() => {
    if (!coinAccountInfo) return new BigNumber(0);
    // console.log(maxBorrow)
    const maxBorrowBigNumber = new BigNumber(maxBorrow);
    const supplyUnderlyingBalanceBigNum = new BigNumber(coinAccountInfo.supply_balance_underlying);
    let supplyUnderlyingBalanceStr = supplyUnderlyingBalanceBigNum.times(info.underlying_price).times(info.collateral_factor).toString();
    supplyUnderlyingBalanceStr = supplyUnderlyingBalanceStr.includes('e') ? toNonExponential(supplyUnderlyingBalanceStr) : supplyUnderlyingBalanceStr;
    const newSupplyNum = supplyUnderlyingBalanceStr.substring(0, (supplyUnderlyingBalanceStr.includes('.') ? supplyUnderlyingBalanceStr.indexOf('.') : 0) + maxBorrowLen);

    const newSupplyBigNum = new BigNumber(newSupplyNum);
    console.log('maxBorrow', maxBorrow, 'len', maxBorrowLen, 'newSupplyNum', newSupplyNum, 'showNum', maxBorrowBigNumber.minus(newSupplyBigNum).toString())
    // console.log('newSupplyBigNum===', newSupplyBigNum.toString(), 'supplyUnderlying==', coinAccountInfo.supply_balance_underlying, 'price==', info.underlying_price, 'factor==', info.collateral_factor)
    if (info.is_entered) {
      // console.log('limitChange===', maxBorrowBigNumber.minus(newSupplyBigNum).toString())
      const num = maxBorrowBigNumber.minus(newSupplyBigNum)
      return num.lt(0) ? new BigNumber(0) : num;
    }
    // console.log('limitChange====', maxBorrowBigNumber.plus(newSupplyBigNum).toString())
    return maxBorrowBigNumber.plus(newSupplyBigNum);
  }, [coinAccountInfo, info, maxBorrow, maxBorrowLen]);
  const limitUnderlyingChange = useMemo(() => {
    let str = limitChange.div(info.underlying_price).toString();
    str = str.includes('e') ? toNonExponential(str) : str;

    const newLimitChangeNum = str.substring(0, (str.includes('.') ? str.indexOf('.') : 0) + maxBorrowLen);
    return new BigNumber(newLimitChangeNum);
  }, [info, limitChange, maxBorrowLen]);
  const limitUsedChange: string = useMemo(() => {
    const totalBorrowBigNum = new BigNumber(totalBorrowBalance)
    if (limitChange.eq(0)) {
      if (totalBorrowBigNum.gt(0)) return 'Liquidation';
      return commafy2(0) + '%'
    }
    const bigNum = totalBorrowBigNum.div(limitChange).times(100);
    return bigNum.gt(100) ? 'Liquidation' : `${commafy2(bigNum.toString())}%`;
  }, [limitChange, totalBorrowBalance]);

  const banBtn = useMemo(() => {
    if (info.is_entered) {
      if (!coinAccountInfo) return true;
      const totalBorrowBigNum = new BigNumber(totalBorrowBalance);
      if (totalBorrowBigNum.eq(0)) return false;
      const supplyBanlanceBigNum = new BigNumber(coinAccountInfo.supply_balance_underlying);
      const borrowBanlanceBigNum = new BigNumber(coinAccountInfo.borrow_balance_underlying);
      const depositAmount = supplyBanlanceBigNum.times(coinAccountInfo.underlying_price).times(coinAccountInfo.collateral_factor)
      // console.log(depositAmount.toString())
      const leftAmoount = new BigNumber(maxBorrow).minus(depositAmount)
      // console.log('supplyBanlanceBigNum.gt(0)===', supplyBanlanceBigNum.toString(), supplyBanlanceBigNum.gt(0), 'leftAmoount.lt(0)===', leftAmoount.toString(), leftAmoount.lt(0))
      if (borrowBanlanceBigNum.gt(0)) return true;
      if (leftAmoount.lt(0)) return true;
      if (totalBorrowBigNum.gt(0) && leftAmoount.eq(0)) return true;
      if (totalBorrowBigNum.div(leftAmoount).gt(1)) return true;
      return false;
    }
    return false;
  }, [coinAccountInfo, info, maxBorrow, totalBorrowBalance]);

  useEffect(() => {
    if (!personalAccount || !markets) return;
    getTotalBorrowBalance(personalAccount, markets)
      .then(res => {
        setTotalBorrowBalance(res);
      })
    getMaxBorrow(personalAccount, markets)
      .then(res => {
        setMaxBorrow(res);
      })
  }, [getMaxBorrow, getTotalBorrowBalance, markets, personalAccount]);

  useEffect(() => {
    const num = maxBorrow ? totalBorrowBalance / maxBorrow * 100 : 0;
    setBorrowLimitUse(num);
  }, [maxBorrow, totalBorrowBalance]);

  return (
    <ModalLend
      // bg={theme.lendModalBg}
      newEnLarge={true}
      isOpen={showIsDepositModal}
      onDismiss={wrappedOnDismiss}
      positionCenter={'center'}
    >
      <ModalContent>
        <TopContent>
          <Title>
            <RowBetween style={{marginBottom: '6px'}}>
              <InpBtnGroup>
                <Logo address={info?.underlying_address} isMovr={info?.underlying_symbol === 'MOVR'}></Logo>
                <TYPE.white fontSize={16}>{info?.underlying_symbol}</TYPE.white>
              </InpBtnGroup>
              <X size={26} color={'#fff'} onClick={wrappedOnDismiss}></X>
            </RowBetween>
          </Title>
          <Content>
            <NavTypeContent>
              <NavTypeItem noBorder={true}>
                <TYPE.white_o1 fontSize={16}>{t('Borrow Limit')}</TYPE.white_o1>
                <LimitRight>
                  {/* maxBorrow */}
                  <LimitColumn>
                    <TYPE.body fontSize={16}>${commafy2Lend(maxBorrow)}</TYPE.body>
                    <TYPE.white_o1 fontSize={14}>{commafy2Lend(maxBorrow / +info.underlying_price)}&nbsp;{info.underlying_symbol}</TYPE.white_o1>
                  </LimitColumn>
                  <LimitArrow>
                    <ArrowRight size={16} color={'#4DD4FE'}></ArrowRight>
                  </LimitArrow>
                  <LimitColumn>
                    <TYPE.body fontSize={16}>${commafy2Lend(limitChange.toString())}</TYPE.body>
                    <TYPE.white_o1 fontSize={14}>{commafy2Lend(limitUnderlyingChange.toString())}&nbsp;{info.underlying_symbol}</TYPE.white_o1>
                  </LimitColumn>
                </LimitRight>
              </NavTypeItem>
              <NavTypeItem>
                <TYPE.white_o1 fontSize={16}>{t('Borrow Limit Used')}</TYPE.white_o1>
                <LimitRight>
                  {/* totalBorrowBalance/maxBorrow */}
                  <TYPE.body fontSize={16}>{commafy2(borrowLimitUse)}%</TYPE.body>
                  <LimitArrow>
                    <ArrowRight size={16} color={'#4DD4FE'}></ArrowRight>
                  </LimitArrow>
                  <TYPE.body fontSize={16} color={limitUsedChange === 'Liquidation' ? '#FF5B45' : '#4DD4FE'}>{limitUsedChange}</TYPE.body>
                </LimitRight>
              </NavTypeItem>
            </NavTypeContent>
            <Button disabled={banBtn} onClick={() => depositFn()}>{t(`${info?.is_entered ? 'Disable' : 'Enable'} as Collateral`)}</Button>
          </Content>
        </TopContent>

        {
          !info?.is_entered ?
            (
              <Bottom>
                <TYPE.body fontSize={14} padding={'12px 0'}>{t('Enable as Collateral')}</TYPE.body>
                <BottomLine></BottomLine>
                <TYPE.body fontSize={14} padding={'12px 0'}>{t("Enablin' an asset to act as collateral increases your borrowin' limit. However, please note that your collateral is eligible to be seized durin' liquidation.")}</TYPE.body>
              </Bottom>
            )
          :
            null
        }
      </ModalContent>
    </ModalLend>
  )
};

export default IsDepositModal;