import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RowBetween } from '../../components/Row';
// import LimitLine from '../../components/Lend/LimitLine';
import { NavLink, useLocation } from 'react-router-dom';
import { isMobile, browserName } from 'react-device-detect';
import { ChevronDown, CheckCircle } from 'react-feather';
import { TYPE } from '../../theme';
import { ModalLend } from '../../components/Modal';
import RewardBtn from '../../components/Lend/RewardBtn';
import LendApyBtn, { LendApyContext } from '../../components/Lend/LendApyBtn';

const LendCon = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`
const Content = styled.div`
  max-width: 1040px;
  margin: 0 auto;
`
const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`
const NavLine = styled(RowBetween)`
  margin-bottom: 20px;
`;
const Nav = styled.div`
  display: flex;
  background: ${({theme}) => theme.lendBg};
  border: 1px solid ${({theme}) => theme.lendNavBorder };
  border-radius: 100px;
  position: relative;
`;
const activeClassName = 'ACTIVE';
const NavItemLink = styled(NavLink).attrs({
  activeClassName
})`
  padding: 6px 20px;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({theme}) => theme.text11};

  &.${activeClassName}{
    color: #fff;
    background: ${({theme}) => theme.primary7};
    transform: scale(1.06);
  }
`;
const NavTitle = styled.div`
  padding: 8px 20px;
  border-radius: 100px;
  color: #fff;
  background: ${({theme}) => theme.primary7};
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const ModalContent = styled.div`
  width: calc(100vw - 40px);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 18px 18px 18px 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 100px auto 0;
  padding-bottom: 10px;
`;
const ModalItem = styled(NavLink).attrs({
  activeClassName
})`
  padding: 8px 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  outline: none;
  cursor: pointer;
  text-decoration: none;

  .check {
    display: none;
  }

  &.${activeClassName} .txt{
    color: ${({theme}) => theme.primary7};
  }

  &.${activeClassName} .check{
    display: block;
    stroke: ${({theme}) => theme.primary7};
  }
`;

const BtnGroup = styled.div`
  display: flex;
`;


const Lend = ({children}:{ children: React.ReactNode }) => {
  const { t } = useTranslation();
  const [curNavName, setCurNavName] = useState(t('Dashboard'));
  const [showModal, setShowModal] = useState(false);
  const [apyRateState, setApyRateState] = useState(true);
  const { pathname } = useLocation();
  

  useEffect(() => {
    if (/reservoir/.test(pathname)) {
      setCurNavName('Reservoir');
    } else if (/deposit/.test(pathname)) {
      setCurNavName('Deposit');
    } else if (/borrow/.test(pathname)) {
      setCurNavName('Borrow');
    } else if (/dashboard/.test(pathname)) {
      setCurNavName('My Dashboard');
    }
  }, [pathname]);

  return (
    <LendCon>
      <Content>
        {
          isMobile ?
            (
              <>
                <NavTitle onClick={() => setShowModal(!showModal)}>
                  <TYPE.white fontSize={16} fontWeight={400}>{curNavName}</TYPE.white>
                  <ChevronDown size={18} color='#fff' style={{marginTop: '2px'}}></ChevronDown>
                </NavTitle>
                <RewardBtn />
                <LendApyBtn apyState={apyRateState} setApyRateState={() => setApyRateState(!apyRateState)} />
              </>
            )
          :
            (
              <TitleLine>
                <NavLine>
                  <Nav>
                    { browserName === 'Firefox' && <Bg /> }
                    <NavItemLink
                      id={`lend-reservoir-nav-link`}
                      to={'/lend/reservoir'}
                      isActive={ (match, { pathname }) => Boolean(match) || pathname.startsWith('/reservoir/info') }
                    >{t('Reservoir')}</NavItemLink>
                    <NavItemLink id={`lend-deposit-nav-link`} to={'/lend/deposit'}>{t('Deposit')}</NavItemLink>
                    <NavItemLink id={`lend-borrow-nav-link`} to={'/lend/borrow'}>{t('Borrow')}</NavItemLink>
                    <NavItemLink id={`lend-dashboard-nav-link`} to={'/lend/dashboard'}>{t('My Dashboard')}</NavItemLink>
                  </Nav>
                </NavLine>
                <BtnGroup>
                  <LendApyBtn apyState={apyRateState} setApyRateState={() => setApyRateState(!apyRateState)} />
                  <RewardBtn />
                </BtnGroup>
              </TitleLine>
            )
        }
        {/* <LimitLine></LimitLine> */}
        <LendApyContext.Provider value={apyRateState}>
          {
            children
          }
        </LendApyContext.Provider>
      </Content>
      {
        showModal &&
        <ModalLend
          newEnLarge={true}
          isOpen={showModal}
          onDismiss={() => setShowModal(false)}
          positionCenter={'flex-start'}
        >
          <ModalContent onClick={() => setShowModal(false)}>
            <NavTitle style={{marginTop: '-2px', marginBottom: '10px'}}>
              <TYPE.white fontSize={16} fontWeight={400}>{curNavName}</TYPE.white>
              <ChevronDown size={18} color='#fff' style={{marginTop: '2px', transform: 'rotated(180deg)'}} />
            </NavTitle>
            <ModalItem
              onClick={() => setCurNavName(t('Reservoir'))}
              id={`lend-reservoir-nav-link`}
              to={'/lend/reservoir'}
              isActive={ (match, { pathname }) => Boolean(match) || pathname.startsWith('/reservoir/info')}
            >
              <TYPE.white className='txt'>{t('Reservoir')}</TYPE.white>
              <CheckCircle className='check' size={16} />
            </ModalItem>
            <ModalItem id={`lend-deposit-nav-link`} to={'/lend/deposit'} onClick={() => setCurNavName(t('Deposit'))}>
              <TYPE.white className='txt'>{t('Deposit')}</TYPE.white>
              <CheckCircle className='check' size={16} />
            </ModalItem>
            <ModalItem id={`lend-borrow-nav-link`} to={'/lend/borrow'} onClick={() => setCurNavName(t('Borrow'))}>
              <TYPE.white className='txt'>{t('Borrow')}</TYPE.white>
              <CheckCircle className='check' size={16} />
            </ModalItem>
            <ModalItem id={`lend-dashboard-nav-link`} to={'/lend/dashboard'} onClick={() => setCurNavName(t('My Dashboard'))}>
              <TYPE.white className='txt'>{t('My Dashboard')}</TYPE.white>
              <CheckCircle className='check' size={16} />
            </ModalItem>
          </ModalContent>
        </ModalLend>
      }
    </LendCon>
  )
}

export default Lend;