import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { TYPE } from '../../theme';
import { useTranslation } from 'react-i18next';
import Btn from './Button';
import depositIcon from '../../assets/png/Lend/deposit.svg';
import DepositModal from './DepositModal';
import IsDepositModal from './IsDepositModal';
import useLend, { DepositMarketsItem, LendAccountTokensItem } from '../../state/lend/hooks';
import { commafy2, commafy2Lend } from '../../utils/commafy';
import BigNumber from 'bignumber.js';
import { useCurrencyBalance } from '../../state/wallet/hooks';
import { useActiveWeb3React } from '../../hooks';
import { Token } from '@huckleberry/sdk';
import CurrencyLogo from '../CurrencyLogo';
import { useCurrency } from '../../hooks/Tokens';
import { useLendContractV3 } from '../../hooks/useContract';
import { TransactionResponse } from '@ethersproject/providers';
import { useTransactionAdder } from '../../state/transactions/hooks';
import LocalLoader from '../LocalLoader';
import { isMobile } from 'react-device-detect';
import { RowBetween } from '../Row';
import { ChevronDown } from 'react-feather';
import { ButtonBlueCircle } from '../Button';
import { SUGGEST_GAS_PRICE } from '../../constants';
import RateView from './RateView';

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`
const ListCon = styled.div`
  width: 100%;
  border-radius: 16px;
  position: relative;
`;
const ListLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  backdrop-filter: blur(20px);
  background: ${({theme}) => theme.lendBg};
`;
const TitleLine = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 400;
  color: ${({theme}) => theme.text1};
  padding: 16px 20px;
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-bottom-color: ${ ({ theme }) => theme.text10 };
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: ${({theme}) => theme.lendBg};
  backdrop-filter: blur(20px);
`;
const Icon = styled.div`
  width: 18px;
  height: 20px;
  background-image: url(${depositIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 6px;
`;
const HeaderLine = styled.div`
  padding: 16px 20px;
  background: ${({theme}) => theme.lendBg};
  display: flex;
  backdrop-filter: blur(20px);
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-bottom-color: ${ ({ theme }) => theme.text10 };
  border-top: none;
`;
const HeaderItem = styled.div<{textAlign?:string}>`
  font-size: 14px;
  font-weight: 400;
  color: ${ ({ theme }) => theme.text11 };
  flex: 1;
  text-align: ${ ({textAlign}) => textAlign ? textAlign : 'right'};
`;
const ContentLine = styled.div<{
  dark?: string
  last?: string
}>`
  display: flex;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  background: ${ ({dark, theme}) => dark ? theme.lendListBg2 : theme.lendBg };
  padding: 0 20px;
  backdrop-filter: blur(20px);
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
  border-bottom: none;
  ${({last}) => last && css `
    
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  `}
`;
const ContentItem = styled.div<{
  direction?: string
  flexDirection?: string
}>`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  flex: 1;
  min-height: 84px;
  align-items: center;
  justify-content: flex-end;
  ${
    ({flexDirection}) => flexDirection && css`
      flex-direction: ${flexDirection};
      align-items: flex-end;
      justify-content: center;
    `
  }
  ${
    ({direction}) => direction && css`
      justify-content: flex-start;
    `
  }
`;
const DepositMobileContent = styled.div`
  width: 100%;
  padding-bottom: 0.5rem;
`;
const ContentMobileItem = styled(RowBetween)`
  width: 100%;
  padding: 0.5rem 0;
  align-items: center;
`;
const LogoInfo = styled.div`
  display: flex;
  align-items: center;
`;
const ContentMobileRightRowItem = styled.div`
  display: flex;
  align-items: center;
`;
const PayBtn = styled(ButtonBlueCircle)`
  width: 49%;
  height: 1.875rem;
  font-size: 0.75rem;
  border-radius: 8px;
`;


const rate2commafyFn = (num: number | string) => {
  if (Number(num) !== +num) return '--';
  const rate = new BigNumber(num).times(100).toString();
  return commafy2(rate);
}
const BalanceCommafy = ({
  account,
  token,
  isMovr
}:{
  account: string
  token: Token
  isMovr: boolean
}) => {
  const oldCurrency = useCurrency(isMovr ? 'MOVR' : token.address)
  const currency = oldCurrency ? oldCurrency : undefined;
  const balance = useCurrencyBalance(account, currency);
  return <TYPE.white fontSize={14}>{commafy2Lend(balance?.toExact())}</TYPE.white>
}
const Logo = ({
  address,
  isMovr
}:{
  address: string | undefined
  isMovr: boolean
}) => {
  const oldCurrency = useCurrency(isMovr ? 'MOVR' : address);
  const currency = oldCurrency ? oldCurrency : undefined;
  return <CurrencyLogo style={{marginRight: '8px'}} size='36px' currency={currency}></CurrencyLogo>
}

const DepositMobileItem = ({
  item,
  setCurIndex,
  setShowIsDepositModal,
  setShowDepositModal,
  setDepositType,
  lendApyState
}:{
  item: DepositMarketsItem
  setCurIndex: () => void
  setShowIsDepositModal: () => void
  setShowDepositModal: () => void
  setDepositType: (index: number) => void,
  lendApyState: boolean
}) => {
  const { t } = useTranslation();
  const { account, chainId } = useActiveWeb3React();
  const [showViewMore, setShowViewMore] = useState(false);
  return (
    <DepositMobileContent>
      <ContentMobileItem onClick={() => setShowViewMore(!showViewMore)} style={{marginTop: '0.5rem'}}>
        <LogoInfo>
          <Logo address={item?.underlying_address} isMovr={item?.underlying_symbol === 'MOVR'}></Logo>
          <TYPE.white fontSize={14}>{item.underlying_symbol}</TYPE.white>
        </LogoInfo>
        <ChevronDown size={18} color='#fff' style={{transform: `rotate(${showViewMore ? '180deg' : '0deg'})`}} />
      </ContentMobileItem>
      {
        showViewMore && (
          <div>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Deposit APR')}</TYPE.white_o5>
              <ContentMobileRightRowItem>
                <RateView rate={lendApyState ? item.supply_rate_with_reward : item.supply_rate} />
              </ContentMobileRightRowItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Wallet')}</TYPE.white_o5>
              <ContentMobileRightRowItem>
                {
                  account && chainId ?
                    <BalanceCommafy account={account} isMovr={item.underlying_symbol === 'MOVR'} token={new Token(chainId, item.underlying_address, item.decimals)}></BalanceCommafy>
                  :
                    <TYPE.white fontSize={14}>--</TYPE.white>
                }
                <TYPE.white_o5 fontSize={12}>&nbsp;&nbsp;{item?.underlying_symbol}</TYPE.white_o5>
              </ContentMobileRightRowItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Collateral/Factor')}</TYPE.white_o5>
              <ContentMobileRightRowItem>
                <TYPE.white_o1 fontSize={12}>{rate2commafyFn(item.collateral_factor)}%&nbsp;&nbsp;</TYPE.white_o1>
                <Btn open={item?.is_entered} fn={() => {
                  setCurIndex();
                  setShowIsDepositModal();
                }} />
              </ContentMobileRightRowItem>
            </ContentMobileItem>
            <RowBetween style={{marginBottom: '1.25rem', marginTop: '1.5rem'}}>
              <PayBtn disabled={!account} marginRight={'10px'} onClick={() => {
                setDepositType(0);
                setCurIndex();
                setShowDepositModal();
              }}>{t('Deposit')}</PayBtn>
              <PayBtn disabled={!account} onClick={() => {
                setDepositType(1);
                setCurIndex();
                setShowDepositModal();
              }}>
                <TYPE.white marginTop={'-1px'}>{t('Withdraw')}</TYPE.white>
                </PayBtn>
            </RowBetween>
          </div>
        )
      }
    </DepositMobileContent>
  )
}

const DepositList = ({
  lendApyState
}:{
  lendApyState: boolean
}) => {
  const { t } = useTranslation();
  const { marketList, personalAccount, markets } = useLend();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showIsDepositModal, setShowIsDepositModal] = useState(false);
  const [curIndex, setCurIndex] = useState(0);
  const [depositType, setDepositType] = useState(0);
  const { account, chainId } = useActiveWeb3React();
  const ComptrollerContract = useLendContractV3();
  const addTransaction = useTransactionAdder();

  const marketInfo = useMemo(() => {
    let info = {};
    personalAccount?.tokens.map(item => {
      Object.assign(info, {
        [item.token_address]: item
      })
    })
    return info;
  }, [personalAccount]);
  const DepositMarketsList = useMemo(() => {
    return marketList.map(item => {
      const findresult = Object.keys(marketInfo).includes(item.token_address);
      if (!findresult) return Object.assign({is_entered: false}, item);
      const info: LendAccountTokensItem = marketInfo[item.token_address as keyof typeof marketInfo];
      return Object.assign({is_entered: findresult ? info.is_entered : false}, item);
    })
  }, [marketInfo, marketList]);

  const wrappedOnDismiss = () => {
    setShowDepositModal(false);
  };
  const closeIsDepositModalFn = () => {
    setShowIsDepositModal(false);
  };

  const comptrollerFn = async () => {
    const info = DepositMarketsList[curIndex]
    if (!ComptrollerContract || !info) return;
    if (info.is_entered){
      ComptrollerContract.exitMarket(info.token_address, { gasLimit: 2000000, gasPrice: SUGGEST_GAS_PRICE(), value: '0' })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Disable Collateral ${info.underlying_symbol}`
          })
          closeIsDepositModalFn();
        })
        .catch((error: any) => {
          console.error(error)
        })
    } else {
      ComptrollerContract.enterMarkets([info.token_address], { gasLimit: 2000000, gasPrice: SUGGEST_GAS_PRICE(), value: '0' })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Enable Collateral ${info.underlying_symbol}`
          })
          closeIsDepositModalFn();
        })
        .catch((error: any) => {
          console.error(error)
        })
    }
  }

  return (
    <>
      <ListCon>
        <Bg />
        <TitleLine><Icon />{t('Deposit Markets')}</TitleLine>
        {
          !isMobile &&
          (
            <HeaderLine>
              <HeaderItem textAlign={'left'}>{t('Asset')}</HeaderItem>
              <HeaderItem textAlign={'right'}>{t('Deposit APR')}</HeaderItem>
              <HeaderItem textAlign={'right'}>{t('Wallet')}</HeaderItem>
              <HeaderItem textAlign={'right'}>{t('Collateral Rate')}</HeaderItem>
            </HeaderLine>
          )
        }
        {
          DepositMarketsList && DepositMarketsList.length ? 
            DepositMarketsList.map((item: DepositMarketsItem, index) => (
              isMobile ?
                <ContentLine
                  key={index}
                  dark={Number(index) % 2 ? '' : 'true'}
                  last={Number(index) === DepositMarketsList.length - 1 ? 'true' : ''}
                >
                  <DepositMobileItem
                    item={item}
                    setCurIndex={ () => setCurIndex(index) }
                    setShowIsDepositModal={ () => setShowIsDepositModal(true) }
                    setShowDepositModal={ () => setShowDepositModal(true) }
                    setDepositType={ (index: number) => setDepositType(index) }
                    lendApyState={lendApyState}
                  ></DepositMobileItem>
                </ContentLine>
              :
                <ContentLine
                  key={index}
                  dark={Number(index) % 2 ? '' : 'true'}
                  last={Number(index) === DepositMarketsList.length - 1 ? 'true' : ''}
                  onClick={() => {
                    setShowDepositModal(true);
                    setCurIndex(index);
                  }}
                >
                  <ContentItem direction={'left'}>
                    <Logo address={item?.underlying_address} isMovr={item?.underlying_symbol === 'MOVR'}></Logo>
                    <TYPE.white fontSize={14}>{item?.underlying_symbol}</TYPE.white>
                  </ContentItem>
                  <ContentItem>
                    <RateView rate={lendApyState ? item.supply_rate_with_reward : item.supply_rate} />
                  </ContentItem>
                  <ContentItem>
                    {
                      account && chainId ?
                        <BalanceCommafy account={account} isMovr={item.underlying_symbol === 'MOVR'} token={new Token(chainId, item.underlying_address, item.decimals)}></BalanceCommafy>
                      :
                        <TYPE.white fontSize={14}>--</TYPE.white>
                    }
                    <TYPE.white_o1 fontSize={12}>&nbsp;{item?.underlying_symbol}</TYPE.white_o1>
                  </ContentItem>
                  <ContentItem flexDirection={'column'}>
                    <Btn open={item?.is_entered} fn={() => {
                      setCurIndex(index);
                      setShowIsDepositModal(true);
                    }} />
                    <TYPE.white_o1 fontSize={12} marginTop={'4px'}>{rate2commafyFn(item.collateral_factor)}%</TYPE.white_o1>
                  </ContentItem>
                </ContentLine>
            ))
          :
            (
              <ListLoader>
                <LocalLoader fill={undefined} />
              </ListLoader>
            )
        }
      </ListCon>
      {
        showDepositModal ?
          <DepositModal
            showDepositModal={showDepositModal}
            wrappedOnDismiss={wrappedOnDismiss}
            info={DepositMarketsList[curIndex]}
            personalAccount={personalAccount}
            markets={markets}
            type={isMobile ? depositType : 0}
            lendApyState={lendApyState}
          ></DepositModal>
        :
          null
      }
      {
        showIsDepositModal ?
          <IsDepositModal
            showIsDepositModal={showIsDepositModal}
            wrappedOnDismiss={closeIsDepositModalFn}
            depositFn={comptrollerFn}
            info={DepositMarketsList[curIndex]}
            personalAccount={personalAccount}
            markets={markets}
          ></IsDepositModal>
        :
          null
      }
    </>
  )
}

export default DepositList;