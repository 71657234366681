import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TYPE } from '../../theme';
import Btn from '../../components/Lend/Button';
import { ButtonBlueCircle, ButtonCircle } from '../../components/Button';
import useLend, { LendAccountTokensItem, MarketItem, DepositMarketsItem } from '../../state/lend/hooks';
import { commafy2, commafy2Lend } from '../../utils/commafy';
import { NavLink } from 'react-router-dom';
import InfoContent from '../../components/Lend/InfoContent';
import CurrencyLogo from '../../components/CurrencyLogo';
import { useCurrency } from '../../hooks/Tokens';
import DepositModal from '../../components/Lend/DepositModal';
import IsDepositModal from '../../components/Lend/IsDepositModal';
import BorrowModal from '../../components/Lend/BorrowModal';
import { useLendContractV3 } from '../../hooks/useContract';
import { useTransactionAdder } from '../../state/transactions/hooks';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigNumber } from 'bignumber.js';
import { isMobile, browserName } from 'react-device-detect';
import { RowBetween } from '../../components/Row';
import { useActiveWeb3React } from '../../hooks';
import { ChevronDown } from 'react-feather';
import RateView from '../../components/Lend/RateView';
import { LendApyContext } from '../../components/Lend/LendApyBtn';

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`
const Title = styled.div`
  display: flex;
  border: 1px solid ${({theme}) => theme.text8};
  padding: 16px 20px;
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-bottom-color: ${ ({ theme }) => theme.text10 };
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: ${({theme}) => theme.lendBg};
  backdrop-filter: blur(20px);
`;

const List = styled.div`
  margin-bottom: 20px;
  position: relative;
`;
const ContentLine = styled.div<{
  dark?: string
  last?: string
}>`
  display: flex;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  background: ${ ({dark, theme}) => dark ? theme.lendListBg2 : theme.lendBg };
  padding: 0 20px;
  backdrop-filter: blur(20px);
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
  border-bottom: none;
  ${({last}) => last && css `
    
  border: 1px solid ${ ({ theme }) => theme.text8 };
  border-top: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  `}
`;
const ContentItem = styled.div<{
  direction?: string
  flexDirection?: string
  flex?: number | string
  width?: string
}>`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  flex: ${({flex}) => flex};
  min-height: 84px;
  align-items: center;
  justify-content: flex-end;
  ${
    ({flexDirection}) => flexDirection && css`
      flex-direction: ${flexDirection};
      align-items: flex-end;
      justify-content: center;
    `
  }
  ${
    ({direction}) => direction && css`
      justify-content: flex-start;
    `
  }
  width: ${({width}) => width}
`;

const Introduce = styled.div`
  background: ${({theme}) => theme.lendListBg2};
  border: 1px solid ${({theme}) => theme.text8};
  border-radius: 16px;
  padding: 40px 30px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  backdrop-filter: blur(20px);
`;
const PayBtn = styled(ButtonBlueCircle)`
  width: 88px;
  height: 30px;
  border-radius: 8px;
  font-size: 14px;
`;
const BtnCircelYellow = styled(ButtonCircle)`
  color: #172329;
  font-size: 14px;
  width: auto;
  background: #FFC70B;
  width: 118px;
  height: 30px;
  border-radius: 8px;
  :hover {
    background: #FFC70B;
    border-color: #FFC70B;
  }
`;
const NavItemLink = styled(NavLink)`
  outline: none;
  cursor: pointer;
  text-decoration: none;
`;
const Logo = ({
  address,
  isMovr
}:{
  address: string | undefined
  isMovr: boolean
}) => {
  const oldCurrency = useCurrency(isMovr ? 'MOVR' : address);
  const currency = oldCurrency ? oldCurrency : undefined;
  return <CurrencyLogo style={{marginRight: '8px'}} size='36px' currency={currency}></CurrencyLogo>
}

interface DepositItem extends LendAccountTokensItem {
  underlying_symbol: string
  supply_rate: string
  supply_rate_with_reward: string
  decimals: number
  underlying_price: string
  underlying_address: string
  collateral_factor: string
}
interface BorrowItem extends LendAccountTokensItem {
  underlying_symbol: string
  borrow_rate: string
  borrow_rate_with_reward: string
  decimals: number
  underlying_price: string
  underlying_address: string
}
const DepositMobileContent = styled.div`
  width: 100%;
  padding-bottom: 0.5rem;
`;
const BorrowMobileContent = styled.div`
  width: 100%;
  padding-bottom: 0.5rem;
`;
const ContentMobileItem = styled(RowBetween)`
  width: 100%;
  padding: 0.5rem 0;
  align-items: center;
`;
const LogoInfo = styled.div`
  display: flex;
  align-items: center;
`;
const ContentMobileRightRowItem = styled.div`
  display: flex;
  align-items: center;
`;
const ContentMobileRightColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const ContentMobilePayBtn = styled(ButtonBlueCircle)`
  width: 49%;
  height: 1.875rem;
  font-size: 0.75rem;
  border-radius: 8px;
`;


const rate2commafyFn = (num: number | string) => {
  if (Number(num) !== +num) return '--';
  const rate = new BigNumber(num).times(100).toString();
  return commafy2(rate);
}
const DepositMobileItem = ({
  item,
  setCurDepositIndex,
  setShowIsDepositModal,
  setShowDepositModal,
  setDepositType,
  lendApyState
}:{
  item: DepositItem
  setCurDepositIndex: () => void
  setShowIsDepositModal: () => void
  setShowDepositModal: () => void
  setDepositType: (index: number) => void
  lendApyState: boolean
}) => {
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const [showViewMore, setShowViewMore] = useState(false);
  return (
    <DepositMobileContent>
      <ContentMobileItem onClick={() => setShowViewMore(!showViewMore)} style={{marginTop: '0.5rem'}}>
        <LogoInfo>
          <Logo address={item?.underlying_address} isMovr={item?.underlying_symbol === 'MOVR'}></Logo>
          <TYPE.white fontSize={14}>{item.underlying_symbol}</TYPE.white>
        </LogoInfo>
        <ChevronDown size={18} color='#fff' style={{transform: `rotate(${showViewMore ? '180deg' : '0deg'})`}} />
      </ContentMobileItem>
      {
        showViewMore && (
          <div>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Current Balance')}</TYPE.white_o5>
              <ContentMobileRightColumnItem>
                <TYPE.white fontSize={14} marginBottom={'2px'}>{commafy2Lend(item.supply_balance_underlying, 4)}&nbsp;&nbsp;{item.underlying_symbol}</TYPE.white>
                <TYPE.white_o1 fontSize={12}>${commafy2Lend(Number(item.underlying_price) * Number(item.supply_balance_underlying))}</TYPE.white_o1>
              </ContentMobileRightColumnItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('APR')}</TYPE.white_o5>
              <ContentMobileRightRowItem>
                <RateView rate={lendApyState ? item.supply_rate_with_reward : item.supply_rate} />
              </ContentMobileRightRowItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Collateral/Factor')}</TYPE.white_o5>
              <ContentMobileRightRowItem>
                <TYPE.white_o1 fontSize={12}>{rate2commafyFn(item.collateral_factor)}%&nbsp;&nbsp;</TYPE.white_o1>
                <Btn open={item?.is_entered} fn={() => {
                  setCurDepositIndex();
                  setShowIsDepositModal();
                }} />
              </ContentMobileRightRowItem>
            </ContentMobileItem>
            <RowBetween style={{marginBottom: '1.25rem', marginTop: '1.5rem'}}>
              <ContentMobilePayBtn disabled={!account} marginRight={'10px'} onClick={() => {
                setCurDepositIndex();
                setDepositType(0);
                setShowDepositModal();
              }}>{t('Deposit')}</ContentMobilePayBtn>
              <ContentMobilePayBtn disabled={!account} onClick={() => {
                setCurDepositIndex();
                setDepositType(1);
                setShowDepositModal();
              }}>
                <TYPE.white marginTop={'-1px'}>{t('Withdraw')}</TYPE.white>
                </ContentMobilePayBtn>
            </RowBetween>
          </div>
        )
      }
    </DepositMobileContent>
  )
}
const BorrowMobileItem = ({
  item,
  setCurBorrowIndex,
  setShowBorrowModal,
  setBorrowType,
  lendApyState
}:{
  item: BorrowItem
  setCurBorrowIndex: () => void
  setShowBorrowModal: () => void
  setBorrowType: (index: number) => void
  lendApyState: boolean
}) => {
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const [showViewMore, setShowViewMore] = useState(false);
  return (
    <BorrowMobileContent>
      <ContentMobileItem onClick={() => setShowViewMore(!showViewMore)} style={{marginTop: '0.5rem'}}>
        <LogoInfo>
          <Logo address={item?.underlying_address} isMovr={item?.underlying_symbol === 'MOVR'}></Logo>
          <TYPE.white fontSize={14}>{item.underlying_symbol}</TYPE.white>
        </LogoInfo>
        <ChevronDown size={18} color='#fff' style={{transform: `rotate(${showViewMore ? '180deg' : '0deg'})`}} />
      </ContentMobileItem>
      {
        showViewMore && (
          <div>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('Current Balance')}</TYPE.white_o5>
              <ContentMobileRightColumnItem>
                <TYPE.white fontSize={14} marginBottom={'2px'}>{commafy2Lend(item.borrow_balance_underlying, 4)}&nbsp;&nbsp;{item.underlying_symbol}</TYPE.white>
                <TYPE.white_o1 fontSize={12}>${commafy2Lend(Number(item.underlying_price) * Number(item.borrow_balance_underlying))}</TYPE.white_o1>
              </ContentMobileRightColumnItem>
            </ContentMobileItem>
            <ContentMobileItem>
              <TYPE.white_o5 fontSize={14} fontWeight={400}>{t('APR')}</TYPE.white_o5>
              <ContentMobileRightRowItem>
                <RateView rate={lendApyState ? item.borrow_rate_with_reward : item.borrow_rate} />
              </ContentMobileRightRowItem>
            </ContentMobileItem>
            <RowBetween style={{marginBottom: '1.25rem', marginTop: '1.5rem'}}>
              <ContentMobilePayBtn disabled={!account} marginRight={'10px'} onClick={() => {
                setBorrowType(0);
                setCurBorrowIndex();
                setShowBorrowModal();
              }}>
                <TYPE.white marginTop={'-1px'} fontWeight={400} fontSize={14}>{t('Borrow')}</TYPE.white>
              </ContentMobilePayBtn>
              <ContentMobilePayBtn disabled={!account} onClick={() => {
                setBorrowType(1);
                setCurBorrowIndex();
                setShowBorrowModal();
              }}>
                <TYPE.white marginTop={'-1px'} fontWeight={400} fontSize={14}>{t('Repay')}</TYPE.white>
                </ContentMobilePayBtn>
            </RowBetween>
          </div>
        )
      }
    </BorrowMobileContent>
  )
}

const Dashboard = () => {
  const { t } = useTranslation();
  const { personalAccount, markets } = useLend();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [depositType, setDepositType] = useState(0);
  const [curDepositIndex, setCurDepositIndex] = useState(0);
  const [showIsDepositModal, setShowIsDepositModal] = useState(false);
  const [showBorrowModal, setShowBorrowModal] = useState(false);
  const [curBorrowIndex, setCurBorrowIndex] = useState(0);
  const [borrowType, setBorrowType] = useState(0);


  const ComptrollerContract = useLendContractV3();
  const addTransaction = useTransactionAdder();

  const depositMarketList: Array<LendAccountTokensItem> | undefined = personalAccount?.tokens?.filter((v: LendAccountTokensItem) => Boolean(+v?.supply_balance));
  const depositList: Array<DepositItem> | undefined = depositMarketList?.map((item: LendAccountTokensItem) => {
    const tokenItem: MarketItem = markets[item.token_address as keyof typeof markets]
    return Object.assign(item, {
      underlying_symbol: tokenItem?.underlying_symbol,
      underlying_address: tokenItem?.underlying_address,
      supply_rate: tokenItem?.supply_rate,
      supply_rate_with_reward: tokenItem?.supply_rate_with_reward,
      decimals: tokenItem?.decimals,
      underlying_price: tokenItem?.underlying_price,
      collateral_factor: tokenItem?.collateral_factor
    });
  });
  const borrowMarketList: Array<LendAccountTokensItem> | undefined = personalAccount?.tokens?.filter((v: LendAccountTokensItem) => Boolean(+v?.borrow_balance_underlying));
  const borrowList: Array<BorrowItem> | undefined = borrowMarketList?.map((item: LendAccountTokensItem) => {
    const tokenItem: MarketItem = markets[item.token_address as keyof typeof markets]
    return Object.assign(item, {
      underlying_symbol: tokenItem?.underlying_symbol,
      underlying_address: tokenItem?.underlying_address,
      borrow_rate: tokenItem?.borrow_rate,
      borrow_rate_with_reward: tokenItem?.borrow_rate_with_reward,
      decimals: tokenItem?.decimals,
      underlying_price: tokenItem?.underlying_price
    });
  })

  const curDepositInfo: DepositMarketsItem | undefined = useMemo(() => {
    if (!(depositMarketList && depositMarketList.length)) return undefined;
    const curAccountInfo: LendAccountTokensItem | null = depositMarketList[curDepositIndex];
    if (!curAccountInfo) return undefined;
    const market = markets[curAccountInfo?.token_address as keyof typeof markets];
    const info = Object.assign({
      is_entered: curAccountInfo.is_entered
    }, market);
    return info;
  }, [curDepositIndex, depositMarketList, markets]);
  const curBorrowInfo: MarketItem | undefined = useMemo(() => {
    if (!(borrowMarketList && borrowMarketList.length)) return undefined;
    const curAccountInfo = borrowMarketList[curBorrowIndex];
    if (!curAccountInfo) return undefined;
    const market = markets[curAccountInfo.token_address as keyof typeof markets];
    return market;
  }, [borrowMarketList, curBorrowIndex, markets])


  const comptrollerFn = async () => {
    const info = curDepositInfo;
    if (!ComptrollerContract || !info) return;
    if (info.is_entered){
      ComptrollerContract.exitMarket(info.token_address)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Disable Collateral ${info.underlying_symbol}`
          })
          closeIsDepositModalFn();
        })
        .catch((error: any) => {
          console.error(error)
        })
    } else {
      ComptrollerContract.enterMarkets([info.token_address])
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Enable Collateral ${info.underlying_symbol}`
          })
          closeIsDepositModalFn();
        })
        .catch((error: any) => {
          console.error(error)
        })
    }
  }

  const closeDepositFn = () => {
    setShowDepositModal(false);
  };
  const closeIsDepositModalFn = () => {
    setShowIsDepositModal(false);
  };

  return (
    <LendApyContext.Consumer>
      {
        state => (
          <>
            <InfoContent lendApyState={state}></InfoContent>
            {
              (depositList && depositList.length)
              ?
                <List>
                  { browserName === 'Firefox' && <Bg /> }
                  {
                    isMobile ?
                      (
                        <Title>
                          <TYPE.white fontSize={16} flex={1}>{t('Deposit')}</TYPE.white>
                        </Title>
                      )
                    :
                      (
                        <Title>
                          <TYPE.white_o1 fontSize={14} flex={1} textAlign="left">{t('Deposits')}</TYPE.white_o1>
                          <TYPE.white_o1 fontSize={14} flex={1} textAlign="right">{t('Current Balance')}</TYPE.white_o1>
                          <TYPE.white_o1 fontSize={14} flex={1} textAlign="right">{t('APR')}</TYPE.white_o1>
                          <TYPE.white_o1 fontSize={14} flex={1} textAlign="center">{t('Collateral Rate')}</TYPE.white_o1>
                          <TYPE.white_o1 fontSize={14} width={'200px'}></TYPE.white_o1>
                        </Title>
                      )
                  }
                  {
                      depositList.map((item: DepositItem, index) => (
                        isMobile ?
                          <ContentLine
                            key={index}
                            dark={Number(index) % 2 ? '' : 'true'}
                            last={index === depositList.length - 1 ? 'true' : ''}
                          >
                            <DepositMobileItem
                              item={item}
                              setCurDepositIndex={ () => setCurDepositIndex(index) }
                              setDepositType={ (index) => setDepositType(index) }
                              setShowDepositModal={ () => setShowDepositModal(true) }
                              setShowIsDepositModal={ () => setShowIsDepositModal(true) }
                              lendApyState={state}
                            ></DepositMobileItem>
                          </ContentLine>
                        :
                          <ContentLine
                            key={index}
                            dark={Number(index) % 2 ? '' : 'true'}
                            last={index === depositList.length - 1 ? 'true' : ''}
                          >
                            <ContentItem flex={1} direction={'left'}>
                              <Logo address={item.underlying_address} isMovr={item?.underlying_symbol === 'MOVR'}></Logo>
                              <TYPE.white fontSize={14}>{item.underlying_symbol}</TYPE.white>
                            </ContentItem>
                            <ContentItem flex={1} flexDirection={'column'}>
                              <TYPE.white fontSize={14} marginBottom={'2px'}>{commafy2Lend(item.supply_balance_underlying, 4)}&nbsp;&nbsp;{item.underlying_symbol}</TYPE.white>
                              <TYPE.white_o1 fontSize={12}>${commafy2Lend(Number(item.underlying_price) * Number(item.supply_balance_underlying))}</TYPE.white_o1>
                            </ContentItem>
                            <ContentItem flex={1}>
                              <RateView rate={state ? item.supply_rate_with_reward : item.supply_rate} />
                            </ContentItem>
                            <ContentItem flex={1} style={{alignItems: 'center'}} flexDirection='column'>
                              <Btn open={item.is_entered} fn={() => {
                                setCurDepositIndex(index);
                                setShowIsDepositModal(true);
                              }} />
                              <TYPE.white_o1 marginTop={'4px'} fontSize={12}>{rate2commafyFn(item.collateral_factor)}%</TYPE.white_o1>
                            </ContentItem>
                            <ContentItem width={'200px'}>
                              <PayBtn marginRight={'10px'} onClick={() => {
                                setCurDepositIndex(index);
                                setDepositType(0);
                                setShowDepositModal(true);
                              }}>
                                <TYPE.white marginTop={'-1px'}>{t('Deposit')}</TYPE.white>
                              </PayBtn>
                              <PayBtn onClick={() => {
                                setCurDepositIndex(index);
                                setDepositType(1);
                                setShowDepositModal(true);
                              }}>{t('Withdraw')}</PayBtn>
                            </ContentItem>
                          </ContentLine>
                      ))
                  }
                </List>
              :
                <Introduce>
                  { browserName === 'Firefox' && <Bg /> }
                  <TYPE.white fontSize={12} fontWeight={400} marginBottom={'10px'}>{t('Deposits')}</TYPE.white>
                  <TYPE.white_o fontSize={12} fontWeight={400} marginBottom={'20px'}>{t('This is a list of all the assets you have deposited. For now, it’s empty since you haven’t deposited anythin’ yet!')}</TYPE.white_o>
                  <NavItemLink id={`lend-deposit-nav-link`} to={'/lend/deposit'}>
                    <BtnCircelYellow>{t('Deposit')}</BtnCircelYellow>
                  </NavItemLink>
                </Introduce>
            }
            {
              (borrowList && borrowList.length)
              ?
                <List>
                  { browserName === 'Firefox' && <Bg /> }
                  {
                    isMobile ?
                      (
                        <Title>
                          <TYPE.white fontSize={16} flex={1}>{t('Borrow')}</TYPE.white>
                        </Title>
                      )
                    :
                      (
                        <Title>
                          <TYPE.white_o1 fontSize={14} flex={1} textAlign="left">{t('Borrow')}</TYPE.white_o1>
                          <TYPE.white_o1 fontSize={14} flex={1} textAlign="left">{t('Current Balance')}</TYPE.white_o1>
                          <TYPE.white_o1 fontSize={14} flex={1} textAlign="left">{t('APR')}</TYPE.white_o1>
                          <TYPE.white_o1 fontSize={14} width={'200px'}></TYPE.white_o1>
                        </Title>
                      )
                  }
                  {
                      borrowList.map((item: BorrowItem, index) => (
                        isMobile ?
                          <ContentLine
                            key={index}
                            dark={Number(index) % 2 ? '' : 'true'}
                            last={index === borrowList.length - 1 ? 'true' : ''}
                          >
                            <BorrowMobileItem
                              item={item}
                              setCurBorrowIndex={ () => setCurBorrowIndex(index) }
                              setShowBorrowModal={ () => setShowBorrowModal(true) }
                              setBorrowType={ (index: number) => setBorrowType(index) }
                              lendApyState={state}
                            ></BorrowMobileItem>
                          </ContentLine>
                        :
                        <ContentLine
                          key={index}
                          dark={Number(index) % 2 ? '' : 'true'}
                          last={index === borrowList.length - 1 ? 'true' : ''}
                        >
                          <ContentItem flex={1} direction={'left'}>
                            <Logo address={item.underlying_address} isMovr={item?.underlying_symbol === 'MOVR'}></Logo>
                            <TYPE.white fontSize={14}>{item.underlying_symbol}</TYPE.white>
                          </ContentItem>
                          <ContentItem flex={1} flexDirection={'column'} style={{alignItems: 'flex-start', justifyContent: 'center'}}>
                            <TYPE.white fontSize={14} marginBottom={'2px'}>{commafy2Lend(item.borrow_balance_underlying)}&nbsp;&nbsp;{item.underlying_symbol}</TYPE.white>
                            <TYPE.white_o1 fontSize={12}>${commafy2Lend(Number(item.underlying_price) * Number(item.borrow_balance_underlying))}</TYPE.white_o1>
                          </ContentItem>
                          <ContentItem flex={1} direction={'left'}>
                            <RateView rate={state ? item.borrow_rate_with_reward : item.borrow_rate} />
                          </ContentItem>
                          <ContentItem width={'200px'}>
                            <PayBtn marginRight={'10px'} onClick={() => {
                              setCurBorrowIndex(index);
                              setBorrowType(0);
                              setShowBorrowModal(true);
                            }}>{t('Borrow')}</PayBtn>
                            <PayBtn onClick={() => {
                              setCurBorrowIndex(index);
                              setBorrowType(1);
                              setShowBorrowModal(true);
                            }}>
                              <TYPE.white marginTop={'-1px'}>{t('Repay')}</TYPE.white>
                            </PayBtn>
                          </ContentItem>
                        </ContentLine>
                      ))
                  }
                </List>
              :
                <Introduce>
                  { browserName === 'Firefox' && <Bg /> }
                  <TYPE.white fontSize={12} fontWeight={400} marginBottom={'10px'}>{t('Borrowings')}</TYPE.white>
                  <TYPE.white_o fontSize={12} fontWeight={400} marginBottom={'20px'}>{t('This is a list of all the assets you have borrowed. For now, it’s empty since you haven’t borrowed anythin’ yet!')}</TYPE.white_o>
                  <NavItemLink id={`lend-market-nav-link`} to={'/lend/borrow'}>
                    <BtnCircelYellow>{t('Borrow')}</BtnCircelYellow>
                  </NavItemLink>
                </Introduce>
            }
            {
              showDepositModal && curDepositInfo ?
                <DepositModal
                  showDepositModal={showDepositModal}
                  wrappedOnDismiss={closeDepositFn}
                  info={curDepositInfo}
                  personalAccount={personalAccount}
                  markets={markets}
                  type={depositType}
                  lendApyState={state}
                ></DepositModal>
              :
                null
            }
            {
              showIsDepositModal && curDepositInfo ?
                <IsDepositModal
                  showIsDepositModal={showIsDepositModal}
                  wrappedOnDismiss={closeIsDepositModalFn}
                  depositFn={comptrollerFn}
                  info={curDepositInfo}
                  personalAccount={personalAccount}
                  markets={markets}
                ></IsDepositModal>
              :
                null
            }
            {
              showBorrowModal && curBorrowInfo ?
                <BorrowModal
                  showBorrowModal={showBorrowModal}
                  wrappedOnDismiss={() => setShowBorrowModal(false)}
                  personalAccount={personalAccount}
                  info={curBorrowInfo}
                  markets={markets}
                  type={borrowType}
                  lendApyState={state}
                ></BorrowModal>
              :
                null
            }
          </>
        )
      }
      
    </LendApyContext.Consumer>
  );
}


export default Dashboard;