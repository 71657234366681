import qs from "qs";
import { useCallback } from "react";
import { useActiveWeb3React } from ".";

const apiUrl = 'https://air-drop-api.vercel.app/api/airdropQuery';

const apiUrlTestnet = 'https://air-drop-api.vercel.app/api/airdropQuery-testnet';

type RawObject<T> = { [key: string]: T };

interface Config extends RequestInit {
  params?: RawObject<unknown>;
  url?: string;
  chainId?: number;
}

export const http = async ({ params, url, chainId, ...customConfig }: Config = {}) => {
  let endpoint = "";
  const config = {
    method: "GET",
    ...customConfig,
  };

  if (config.method.toUpperCase() === "GET") {
    endpoint += `?${qs.stringify(params, { encode: false })}`;
  } else {
    config.body = JSON.stringify(params || {});
  }

  if (chainId === 1287) {
    return window
    .fetch(`${url || apiUrlTestnet}${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
  } else {
    return window
    .fetch(`${url || apiUrl}${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
  }

};

export const useHttp = () => {
  const { chainId } = useActiveWeb3React()

  return useCallback(
    (...[config]: Parameters<typeof http>) => http({ ...config, chainId: Number(chainId) }),
    [chainId]
  );
};