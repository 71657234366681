import React from 'react';
import { TYPE } from '../../theme';
import { commafy2 } from '../../utils/commafy';
import BigNumber from 'bignumber.js';


const rate2commafyFn = (num: number | string) => {
  if (Number(num) !== +num) return '--';
  const rate = new BigNumber(num).times(100).abs().toString();
  return commafy2(rate);
}

const RateView = ({rate}:{rate:string}) => {
  
  
  return (
    <>
      {
        new BigNumber(rate).gt(0) ?
          (
            <>
              <TYPE.green fontSize={14}>↑</TYPE.green>&nbsp;
              <TYPE.green fontSize={14}>{rate2commafyFn(rate)}%</TYPE.green>
            </>
          )
        :
          new BigNumber(rate).eq(0) ?
            (
              <>
                <TYPE.white fontSize={14}>{rate2commafyFn(rate)}%</TYPE.white>
              </>
            )
          :
            (
              <>
                <TYPE.red_o fontSize={14}>↓</TYPE.red_o>&nbsp;
                <TYPE.red_o fontSize={14}>{rate2commafyFn(rate)}%</TYPE.red_o>
              </>
            )
      }
    </>
  )
}

export default RateView;