import React, { 
  useCallback, 
  useContext,
  useState
 } from 'react'
import { AutoColumn } from '../../components/Column'
import styled, { ThemeContext } from 'styled-components'

// import { JSBI } from '@huckleberry/sdk'
import { RouteComponentProps } from 'react-router-dom'
import CurrencyLogo from '../../components/CurrencyLogo'
// import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earnExcursion/styled'
import { ButtonPrimary } from '../../components/Button'
import StakingAutoTomModal from '../../components/earnTom/StakingAutoTomModal'
import { useStakeTomEarnTomInfo } from '../../state/stake/hooks'
// import UnstakingAutoTomModal from '../../components/earnTom/UnstakingAutoTomModal'
// import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useColor } from '../../hooks/useColor'

// import { wrappedCurrency } from '../../utils/wrappedCurrency'
// import { useTotalSupply } from '../../data/TotalSupply'
import { 
  // BIG_INT_ZERO, 
  // FINN, 
  // STAKE_TOM_EARN_FINN_PID, 
  TOM } from '../../constants'
import { commafy2, commafy3 } from '../../utils/commafy'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import arrowleft from '../../assets/images/arrowleft.png'
import UnstakingAutoTomAmountModal from '../../components/earnTom/UnstakingAutoTomAmountModal'


const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  position: relative;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 10px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: linear-gradient(90deg,rgb(0,169,157) 0%,rgb(40,171,224) 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`

const PoolData = styled(DataCard)`
  background: ${({ theme }) => theme.liquidityCardBg };
  border: 1px solid rgba(255, 255, 255, 0.2);;
  padding: 1rem;
  z-index: 1;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  grid-gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    grid-gap: 12px;
  `};
`

export default function ManageStakeTomEarnFinn({
  match: {
    params: { currencyIdA }
  },
  history
}: RouteComponentProps<{ currencyIdA: string }>) {
  const { 
    account, 
    chainId } = useActiveWeb3React()
  
  const theme = useContext(ThemeContext)
  
  // const pid = chainId ? STAKE_TOM_EARN_FINN_PID[chainId] : 999999
  
  const tokenA = chainId ? TOM[chainId] : TOM[1285]
  // const tokenB = chainId ? FINN[chainId] : FINN[1285]
  
  const currency0 = unwrappedToken(tokenA)
  
  const stakingInfo = useStakeTomEarnTomInfo()
  // const rewardToken = chainId ? FINN[chainId] : FINN[1285]
  
  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = stakingInfo.balance
  const showAddLiquidityButton = false

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = false

  const backgroundColor = useColor(tokenA)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])


  return (
    <PageWrapper gap="lg" justify="center">
      <GoBack onClick={() => history.push('/excursion')}><img alt="Go Back" src={arrowleft}/></GoBack>
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }} color={theme.liquidityTxt}>
          {'TOM'} in Excursion
        </TYPE.mediumHeader>
        <CurrencyLogo currency={currency0 ?? undefined} size={'40px'} />
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.white style={{ margin: 0 }}>Total deposits</TYPE.white>
            <TYPE.body fontSize={22} fontWeight={500}>
            {stakingInfo.totalStaked ? `${commafy3(stakingInfo.totalStaked?.toString().split('.')[0])} TOM` : ''}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
      </DataRow>
      {stakingInfo && (
        <>
          <StakingAutoTomModal
            isOpen={showStakingModal}
            onDismiss={() => setShowStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          {/* <UnstakingAutoTomModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          /> */}
          <UnstakingAutoTomAmountModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}

      <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
            <CardSection>
              <CardBGImage desaturate />
              <CardNoise />
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600}>Your TOM deposits</TYPE.white>
                </RowBetween>
                <RowBetween style={{ alignItems: 'center', display:'flex',flexWrap:'wrap' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {commafy2(stakingInfo.stakedAmount)}
                  </TYPE.white>
                  <TYPE.white>
                    {'TOM'}
                  </TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
        </BottomSection>
        <TYPE.white style={{ textAlign: 'center' }} fontSize={14} color={theme.liquidityTxt}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          You will earn compound interest on your initial deposits of TOM.
        </TYPE.white>

        {!showAddLiquidityButton && (
          <DataRow style={{ marginBottom: '1rem',gap:0 }}>
            <ButtonPrimary height="60px" padding="8px" borderRadius="8px" width="240px" margin="6px" 
              onClick={handleDepositClick}
              >
              {Number(stakingInfo?.stakedAmount) > 0 ? 'Deposit' : 'Deposit TOM Tokens'}
            </ButtonPrimary>

            {Number(stakingInfo?.stakedAmount) > 0 && (
              <>
                <ButtonPrimary
                  margin="6px"
                  height="60px"
                  padding="8px"
                  borderRadius="8px"
                  width="240px"
                  onClick={() => setShowUnstakingModal(true)}
                >
                  Withdraw
                </ButtonPrimary>
              </>
            )}
          </DataRow>
        )}
      </PositionInfo>
    </PageWrapper>
  )
}

const GoBack = styled.div`
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  top: -35px;
  left: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({theme}) => theme.riverArrowBg};
`
