import { useState, useEffect, useCallback, useMemo } from 'react';
import Web3 from 'web3';
import { useActiveWeb3React } from '../../hooks';
import LendSdk from 'lend-sdk';
import { MULTICALL_NETWORKS } from '../../constants/multicall/index';
import { BigNumber } from 'bignumber.js';
import useUSDCPrice from '../../utils/useUSDCPrice'
import { FINN } from '../../constants'

interface TotalInfoData {
  totalNetApr: number
  totalBorrowBalance: number
  maxBorrow: number
  totalSupplyBalance: number
  totalNetAprWithReward: number
}
export interface LendAccountTokensItem {
  account_address: string
  account_total_borrow: string
  account_total_liquidate: string
  account_total_liquidated: string
  account_total_redeem: string
  account_total_repay: string
  account_total_supply: string
  comp_index_borrow: string
  comp_index_supply: string
  is_entered: boolean
  lifetime_borrow_interest_accrued: string
  lifetime_supply_interest_accrued: string
  supply_balance: string
  supply_balance_underlying: string
  borrow_balance: string
  borrow_balance_underlying: string
  timestamp: number
  token_address: string
}
export interface LendAccountTokens {
  address: string
  comp_reward: string
  health: string
  net_asset_value: string
  rewardAddress: string
  rewardBalance: string
  timestamp: number
  tokens: Array<LendAccountTokensItem>
  total_borrow_value: string
  total_collateral_value: string
  netAPY_with_reward: string
  netAPY: string
}
export interface MarketItem {
  underlying_symbol: string
  underlying_price: string
  supply_rate: string
  supply_rate_with_reward: string
  borrow_rate: string
  borrow_rate_with_reward: string
  token_address: string
  decimals: number
  collateral_factor: string
  cash: string
  total_supply: string
  total_borrows: string
  utilization: string
  reserve_factor: string
  underlying_address: string
  underlying_decimals: string
  symbol: string
  name: string
  exchange_rate: string
  liquidation_incentive: string
  borrowCap: string
}
export interface DepositMarketsItem extends MarketItem {
  is_entered: boolean
}
export interface Markets {
  [key: string]: MarketItem
}







const useLend = () => {
  const { chainId, account } = useActiveWeb3React();
  let finnPrice = useUSDCPrice(chainId ? FINN[chainId] : undefined)?.toSignificant();
  finnPrice = finnPrice ? finnPrice : '0'
  const netId = chainId === 1287 ? 1287 : 1285;
  const webUrl = useMemo(() => {
    const webUrlConfig = {
      // 1287: 'https://rpc.testnet.moonbeam.network',
      1287: 'https://rpc.api.moonbase.moonbeam.network',
      // 1287: 'https://rpc.api.moonriver.moonbeam.network',
      1285: 'https://rpc.api.moonriver.moonbeam.network'
    }
    return webUrlConfig[netId]
  }, [netId]);
  
  const config = useMemo(() => {
    const netConfig = {
      1287: {
        apiUrl: 'https://lend-api-testnet.huckleberry.finance',
        unitroller: '0x6652efa0a15939e8013522ac24dc3e6cb1a1f3e1',
        multiCallAddr: chainId && MULTICALL_NETWORKS[chainId],
        helper: "0xec36dECC18E8d023B82ab97B6bC92151C9c882F6"
      },
      1285: {
        apiUrl: 'https://lend-api.huckleberry.finance',
        unitroller: '0xcffef313b69d83cb9ba35d9c0f882b027b846ddc',
        multiCallAddr: chainId && MULTICALL_NETWORKS[chainId],
        helper: "0x996778ada1802da9b2362cedc7006577269588a4"
      }
    }
    return netConfig[netId]
  }, [chainId, netId]);

  const web3 = useMemo(() => {
    return new Web3(new Web3.providers.HttpProvider(webUrl));
  }, [webUrl]);

  let compoundSdk = useMemo(() => {
    return new LendSdk(config, web3);
  }, [config, web3]);

  const [updater, setUpdater] = useState(0);
  const [markets, setMarkets] = useState<Markets>({});
  const [marketList, setMarketList] = useState<Array<MarketItem>>([]);
  const [totalInfo, setTotalInfo] = useState<TotalInfoData>({
    totalNetApr: 0,
    totalBorrowBalance: 0,
    maxBorrow: 0,
    totalSupplyBalance: 0,
    totalNetAprWithReward: 0
  });
  const [personalAccount, setPersonalAccount] = useState<LendAccountTokens | null>(null);
  // const accountAddress = '0x52F1045671F56f572f7743232B558dDCa0627e10';


  // update markets
  useEffect(() => {
    try {
      const isLogin = Boolean(account)
      const accountAddress = isLogin ? account : `0x${'0'.repeat(40)}`;
      compoundSdk.getCompoundData2(accountAddress, finnPrice).then(async ({markets, account}:{
        markets: Markets
        account: LendAccountTokens
      }) => {
        setMarkets(markets);
        // console.log('markets', markets, JSON.stringify(markets))
        let sortMarkets = Object.values(markets).sort((a: MarketItem, b: MarketItem):number => new BigNumber(b.total_supply).times(b.underlying_price).minus(new BigNumber(a.total_supply).times(a.underlying_price)).toNumber())
        // console.log('sortMarkets', sortMarkets)
        setMarketList(sortMarkets.sort(v => v.underlying_symbol.toLocaleUpperCase() === 'MOVR' ? -1 : 1));
        if (isLogin) setPersonalAccount(account);
        // console.log(account, markets)
      }).catch ((e:any) => {
        console.error(e)
      });
    } catch (e) {
      console.error(e)
    }
  }, [account, compoundSdk, finnPrice, updater]);
  // update totalInfo
  useEffect(() => {
    if (!Object.values(markets).length) return;
    if (!personalAccount || !Object.values(personalAccount).length) return;
    // console.log('personalAccount', personalAccount)
    const func = async () => {
      const totalSupplyBalance = await compoundSdk.totalSupplyBalance(personalAccount, markets);
      const totalBorrowBalance = await compoundSdk.totalBorrowBalance(personalAccount, markets);
      const maxBorrow = await compoundSdk.maxBorrow(personalAccount, markets);
      const totalNetApr = Number(personalAccount.netAPY);
      const totalNetAprWithReward = account ? Number(personalAccount.netAPY_with_reward) : totalNetApr;
      const info: TotalInfoData = {
        totalSupplyBalance,
        totalBorrowBalance,
        totalNetApr,
        maxBorrow,
        totalNetAprWithReward
      }
      setTotalInfo(info)
    };
    func();
  }, [personalAccount, markets, updater, compoundSdk, account]);

  const isAuthorized = useCallback(() => {
    return async (personalAccount: LendAccountTokens, marketInfo: MarketItem | DepositMarketsItem) => {
      if (!personalAccount || !marketInfo) return;
      try {
        let approved = await compoundSdk.isAuthorized(personalAccount, marketInfo);
        return approved;
      } catch (err) {
        console.error(err)
      }
    }
    
  }, [compoundSdk]);

  const useMaxFreeReedemAmount = useCallback(() => {
    return async (personalAccount: LendAccountTokens, markets: Markets, address: string) => {
      if (!personalAccount || !markets || !Object.values(markets).length || !address) return;
      const maxMarkets = await compoundSdk.maxFreeReedemAmountOfAllMarket(personalAccount, markets, 2);
      const maxNum = maxMarkets[address as keyof typeof markets];
      return maxNum;
    }
  }, [compoundSdk]);

  const useAllMaxFreeReedemAmount = useCallback(() => {
    return async (personalAccount: LendAccountTokens, markets: Markets, address: string) => {
      if (!personalAccount || !markets || !Object.values(markets).length || !address) return;
      const maxMarkets = await compoundSdk.maxFreeReedemAmountOfAllMarket(personalAccount, markets, 2, 1);
      const maxNum = maxMarkets[address as keyof typeof markets];
      return maxNum;
    }
  }, [compoundSdk]);

  const useLimitPer = useCallback(() => {
    return async (personalAccount: LendAccountTokens, markets: Markets, address: string, limit: number | undefined = 1 ) => {
      const limitMarkets = await compoundSdk.maxFreeBorrowOfAllMarket(personalAccount, markets, 20, limit);
      const market: MarketItem = markets[address as keyof typeof markets];
      const limitNum = limitMarkets[address as keyof typeof markets];
      const limitBigNum = new BigNumber(+limitNum).toFixed(+market.underlying_decimals);
      return limitBigNum;
    }
  }, [compoundSdk]);

  const useTotalBorrowBalance = useCallback(() => {
    return async (personalAccount: LendAccountTokens, markets: Markets) => {
      const value = await compoundSdk.totalBorrowBalance(personalAccount, markets);
      return value;
    }
  }, [compoundSdk]);

  const useMaxBorrow = useCallback(() => {
    return async (personalAccount: LendAccountTokens, markets: Markets) => {
      const value = await compoundSdk.maxBorrow(personalAccount, markets);
      return value;
    }
  }, [compoundSdk])

  // const useReward = useCallback(() => {
  //   return async () => {
  //     const amount = await compoundSdk.comp_reward();
  //     return amount;
  //   }
  // }, [compoundSdk]);

  useEffect(() => {
    const timer = setInterval(() => {
      setUpdater(Date.now());
    }, 30 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return {
    markets,
    marketList,
    totalInfo,
    personalAccount,
    isAuthorized: isAuthorized(),
    useMaxFreeReedemAmount: useMaxFreeReedemAmount(),
    useAllMaxFreeReedemAmount: useAllMaxFreeReedemAmount(),
    useLimitPer: useLimitPer(),
    useTotalBorrowBalance: useTotalBorrowBalance(),
    useMaxBorrow: useMaxBorrow()
  };
}

export default useLend;