import { ChainId } from '@huckleberry/sdk'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Modal from '../Modal'
import { ExternalLink } from '../../theme'
import { Text } from 'rebass'
import { CloseIcon } from '../../theme/components'
import { RowBetween } from '../Row'
import { AlertTriangle } from 'react-feather'
import { ButtonPrimary } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import tokenLogo from '../../assets/png/FINN/02.png'
import successIcon from '../../assets/images/success.png'
import { UniTokenAnimated } from '../../theme'

import { getEtherscanLink } from '../../utils'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div<{tsBg?:string}>`
  width: 100%;
  background-color: ${({tsBg}) => tsBg };
`

const WrapperCus = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.liquidityAddModalBg };
  position: relative;
`

const Section = styled(AutoColumn)`
  padding: 1.5rem;
`

const BottomSection = styled(Section)<{bg?:string}>`
  background-color: ${({ theme, bg }) => bg ? bg : theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const BottomSectionV1 = styled(Section)`
  background-color: #152025;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const BottomSectionNew = styled(Section)`
  background-color: ${({theme}) => theme.liquidityAddCardBg};
  margin-top: 5rem;
  border-radius: 20px;
  padding-top: 8rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
`

const SectionCus = styled(Section)`
  position: absolute;
  background: ${({theme}) => theme.currencySearchBg};
  box-shadow: 0px 0px 50px 0px rgba(47, 156, 163, 0.2);
  border-radius: 16px;
  padding: 0;
  margin: 24px;
  top: 3rem;
  min-height: 160px;
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.3);
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 60px 0;
`

export function AddButtonContnet({ symbol, onAddHandle }: { symbol?: string; onAddHandle?: () => void }) {
  const {t} = useTranslation()
  return (
    <ButtonPrimary onClick={onAddHandle} style={{ margin: '20px 0 0 0' }}>
      <Text fontWeight={500} fontSize={20}>
        {t(`Add ${symbol} to MetaMask`)}
      </Text>
    </ButtonPrimary>
  )
}

function ConfirmationPendingContent({ onDismiss, pendingText }: { onDismiss: () => void; pendingText: string }) {
  const {t} = useTranslation()
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIconCus>
          <img src={tokenLogo} alt={'icon'} style={{ width: 200, height: 200, position: 'absolute', filter: 'drop-shadow(rgba(0, 0, 0, 0.3) 8px 8px 10px) drop-shadow(rgba(255, 255, 255, 0.565) -5px -5px 10px)' }} />
          <UniTokenAnimated width="200px" src={tokenLogo} />{' '}
        </ConfirmedIconCus>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={400} fontSize={12} color={"#999"}>
            {t('waitingForConfirmation')}
          </Text>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={600} fontSize={14} color="" textAlign="center">
              {pendingText}
            </Text>
          </AutoColumn>
          <Text fontSize={12} color="#565A69" textAlign="center">
            {t('confirmThisTransaction')}
          </Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

const ConfirmedIconCus = styled(ConfirmedIcon)`
  padding: 25px 0 50px
`

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  tsBg,
  addBtn
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  tsBg?: string
  addBtn?: () => React.ReactNode
}) {
  
  const { t } = useTranslation()
  return (
    <Wrapper tsBg={tsBg}>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIconCus>
          <img src={successIcon} alt={'icon'} style={{ width: 200, height: 200 }} />
        </ConfirmedIconCus>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={500} fontSize={20}>
            {t('transactionSubmitted')}
          </Text>
          {chainId && hash && (
            <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
              <Text fontWeight={500} fontSize={14} color="#0071bc">
                {t('viewOn')}
              </Text>
            </ExternalLink>
          )}
          {
            addBtn ?
              addBtn()
            :
              <ButtonPrimary onClick={onDismiss} style={{ margin: '20px 0 0 0' }}>
                <Text fontWeight={500} fontSize={20}>
                  {t('close')}
                </Text>
              </ButtonPrimary>
          }
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent,
  color
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode,
  color?: string
}) {
  return (
    <Wrapper style={{backgroundColor: '#fff', display: 'flex', flex: 1, overflowY: 'hidden', flexDirection: 'column'}}>
      <RowBetween backgroundColor={'#fff'} padding={'1.5rem 0'}>
        <Text fontWeight={500} fontSize={20} marginLeft={'1.5rem'}>
          {title}
        </Text>
        <CloseIconCus onClick={onDismiss} color={color} />
      </RowBetween>
      <div style={{backgroundColor: '#F7F8FA', display: 'flex', flex: 1, overflowY: 'scroll', flexDirection: 'column'}}>
        <Section style={{borderBottomLeftRadius: '22px', paddingTop: '0', backgroundColor: '#fff'}}>
          {topContent()}
        </Section>
        <BottomSection gap="20px">{bottomContent()}</BottomSection>
      </div>
    </Wrapper>
  )
}

export function ConfirmationModalContentV1({
  title,
  bottomContent,
  onDismiss,
  topContent
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper style={{backgroundColor: '#152025', display: 'flex', flex: 1, overflowY: 'hidden', flexDirection: 'column'}}>
      <RowBetween padding={'1.5rem 0'}>
        <Text color={"#fff"} fontWeight={"bold"} fontSize={24} marginLeft={'1.5rem'}>
          {title}
        </Text>
        <CloseIconCus onClick={onDismiss} />
      </RowBetween>
      <div style={{ display: 'flex', flex: 1, overflowY: 'scroll', flexDirection: 'column'}}>
        <Section style={{borderBottomLeftRadius: '20px', paddingTop: '0', borderBottomRightRadius: '20px', borderBottom: "1px solid #61696C" }}>
          {topContent()}
        </Section>
        <BottomSectionV1 gap="20px">{bottomContent()}</BottomSectionV1>
      </div>
    </Wrapper>
  )
}

const CloseIconCus = styled(CloseIcon)`
  color: #fff;
  margin-right: 1.5rem;
`

const RowBetweenCus = styled(RowBetween)`
  padding-top: 25px;
  padding-left: 25px;
  color: #fff;
`

export function ConfirmationModalContentNew({
  title,
  bottomContent,
  onDismiss,
  topContent
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <WrapperCus>
      <RowBetweenCus>
        <Text fontWeight={500} fontSize={20}>
          {title}
        </Text>
        <CloseIconCus onClick={onDismiss} />
      </RowBetweenCus>
      <SectionCus>
        {topContent()}
      </SectionCus>
      <BottomSectionNew gap="10px">{bottomContent()}</BottomSectionNew>
    </WrapperCus>
  )
}

export function TransactionErrorContent({ message, onDismiss, bg }: { message: string; onDismiss: () => void; bg?: string }) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper tsBg={bg}>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            Error
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
          <Text fontWeight={500} fontSize={16} color={theme.red1} style={{ textAlign: 'center', width: '85%' }}>
            {message}
          </Text>
        </AutoColumn>
      </Section>
      <BottomSection bg={bg} gap="12px">
        <ButtonPrimary onClick={onDismiss}>Dismiss</ButtonPrimary>
      </BottomSection>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string,
  enlarge?: boolean,
  bg?: string,
  tsBg?: string,
  addBtn?: () => React.ReactNode
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  enlarge,
  bg,
  tsBg,
  addBtn
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal enlarge={enlarge} isOpen={isOpen} onDismiss={onDismiss} maxHeight={90} bg={bg ? bg : (!attemptingTxn && !hash) ? theme.currencySearchBg : attemptingTxn ? theme.finnbarModalBg : ''} borderBottomRadius={isMobile ? '0px' : '20px'}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent addBtn={addBtn} tsBg={tsBg} chainId={chainId} hash={hash} onDismiss={onDismiss} />
      ) : (
        content()
      )}
    </Modal>
  )
}
