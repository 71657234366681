// import { TokenAmount } from '@huckleberry/sdk';
import React, { useState } from 'react';
import { RowBetween } from '../../components/Row';
import { Text } from 'rebass';
import { ButtonPrimary, ButtonSmall } from '../../components/Button';
// import { useTokenBalance } from '../../state/wallet/hooks';
import { useActiveWeb3React } from '../../hooks';
// import { useCurrencyBalance } from '../../state/wallet/hooks';
import { FINN, SUGGEST_GAS_PRICE, TOM } from '../../constants';
import { useTranslation } from 'react-i18next';
// // import { useWalletModalToggle } from '../../state/application/hooks';
// import { useHttp } from '../../hooks/useHttps';
// import { ETHER } from '@huckleberry/sdk';
import styled, { css } from 'styled-components';
import { Input as NumericalInput } from '../../components/NumericalInput';
// import { isMobile } from 'react-device-detect';
import { FINNBAR_ADDRESS } from '../../constants/abis/bridge'
import { useFinnBarInfo } from '../../state/stake/hooks';
// import useReflectionAPY from '../../hooks/reflection/useReflectionAPY';
// import { useApproveCallback } from '../../hooks/useApproveCallback';
import { useFinnBarContract, useTokenContract } from '../../hooks/useContract';
import { useEffect } from 'react';
import { useCallback } from 'react';
import BigNumber from 'bignumber.js';
import { isMobile } from 'react-device-detect';
import { useTransactionAdder } from '../../state/transactions/hooks';
import { ChainId, TokenAmount } from '@huckleberry/sdk';
import { useTokenBalance } from '../../state/wallet/hooks';
import { useWeeklyBuyBack, useNotMined, useTotalBurned, useTotalBuyBack, useTotalSupply, useDailyBuyBack } from '../../data/TotalSupply';
import { useMemo } from 'react';
import useUSDCPrice from '../../utils/useUSDCPrice';
import Numeral from 'numeral'

const colorFFF = 'rgba(255, 255, 255, .5)';
const colorBlue = '#4DD4FE';
// const colorYellow = '#FFC70B';

const FinnBarContainer = styled.div`
  width: 100%;
`

const MobileRowBetween = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    ${isMobile &&
      css`
        flex-direction: column;
    `}
  `}
`

const SampleContent = styled.div`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
`

const Border = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 1.5rem;
  position: relative;
  padding: 1.25rem;
  background: ${({ theme }) => theme.cardBg };
  backdrop-filter: blur(20px);
`
const BorderYellow = styled(Border)`
  border: 2px solid #FFC70B;
  padding: 0.5rem 1rem;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: #FFC70B;
  font-size: 1rem;
`
const RowBase = styled.div`
  display: flex;
`

const RowNormal = styled(RowBase)`
  flex: 1;
`

const Row = styled(RowNormal)`
  flex-direction: column;
`

const InpRow = styled(RowBase)`
  width: 100%;
  border-radius: 16px;
  border: 2px solid #4DD4FE;
  padding: 1rem 1.25rem;
  align-items: center;
  margin-bottom: 0.75rem;
`

const Icon = styled.img`
  width: 4.375rem;
  height: 4.375rem;
  /* background-image: url(./images/FINN/05.png); */
  background-repeat: no-repeat;
  background-size: 100%;
`

const MobileProductCon = styled(RowBetween)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  margin-bottom: 1rem;
  padding-bottom: 1rem

  ${
    ({ theme }) => theme.mediaWidth.upToSmall`
      ${
        isMobile && css`
          flex-direction: column;
          align-items: flex-start;
          padding-bottom: 0.425rem;
        `
      }
    `
  }
`

function Product({name, num, src}:{name?:string, num?: number, src?: string}) {
  return (
    <RowNormal style={{paddingRight: '0.25rem', marginBottom: isMobile ? '1rem' : ''}}>
      <Icon src={src} />
      <Row style={{flexDirection: 'column', flex: 1, marginLeft: '0.625rem'}}>
        <Text fontWeight={400} fontSize={16} color={'#fff'} marginBottom={'0.625rem'}>{name}</Text>
        <Text fontWeight={400} fontSize={24} color={num ? colorFFF : colorBlue}>{num}</Text>
      </Row>
    </RowNormal>
  )
}

const MIN = '0xFFFFFFFFFFFFFFFF'
const MAX = '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF'

export default function FinnBar() {
  const { t } = useTranslation();
  const { account, chainId } = useActiveWeb3React();
  const uni = chainId ? FINN[chainId] : undefined;
  const uniPrice = useUSDCPrice(uni)
  const stakedFinn: TokenAmount | undefined = useTokenBalance(chainId ? FINNBAR_ADDRESS[chainId] : undefined, FINN[chainId ? chainId : 1285]);

  const totalBuyBack: TokenAmount | undefined = useTotalBuyBack()
  const weeklyBuyBack: TokenAmount | undefined = useWeeklyBuyBack()
  const dailyBuyBack: TokenAmount | undefined = useDailyBuyBack()
  let buyBackApr;
  if (Number(weeklyBuyBack?.toExact()) > 0) {
    buyBackApr = weeklyBuyBack && stakedFinn && (Number(weeklyBuyBack.toExact()) / 7 * 365 * 100 / Number(stakedFinn.toExact())).toFixed(0)
  } else {
    buyBackApr = dailyBuyBack && stakedFinn && (Number(dailyBuyBack.toExact()) * 365 * 100 / Number(stakedFinn.toExact())).toFixed(0)
  }
  const totalSupply: TokenAmount | undefined = useTotalSupply(uni)
  const totalBurned: TokenAmount | undefined = useTotalBurned(uni)
  const notMined: TokenAmount | undefined = useNotMined(uni)
  // const unclaimedUni = useTokenBalance(useMerkleDistributorContract()?.address, uni)
  const circulation: TokenAmount | undefined = useMemo(
    () =>
       uni && chainId === ChainId.MOON_MAINNET
        ? totalSupply?.subtract(totalBurned ? totalBurned : new TokenAmount(uni!, '0'))?.subtract(notMined ? notMined : new TokenAmount(uni!, '0'))
        : totalSupply?.subtract(totalBurned ? totalBurned : new TokenAmount(uni!, '0'))?.subtract(notMined ? notMined : new TokenAmount(uni!, '0')),
    [chainId, totalSupply, uni, totalBurned, notMined]
  )

  // const { currencies } = useDerivedSwapInfo()
  // const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined);
  // const faucetAddress = '0x30b4A1Fb82172bFc9dA35195FE0C42241b3BbD5F';
  // let api = useHttp();
  
  // const balance = useCurrencyBalance(faucetAddress, ETHER);
  // let faucetNum = balance ? balance.toSignificant(6) : 0;
  // console.log('faucetNum', faucetNum, balance, typeof balance)

  let [type, setType] = useState('StakeFINN');
  let [value, setValue] = useState('');
  // let [finnBalance, setFinnBalance] = useState(account ? Number(uniBalance?.toFixed(2, { groupSeparator: ',' }) || 0) : 0);

  // useEffect(() => {
  //   account && setFinnBalance(Number(uniBalance?.toFixed(2, { groupSeparator: ',' })) || 0)
  //   !account && setFinnBalance(0)
  // }, [account, uniBalance]);

  const finnInfo = useFinnBarInfo()
  /* const apr = useReflectionAPY() */
  const finnToken = useTokenContract(chainId ? FINN[chainId].address : undefined, true)
  const tomToken = useTokenContract(chainId ? TOM[chainId].address : undefined, true)

  const finnBar = useFinnBarContract()

  const addTransaction = useTransactionAdder()


  const [approved, setApproved] = useState(false)
  const [approving, setApproving] = useState(false)
  const [updater, setUpdater] = useState(0);
  useEffect(()=>{
    if (!chainId || !finnToken || !account || !tomToken) {
      return;
    }

    const func = async () => {
      try {
        let allowance;
        if (type === 'Unstake') {
          allowance = await tomToken.allowance(account, FINNBAR_ADDRESS[chainId])
        } else {
          allowance = await finnToken.allowance(account, FINNBAR_ADDRESS[chainId])
        }
        if (allowance.lt(MIN)) {
          setApproved(false)
        } else {
          setApproved(true)
        }
      } catch(err) {
        console.error(err)
      }
    }

    func();

  }, [chainId, finnToken, account, updater, type, tomToken])

  const approve = useCallback(()=>{
    return async () => {
      if (!chainId || !finnToken || !account || !tomToken) {
        return;
      }

      setApproving(true);
      try {
        let ret;
        if (type === 'Unstake') {
          ret = await tomToken.approve(FINNBAR_ADDRESS[chainId], MAX, {gasPrice: SUGGEST_GAS_PRICE()})
        } else {
          ret = await finnToken.approve(FINNBAR_ADDRESS[chainId], MAX, {gasPrice: SUGGEST_GAS_PRICE()})
        }
        addTransaction(ret, {summary: "Approve for Tom's house"})
        await ret.wait()
      } catch (error) {
        console.error(error)
      }
      setUpdater(Date.now())

      setTimeout(()=>{
        setUpdater(Date.now())
        setApproving(false);
      }, 5*1000)
    }
  }, [chainId, finnToken, account, type, tomToken, addTransaction])

  const [waiting, setWaiting] = useState(false)

  const onStart = useCallback(()=>{
    return async () => {
      if (!chainId || !finnBar || !account) {
        return;
      }

      const amount = '0x' + new BigNumber(value).multipliedBy(1e18).toString(16).split('.')[0]
      setWaiting(true)
      try {
        let ret;
        if (type === 'Unstake') {
          ret = await finnBar.withdraw(amount, {gasPrice: SUGGEST_GAS_PRICE()})
        } else {
          ret = await finnBar.deposit(amount, {gasPrice: SUGGEST_GAS_PRICE()})
        }
        addTransaction(ret, {summary: `${type === 'Unstake'? "Unstake by" : "Stake"} ${value} ${type === 'Unstake'? "TOM" : "FINN"} in Tom's House`})

        await ret.wait()
        setValue('')
      } catch (error) {
        console.error(error)
      }
      setWaiting(false)
    }
  }, [chainId, finnBar, account, value, type, addTransaction])

  const tomPrice = useMemo(() => {
    const price = uniPrice && finnInfo.times && !isNaN(finnInfo.times) && new TokenAmount(uni!, (1000000 * Number(finnInfo.times?.toFixed(6)) * Number(uniPrice?.toFixed(4))).toFixed(0)).multiply('1000000000000').toFixed(4)
    return price || '0'
  }, [finnInfo.times, uni, uniPrice])

  const totalTomPrice = useMemo(() => {
    return stakedFinn ? Numeral(Number(stakedFinn.toFixed(0)) * Number(uniPrice?.toFixed(4) ?? '0')).format('$0,0') : '$0'
  }, [stakedFinn, uniPrice])

  return (
    <FinnBarContainer>
      <SampleContent>
        <Border style={{marginBottom: '1rem'}}>
          <Text fontWeight={400} fontSize={24} color={'#fff'} marginBottom={'1rem'}>{t('sampleTitle')}</Text>
          <Text fontWeight={400} fontSize={16} color={colorBlue} marginBottom={'1rem'} lineHeight={'1.7'} >{t('samplePoint')}</Text>
          <Text fontWeight={400} fontSize={16} color={'#fff'} marginBottom={'1rem'} lineHeight={'1.7'} >{t('sampleIntroduce')}</Text>
          <Text fontWeight={400} fontSize={16} color={'#fff'} marginBottom={'1rem'} lineHeight={'1.7'} >{t('sampleIntroduce1')}</Text>
          <Text fontWeight={400} fontSize={16} color={'#fff'} marginBottom={'1rem'} lineHeight={'1.7'} >{t('sampleIntroduce2')}</Text>
        </Border>
        <MobileRowBetween>
          <Row style={{margin: isMobile ? '0 0 1rem 0' : '0 1rem 0 0'}}>
            <Border style={{marginBottom: '1rem', padding: '10px 20px'}}>
              {
                /* <MobileRowBetween style={{borderBottom: '1px solid rgba(255, 255, 255, 0.05)', padding: '10px 0'}}>
                <MobileRowBetween>
                  <Text fontWeight={400} fontSize={16} color={'#fff'}>FINN Passive Sailing APY</Text>
                  <Text fontWeight={400} fontSize={12} color={colorFFF} textAlign="right">（Weekly APY）</Text>
                </MobileRowBetween>
                <Text fontWeight={400} fontSize={16} color={colorBlue}>{apr ? (Number(apr).toFixed(0) + '%') : 'Loading...'}</Text>
              </MobileRowBetween> */
            }
              <MobileRowBetween style={{borderBottom: '1px solid rgba(255, 255, 255, 0.05)', padding: '10px 0'}}>
                <MobileRowBetween>
                  <Text fontWeight={400} fontSize={16} color={'#fff'}>Buy Back APY</Text>
                  <Text fontWeight={400} fontSize={12} color={colorFFF} textAlign="right">（Total Buy Back: {totalBuyBack?.toFixed(0, {groupSeparator: ','})} FINN）</Text>
                </MobileRowBetween>
                <Text fontWeight={400} fontSize={16} color={colorBlue}>{buyBackApr && buyBackApr !== '0' && !isNaN(Number(buyBackApr)) ? (((Number(buyBackApr)/100/365 + 1)**365 - 1)*100).toFixed(0) + '%' : '0%'}</Text>
              </MobileRowBetween>
              <MobileRowBetween style={{padding: '10px 0'}}>
                <MobileRowBetween style={{ width: 'fit-content' }}>
                  <Text fontWeight={400} fontSize={16} color={'#fff'}>Total Staked FINN</Text>
                </MobileRowBetween>
                <MobileRowBetween style={{ width: 'fit-content' }}>
                  <Text fontWeight={400} fontSize={12} color={colorFFF} textAlign="right">（{(Number(stakedFinn?.toExact())*100/Number(circulation?.toExact())).toString().split('.')[0]}% of Circulation）</Text>
                  <Text fontWeight={400} fontSize={16} color={colorBlue}>{stakedFinn ? stakedFinn.toFixed(0, {groupSeparator: ','}) + ' ｜ ' +  totalTomPrice : 'Loading...'}</Text> 
                </MobileRowBetween>
              </MobileRowBetween>
            </Border>
            <Border>
              <MobileProductCon>
                <Product 
                  src={'https://token-icons.vercel.app/icon/others/TOM.png'}
                  name={'TOM Balance'} num={finnInfo.tomBalance ? Number(Number(finnInfo.tomBalance.toExact()).toFixed(2)) : 0}></Product>
                <Product 
                  src={'https://token-icons.vercel.app/icon/others/FINN.png'}
                  name={'Staked FINN'} num={finnInfo.tomBalance ? Number(Number(Number(finnInfo.tomBalance.toExact()) * finnInfo.times).toFixed(2)) : 0 }></Product>
              </MobileProductCon>
              <ButtonPrimary
                style={{marginBottom: '1rem', padding: '0.5rem 1rem', width: isMobile ? '100%' : 'auto', borderRadius: '0.5rem'}}
                onClick={
                  () => {
                    const tokenAddress = uni ? FINNBAR_ADDRESS[uni?.chainId] : FINNBAR_ADDRESS[1285];
                    const tokenSymbol = 'TOM';
                    const tokenDecimals = 18;
                    const tokenImage = 'https://token-icons.vercel.app/icon/others/TOM.png';
                    if (!window.ethereum) {
                      return;
                    }
                    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
                    (window.ethereum as any).request({
                      method: 'wallet_watchAsset',
                      params: {
                        type: 'ERC20', // Initially only supports ERC20, but eventually more!
                        options: {
                          address: tokenAddress, // The address that the token is at.
                          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                          decimals: tokenDecimals, // The number of decimals in the token
                          image: tokenImage, // A string url of the token logo
                        },
                      },
                    }).then((ret:any)=>{
                      console.log(ret);
                    }).catch((err:any)=>{
                      console.error(err);
                    });
                  }
                }
              >{t('sampleAdd')}</ButtonPrimary>
              <Text fontWeight={400} fontSize={12} color={'#ffffff97'} textAlign={isMobile ? 'center' : 'left'}>{t('sampleNote')}</Text>
            </Border>
          </Row>
          <Border style={{width: isMobile ? '100%' : '37%', minHeight: '376px'}}>
            <RowNormal style={{marginBottom: '1.45rem'}}>
              <Text
                fontWeight={400}
                fontSize={16}
                color={type === 'Unstake' ? colorFFF : colorBlue}
                style={{cursor: 'pointer'}} marginRight={'1.5rem'}
                onClick={() => {
                  setType('StakeFINN')
                  setUpdater(Date.now())
                  type === 'Unstake' && setValue('')
                }}
              >Stake FINN</Text>
              <Text
                fontWeight={400}
                fontSize={16}
                color={type === 'Unstake' ? colorBlue : colorFFF}
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setType('Unstake')
                  setUpdater(Date.now())
                  type !== 'Unstake' && setValue('')
                }}
              >Unstake</Text>
            </RowNormal>
            <BorderYellow style={{marginBottom: '0.85rem'}}>1 TOM = { (finnInfo.times && !isNaN(finnInfo.times)) ? Number(finnInfo.times.toFixed(6)) + ' FINN': 'Loading...'}</BorderYellow>
            <RowBetween style={{marginBottom: '1.2rem'}}>
              <Text fontWeight={400} fontSize={14} color={colorFFF}>1 TOM = ${tomPrice}</Text>
            </RowBetween>
            <InpRow>
              <NumericalInput
                style={{backgroundColor:'transparent', flex: 1, color: colorBlue}}
                className="token-amount-input"
                value={value}
                placeholder={'0'}
                onUserInput={val => {
                  setValue(val)
                  console.log(val)
                }}
              />
              <RowBase>
                <ButtonSmall style={{marginRight: '0.25rem'}} onClick={() => {
                  if (type === 'Unstake') {
                    setValue(finnInfo.tomBalance ? finnInfo.tomBalance.toExact() : "")
                  } else {
                    setValue(finnInfo.finnBalance ? finnInfo.finnBalance.toExact() : "")
                  }
                  }}>MAX</ButtonSmall>
                |
                <Text fontWeight={400} fontSize={16} color={colorBlue} style={{marginLeft: '0.25rem'}}>{type === 'Unstake' ? 'TOM' : 'FINN'}</Text>
              </RowBase>
            </InpRow>
            <RowBetween style={{marginBottom: '1.2rem'}}>
              <Text fontWeight={400} fontSize={14} color={colorFFF}>Balance</Text>
              <Text fontWeight={400} fontSize={14} color={colorFFF}>{type === 'Unstake' ? (finnInfo.tomBalance ? finnInfo.tomBalance.toSignificant(6) : "Loading...") : (finnInfo.finnBalance ? finnInfo.finnBalance.toSignificant(6) : "Loading...") }&nbsp;&nbsp;{type === 'Unstake' ? 'TOM' : 'FINN'}</Text>
            </RowBetween>
            {
              !approved && !approving && <ButtonPrimary onClick={approve()}>{t('approve')}</ButtonPrimary>
            }
            {
              approving && !approved && <ButtonPrimary style={{cursor:"not-allowed", background: "#6f838a"}}>{t('Approving...')}</ButtonPrimary>
            }
            {
              approved && !waiting && <ButtonPrimary onClick={onStart()}>{type === 'Unstake' ? t('Unstake') : t('Stake')}</ButtonPrimary>
            }
            {
              approved && waiting && <ButtonPrimary style={{cursor:"not-allowed", background: "#6f838a"}}>{t('Waiting...')}</ButtonPrimary>
            }
          </Border>
        </MobileRowBetween>
      </SampleContent>
    </FinnBarContainer>
  )
}
