import React, { useContext } from 'react';
import styled from 'styled-components';
import { BorderExternal, BorderWhite } from './Index';
import { RowBetween } from './../Row';
import { Text } from 'rebass';
import faucetChild from '../../assets/png/Faucet/faucet_child.png';
import rightIcon from '../../assets/png/Faucet/right.png';
import { NavLink } from 'react-router-dom';
import { ButtonPrimary } from '../../components/Button';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { ThemeContext } from 'styled-components';

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: ${isMobile ? '7rem' : '12.5rem'};
`

const Child = styled.img`
  width: 80%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10%, -60%);
`

const RightIcon = styled.img`
  width: 20px;
`

const StyledNavLink = styled(NavLink)`
  outline: none;
  text-decoration: none;
`



export default function Success({transactionId}:{transactionId?: string}) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  return (
    <Content>
      <BorderExternal style={{width: '100%', background: theme.faucetBg, borderColor: theme.faucetBorder}}>
        <Child src={faucetChild} />
        <div style={{paddingTop: '100px', marginBottom: '30px'}}>
          <BorderWhite>
            <RowBetween style={{paddingBottom: '1.25rem', marginBottom: '1.25rem', borderBottom: '1px solid rgba(255, 255, 255, 0.15)'}}>
              <Text fontSize={22} color={'#00CF1F'}>{t('faucetSucc')}</Text>
              <RightIcon src={rightIcon} />
            </RowBetween>
            <RowBetween style={{flexDirection: 'column', alignItems: 'start', flex: 1}}>
              <Text fontSize={16} color={'#fff'} fontWeight={400} marginBottom={'10px'}>{t('faucetTransactionId')}</Text>
              <Text fontSize={16} color={'#4DD4FE'} fontWeight={400} style={{wordBreak: 'break-all'}}>{transactionId}</Text>
            </RowBetween>
          </BorderWhite>
        </div>
        <StyledNavLink to={'/swap'}>
          <ButtonPrimary>{t('faucetToSwap')}</ButtonPrimary>
        </StyledNavLink>
      </BorderExternal>
    </Content>
  )
}
