import React, { useMemo } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled, { css } from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import { ETHER, TokenAmount } from '@huckleberry/sdk'
import { ButtonPrimary } from '../Button'
import { StakingInfo, useFinnBarInfo } from '../../state/stake/hooks'
import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
import { Break, CardNoise, CardBGImage } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTranslation } from 'react-i18next'
import CurrencyLogo from '../CurrencyLogo'
import { Countdown } from '../../pages/Excursion/Countdown'
// import useUSDCPrice from '../../utils/useUSDCPrice'
// import { useActiveWeb3React } from '../../hooks'
// import { FINN } from '../../constants'
import { isMobile } from 'react-device-detect'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { useActiveWeb3React } from '../../hooks'
import { FINN } from '../../constants'
import { commafy2 } from '../../utils/commafy'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  grid-gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    // display: none;
  `};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius:24px;
  width: 100%;
  min-height: 156px;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme }) => theme.cardBg };
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
  backdrop-filter: blur(20px);
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

const MobileRowBetween = styled(RowBetween)`
  ${
    ({theme}) => theme.mediaWidth.upToSmall`
      ${
        isMobile && css`
          flex-wrap: wrap;
          width: 100%;
          & div {
            margin-bottom: 4px;
          }
        `
      }
    `
  }
`

// const APR = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
  ${
    ({theme}) => theme.mediaWidth.upToSmall`
      ${
        isMobile && css`
          flex-wrap: wrap;
          width: 100%;
          & div {
            margin-bottom: 4px;
          }
        `
      }
    `
  }
`

declare global {
  interface Window {
    tvlItems: any;
  }
}

const poolTails : any = {
  0: '#1',
  1: '#1',
  2: '#1',
  4: '#2',
  6: '#2',
  9: '#2',
  13: '#3',
  15: '#2',
  17: '#2',
  18: '#3',
}

export default function ExcursionCard({ stakingInfo, i }: { stakingInfo: StakingInfo; i: number }) {
  const { chainId } = useActiveWeb3React()

  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]
  const uni = chainId ? FINN[chainId] : undefined;

  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)
  const uniPrice = useUSDCPrice(uni)
  const finnInfo = useFinnBarInfo()

  const { t } = useTranslation()

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // get the color of the token
  const token = currency0 === ETHER ? token1 : token0
  const backgroundColor = useColor(token)

  // const { chainId } = useActiveWeb3React()
  // const uni = chainId ? FINN[chainId] : undefined

  // const uniPrice = useUSDCPrice(uni)

  // if (stakingInfo && uniPrice) {
  //   if (!window.tvlItems) {
  //     window.tvlItems = {}
  //   }
  //   window.tvlItems['excursion'] = (Number(stakingInfo?.totalStakedAmount.toFixed(0)) * Number(uniPrice.toFixed(8))).toFixed(0)
  // }

  const symbol = token1.symbol;

  const tomPrice = useMemo(() => {
    const price = uniPrice && finnInfo.times && !isNaN(finnInfo.times) && new TokenAmount(uni!, (1000000 * Number(finnInfo.times?.toFixed(6)) * Number(uniPrice?.toFixed(4))).toFixed(0)).multiply('1000000000000').toFixed(4)
    return price || '0'
  }, [finnInfo.times, uni, uniPrice])

  const myDeopsitPrice = useMemo(() => {
    let ret = (Number(stakingInfo.stakedAmount.toFixed(6)) * Number(tomPrice)).toFixed(6).toString()

    if (ret.indexOf(".") > 0) {
      ret = ret.replaceAll(/0+?$/g, "");
      ret = ret.replaceAll(/[.]$/g, "");
    }
    return ret
  }, [stakingInfo.stakedAmount, tomPrice])

  const tokenPrice = useUSDCPrice(token1)

  const stakedUsd = stakingInfo?.totalStakedAmount && tomPrice ? (Number(stakingInfo?.totalStakedAmount.toExact()) * Number(tomPrice)) : undefined;

  const apy = stakedUsd && stakingInfo?.totalRewardRate && tomPrice ? (Number(stakingInfo?.totalRewardRate.toExact()) * Number(tokenPrice?.toFixed(8)) * 3600*24 * 365 * 100 / Number(stakedUsd.toFixed(0))).toFixed(0) : '--' 

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
      <CardBGImage desaturate />
      <CardNoise />

      <TopSection>
        <CurrencyLogo currency={currency1} size={'36px'} />
        <TYPE.white fontWeight={600} fontSize={18} style={{ marginLeft: '8px' }}>
          {currency0.symbol + ' → ' + symbol} {poolTails[i]}
          <Countdown exactEnd={stakingInfo?.periodFinish} exactStart={stakingInfo?.periodStart} />
        </TYPE.white>

        <StyledInternalLink to={`/excursion/${currencyId(currency0)}/${i}`} style={{ width: '100%',color:'transparent' }}>
          <ButtonPrimary padding="8px" borderRadius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      <StatContainer>
        <MobileRowBetween>
          <TYPE.white>{t('totalDeposited')}</TYPE.white>
          <TYPE.yellow_o>
            {/* {`${stakingInfo?.totalStakedAmount.toFixed(0, { groupSeparator: ',' }) ?? '-'} TOM`} */}
            {commafy2(stakingInfo?.totalStakedAmount.toExact()).split('.')[0]} TOM &nbsp;|&nbsp; ${commafy2(stakedUsd).split('.')[0]}
            
            {
              apy && apy !== '--' && !isNaN(Number(apy)) && apy !== '0' ? ' 🔥 ' : null
            }
            {
              apy && apy !== '--' && !isNaN(Number(apy)) && apy !== '0' ? 'APR: ' + apy + '%' : null
            }

          </TYPE.yellow_o>
        </MobileRowBetween>
        { // TODO: HIDE FOR 24HOURS
          stakingInfo.pid < 20 && <MobileRowBetween>
            <TYPE.white> Pool rate </TYPE.white>
            <TYPE.color_o>{`${stakingInfo.totalRewardRate
              ?.multiply(`${60 * 60 * 24 * 7}`)
              ?.toFixed(0, { groupSeparator: ',' })} ${symbol} / week`}</TYPE.color_o>
          </MobileRowBetween>
        }
      </StatContainer>

      {isStaking && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black fontWeight={500} color={"#909699"} marginBottom={isMobile ? '4px' : ''}>
              <span style={{ fontSize: "14px" }}>My Deposit ≈ </span>
              <span style={{ fontWeight: "bold", fontSize: "18px", color: "#42B5D9" }}>
                {`$${commafy2(myDeopsitPrice)}`}
              </span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={"#909699"}>
              <span style={{ fontSize: "14px" }}>My Rate: </span>
              {/* <span  id="animate-zoom" role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
              ⚡
              </span> */}
              <span style={{ fontWeight: "bold", fontSize: "18px", color: "#42B5D9" }}>
                {`${stakingInfo.rewardRate?.multiply(`${60 * 60 * 24 * 7}`)?.toFixed(4, { groupSeparator: ',' })} `}
              </span>
              {`${symbol} / week`}
              
            </TYPE.black>
          </BottomSection>
        </>
      )}
    </Wrapper>
  )
}