import React, { useState, memo, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { RowBetween } from '../Row';
import { TYPE } from '../../theme';
import { useTranslation } from 'react-i18next';
import useLend, { LendAccountTokensItem } from '../../state/lend/hooks';
import { commafy2 } from '../../utils/commafy';
import { Button as RebassButton } from 'rebass/styled-components';
import { useLendContractV3 } from '../../hooks/useContract';
import { SUGGEST_GAS_PRICE } from '../../constants';
import { TransactionResponse } from '@ethersproject/providers';
import { useTransactionAdder } from '../../state/transactions/hooks';
import Loader from '../Loader';
import { useActiveWeb3React } from '../../hooks';
import { BigNumber } from 'bignumber.js';
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks';
import { TransactionDetails } from '../../state/transactions/reducer';
import { isMobile } from 'react-device-detect';
import { FINN } from '../../constants';
import { FINNBAR_ADDRESS } from '../../constants/abis/bridge';
import { useTokenBalance } from '../../state/wallet/hooks';

const Content = styled.div`
  background: ${({theme}) => theme.lendBg};
  border-radius: 100px;
  // min-width: 220px;
  display: flex;
  align-items: center;
  padding: 0;
  height: 34px;

  ${
    isMobile && css`
      margin-bottom: 20px;
    `
  }
`;
const RewardAmount = styled(RowBetween)`
  flex: 1;
  margin-right: 8px;
`
const Btn = styled(RebassButton)`
  display: flex;
  align-items: center;
  padding: 6px 20px;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  background: ${({theme}) => theme.primary7};
  height: 34px;
  margin: 0;
  border: none;

  &:disabled {
    cursor: not-allowed;
    background: #2C2F36;
    color: #999999;
  }
`
const StyledLoader = styled(Loader)`
  margin-right: 4px;
`
const Txt = styled.div`
  display: flex;
  flex: 1;
  min-width: 130px;
`;

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

const RewardBtn = memo(() => {
  const { t } = useTranslation();
  const { account, chainId } = useActiveWeb3React();
  const { personalAccount } = useLend();
  const LendContract = useLendContractV3();
  const addTransaction = useTransactionAdder();
  const allTransactions = useAllTransactions();
  const [showLoading, setShowLoading] = useState(false);
  const [hash, setHash] = useState('');
  const netId = chainId ? chainId : 1285;
  const finnbarBalance = useTokenBalance(FINNBAR_ADDRESS[netId], FINN[netId]);
  const finnbarBalanceBigNum = useMemo(() => {
    if (!finnbarBalance) return new BigNumber(0)
    return new BigNumber(finnbarBalance.toExact())
  }, [finnbarBalance]);
  const notEnough = useMemo(() => {
    if (!account || !personalAccount) return false;
    return finnbarBalanceBigNum.lt(personalAccount.comp_reward) || finnbarBalanceBigNum.eq(0)
  }, [account, finnbarBalanceBigNum, personalAccount])

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const marketList = useMemo(() => {
    if (!personalAccount || !personalAccount?.tokens?.length) return [];
    const arr: Array<String> = [];
    personalAccount.tokens.forEach((item: LendAccountTokensItem) => {
      const supply = new BigNumber(item.supply_balance)
      const borrow = new BigNumber(item.borrow_balance_underlying)
      if (!(supply.lte(0) && borrow.lte(0))) arr.push(item.token_address) ;
    })
    return arr;
  }, [personalAccount])

  const claimFn = async () => {
    if (!personalAccount?.comp_reward || !LendContract || !account || showLoading) return;
    setShowLoading(true);
    setHash('');
    await LendContract.claimComp(account, marketList, { gasLimit: 2000000, gasPrice: SUGGEST_GAS_PRICE() })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Claim accumulated rewards`
        })
        
        console.log('addTransaction')
        setHash(response.hash);
      })
      .catch((error: any) => {
        console.error(error)
        setShowLoading(false);
      })
  };

  useEffect(() => {
    if (!showLoading && !hash) return;
    const arr = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash).filter(tx => tx === hash);
    const isShowLoading = showLoading && !hash ? true : Boolean(arr.length);
    setShowLoading(isShowLoading);
  }, [hash, showLoading, sortedRecentTransactions]);
  
  return (
    <Content>
      <img style={{marginLeft:'2px',marginRight:'8px'}} height={'28px'} src="./images/FINN/05.png" alt="logo" />
      <RewardAmount>
        {
          account
          ?
            notEnough ?
              <Txt>
                <TYPE.white fontSize={14} fontWeight={400}>{t('Insufficient balance')}</TYPE.white>
              </Txt>
            :
              <TYPE.white fontSize={16} fontWeight={400}>{personalAccount ? commafy2(personalAccount.comp_reward) : null}&nbsp;{t('FINN')}</TYPE.white>
          :
            <TYPE.white fontSize={16} fontWeight={400}>{t('FINN')}</TYPE.white>
        }
        
      </RewardAmount>
      <Btn disabled={showLoading || !personalAccount || new BigNumber(personalAccount?.comp_reward).eq(0) || notEnough} onClick={claimFn}>
        { showLoading && <StyledLoader size="20px" stroke={'#fff'} /> }
        {t('Claim')}
      </Btn>
    </Content>
  )
});

export default RewardBtn;