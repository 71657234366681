/* eslint-disable react/jsx-pascal-case */
import React, { useState, useCallback, useContext } from 'react'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled, { ThemeContext } from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'
import ProgressCircles from '../ProgressSteps'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { CurrencyAmount, TokenAmount } from '@huckleberry/sdk'
import { useActiveWeb3React } from '../../hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useActivityContract } from '../../hooks/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { useDerivedStakeInfo } from '../../state/stake/hooks'
import { wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { ACTIVITY_ADDRESS } from '../../constants/abis/bridge'
import { SUGGEST_GAS_PRICE } from '../../constants'
import { useCurrency } from '../../hooks/Tokens'

const HypotheticalRewardRate = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;

  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: any
  userLiquidityUnstaked: TokenAmount | undefined | CurrencyAmount
}

export default function StakingModal({ isOpen, onDismiss, stakingInfo, userLiquidityUnstaked }: StakingModalProps) {
  const { chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  // @ts-ignore
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, stakingInfo.stakedAmount.token, userLiquidityUnstaked)
  const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)

  let hypotheticalRewardRate: TokenAmount = new TokenAmount(stakingInfo.rewardRate.token, '0')
  if (parsedAmountWrapped?.greaterThan('0')) {
    hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
      stakingInfo.stakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalRewardRate
    )
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  const dummyPair = undefined
  // const pairContract = usePairContract(dummyPair.liquidityToken.address)

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(
    parsedAmount,
    chainId ? ACTIVITY_ADDRESS[chainId] : undefined
  )

  // const isArgentWallet = useIsArgentWallet()
  const bridgeMinerContract = useActivityContract()
  async function onStake() {
    setAttempting(true)
    if (bridgeMinerContract && parsedAmount && deadline) {
      // if ('APPROVED' === ApprovalState.APPROVED) {
        await bridgeMinerContract
          .deposit(stakingInfo.pid, `0x${parsedAmount.raw.toString(16)}`, { gasLimit: 1200000, gasPrice: SUGGEST_GAS_PRICE(), value: `0x${parsedAmount.raw.toString(16)}` })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Deposit MOVR`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      // } else if (signatureData) {
      //   bridgeMinerContract
      //     .stakeWithPermit(
      //       `0x${parsedAmount.raw.toString(16)}`,
      //       signatureData.deadline,
      //       signatureData.v,
      //       signatureData.r,
      //       signatureData.s,
      //       { gasLimit: 350000, gasPrice: SUGGEST_GAS_PRICE }
      //     )
      //     .then((response: TransactionResponse) => {
      //       addTransaction(response, {
      //         summary: `Deposit TOM`
      //       })
      //       setHash(response.hash)
      //     })
      //     .catch((error: any) => {
      //       setAttempting(false)
      //       console.log(error)
      //     })
      // } else {
      //   setAttempting(false)
      //   throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      // }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])
  const currencyA = useCurrency('MOVR')

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90} bg={theme.finnbarModalBg}>
      {!attempting && !hash && (
        <ContentWrapper gap="md">
          <RowBetween>
            <TYPE.mediumHeader>Deposit</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            color={theme.modalCardTxtActive}
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={currencyA}
            pair={dummyPair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to deposit: '}
            id="stake-liquidity-token"
          />

          <HypotheticalRewardRate dim={!hypotheticalRewardRate.greaterThan('0')}>
            <div>
              <TYPE.black fontWeight={600}>Weekly Rewards</TYPE.black>
            </div>

            <TYPE.black>
              {hypotheticalRewardRate.multiply((60 * 60 * 24 * 7).toString()).toSignificant(4, { groupSeparator: ',' })}{' '}
              {stakingInfo?.tokens[1]?.symbol} / week
            </TYPE.black>
          </HypotheticalRewardRate>

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={approveCallback}
              altDisabledStyle={true}
              // confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
              // disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
              disabled={true}
            >
              Approve
            </ButtonConfirmed>
            <ButtonError
              altDisabledStyle={true}
              // disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              disabled={!!error}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? 'Deposit'}
            </ButtonError>
          </RowBetween>
          <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} />
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss} isTOM={true} bg={theme.finnbarModalBg}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Depositing MOVR</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} MOVR</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash} bg={theme.finnbarModalBg}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.white fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} MOVR</TYPE.white>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
