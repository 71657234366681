import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { RowBetween } from '../../components/Row';
import Balance from '../../components/Lend/Banlance';
import { useTranslation } from 'react-i18next';
import ReservoirList from '../../components/Lend/ReservoirList';
import { isMobile } from 'react-device-detect';
import { LendApyContext } from '../../components/Lend/LendApyBtn';

const PersonalLine = styled(RowBetween)`
  align-items: center;
  margin-bottom: 20px;

  ${
    isMobile && css`
      flex-direction: column;
    `
  }
`;

const Reservoir = () => {
  const { t } = useTranslation();
  const [totalSupply, setTotalSupply] = useState('0');
  const [totalBorrow, setTotalBorrow] = useState('0');
  const updateFn = (supply: string, borrow: string) => {
    setTotalSupply(supply);
    setTotalBorrow(borrow);
  }
  return (
    <>
      <LendApyContext.Consumer>
        {
          state => (
            <>
              <PersonalLine>
                <Balance type="deposit" title={t('Total Deposits')} value={totalSupply}></Balance>
                <Balance type="borrow" title={t('Total Borrowings')} value={totalBorrow}></Balance>
              </PersonalLine>
              <ReservoirList
                updateFn={(supply, borrow) => updateFn(supply, borrow)}
                lendApyState={state}
              ></ReservoirList>
            </>
          )
        }
      </LendApyContext.Consumer>
    </>
  );
}


export default Reservoir;