import { BigNumber } from '@ethersproject/bignumber'
import { Token, TokenAmount } from '@huckleberry/sdk'
import { FINN } from '../constants'
import { AIRDROP_ADDRESS, BRIDGE_MINER_ADDRESS } from '../constants/abis/bridge'
import { useActiveWeb3React } from '../hooks'
import { useBuyBackContract, useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalSupply(token?: Token): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const totalSupply: BigNumber = useSingleCallResult(contract, 'totalSupply')?.result?.[0]

  return token && totalSupply ? new TokenAmount(token, totalSupply.toString()) : undefined
}

export function useTotalBurned(token?: Token): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const totalBurned: BigNumber = useSingleCallResult(contract, 'balanceOf', ['0x0000000000000000000000000000000000000001'])?.result?.[0]

  return token && totalBurned ? new TokenAmount(token, totalBurned.toString()) : undefined
}

export function useNotMined(token?: Token): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const { chainId } = useActiveWeb3React()
  const farming: BigNumber = useSingleCallResult(contract, 'balanceOf', [BRIDGE_MINER_ADDRESS[chainId ? chainId : 1285]])?.result?.[0]
  const airdrop: BigNumber = useSingleCallResult(contract, 'balanceOf', [AIRDROP_ADDRESS[chainId ? chainId : 1285]])?.result?.[0]

  return token && farming ? new TokenAmount(token, farming.add(airdrop).toString()) : undefined
}

export function useTotalBuyBack(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()

  const contract = useBuyBackContract()

  const totalBuyBack: BigNumber = useSingleCallResult(contract, 'totalBuyBack', [])?.result?.[0]

  return chainId && totalBuyBack ? new TokenAmount(FINN[chainId], totalBuyBack.toString()) : undefined
}

export function useDailyBuyBack(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()

  const contract = useBuyBackContract()

  const day = Math.floor(Date.now()/(1000*3600*24)) - 1

  const dailyBuyBack: BigNumber = useSingleCallResult(contract, 'dailyBuyBack', [day])?.result?.[0]

  return chainId && dailyBuyBack ? new TokenAmount(FINN[chainId], dailyBuyBack.toString()) : undefined
}

export function useWeeklyBuyBack(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()

  const contract = useBuyBackContract()

  const day = Math.floor(Date.now()/(1000*3600*24)) - 1

  const weekBuyBack: BigNumber = useSingleCallResult(contract, 'weekBuyBack', [((day+1)/7 - 1).toFixed(0)])?.result?.[0]

  return chainId && weekBuyBack ? new TokenAmount(FINN[chainId], weekBuyBack.toString()) : undefined
}
