import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import FINN from '../../assets/images/FINN.png'
import { Button } from "antd";
import { useActiveWeb3React } from '../../hooks'
import { useHttp } from '../../hooks/useHttps'
import { useAirDropContract } from '../../hooks/useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import { LoadingView, SubmittedView } from '../ModalViews'
import { AutoColumn } from '../Column'
import { TYPE, CloseIcon, ExternalLink } from '../../theme'
import Web3 from 'web3'
import Modal from '../Modal';
import { RowBetween, AutoRow } from '../Row'
import DetailImg from '../../assets/images/detail@2x.png'
import { SUGGEST_GAS_PRICE } from '../../constants';

interface AirDropRet {
  userAddr:string,
  value: string,
  r: string,
  s: string,
  v: string
}

const web3 = new Web3()

export default function AirDropModal({ showAirDropModal, setShowAirDropModal }: { showAirDropModal: boolean, setShowAirDropModal: any }) {
  const client = useHttp();
  const [aridropAmount, setAirDropAmount] = useState<string | number>('0.0')
  const [airdrop, setAirDrop] = useState<AirDropRet | null>(null)
  const [inputValue, setInputValue] = useState('')
  const { account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const airDropContract = useAirDropContract()
  const [attempting, setAttempting] = useState(false)
  const [hash, setHash] = useState<string | undefined>()
  // const [finished, setFinished] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const queryAirDropAmount = useCallback(
    async (address: string) => {
      if (!airDropContract) return;
      // let [totalCount, sentCount] = await Promise.all([airDropContract.totalCount(), airDropContract.sentCount()]);
      // console.log('!88', totalCount, sentCount)
      // if (Number(sentCount.toString()) >= Number(totalCount.toString()) && Number(totalCount.toString()) > 0) {
      //   setFinished(true);
      //   return;
      // }


      setAirDropAmount('Loading...')

      client({
        params: { address },
      }).then(async data => {
        console.log('!101', data)
        if (data.value === undefined) return data;
        const tokenOwner = await airDropContract.tokenOwner();
        const digest = web3.utils.keccak256(web3.eth.abi.encodeParameters(['address','uint256','address'], [address, web3.utils.toHex(data.value), tokenOwner]))
        const airDropAmount = await airDropContract.airDropAmount(digest)

        const _enabled = await airDropContract.enabled()
        if (_enabled) {
          setEnabled(_enabled)
        }
        
        if (airDropAmount.toString() === data.value) {
          data.value = '0.0';
        }
        return data
      }).then(data => {
        setAirDrop(data)
        setAirDropAmount(((data.value || 0) / Math.pow(10, 18)).toFixed(2))
      }).catch(err=>{
        console.error(err);
        setAirDropAmount('0');
      })
    },
    [airDropContract, client]
  )

  // useEffect(() => {
  //   if (!inputValue) return;
  //   queryAirDropAmount(inputValue);
  // }, [inputValue, client, queryAirDropAmount])

  useEffect(() => {
    account && setInputValue(account)
  }, [account])

  const handleInput = (e:React.FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value)
    // queryAirDropAmount(e.currentTarget.value)
  }

  const wrappedOnDismiss = () => {
    setHash(undefined)
    setAttempting(false)
    setShowAirDropModal(false)
  }

  const handleClaim = async () => {
    if (airdrop && airDropContract) {
      setAttempting(true)
        await airDropContract.claim(airdrop.value, { r: airdrop.r, s: airdrop.s, v: airdrop.v }, {gasPrice: SUGGEST_GAS_PRICE() })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Claim FINN Airdrops`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  return (
    <Modal isOpen={showAirDropModal} onDismiss={wrappedOnDismiss} maxHeight={90}>
    {
      !attempting && !hash &&
      <ContentWrapper>
        <ModalTitle>Claim Airdrop</ModalTitle>

        <CloseIcon onClick={() => setShowAirDropModal(false)} style={{ position: 'absolute', top: '30px', right: '30px' }} stroke="white" />
        <ContentOne>
          <ClaimTo>Claim to the connected wallet :</ClaimTo>
          <AddrContent>
            <InputCustom value={inputValue} onChange={handleInput}/>
            <BtnCustom  onClick={()=>{queryAirDropAmount(inputValue)}} style={{right:"16px"}} >Check</BtnCustom>
          </AddrContent>
          <div style={{ marginBottom: "10px" }}>
            <RowBetween>
              <AutoRow>
                <ItemName>Airdrop starts at: </ItemName>
                <ItemContent>1) 25% on February 9, 2022</ItemContent>
                <ItemContent>2) 25% on February 16, 2022</ItemContent>
                <ItemContent>3) 25% on February 23, 2022</ItemContent>
                <ItemContent>4) 25% on March 2, 2022</ItemContent>
              </AutoRow>
              <AutoRow justify="flex-end">
                <ExternalLink href="https://medium.com/huckleberrydex/chapter-10-airdrops-part-3-ad084c37ab99">
                  <BtnRule>
                    <img src={DetailImg} alt="" style={{width: '18px', margin: '0 4px 0 0'}} />
                    <span style={{textDecoration: 'none !important'}}>Airdrop rules</span>
                  </BtnRule>
                </ExternalLink>
              </AutoRow>
            </RowBetween>
            
          </div>
          {/* {
            showRule &&
            (
              <div>
                <ItemName>Rules : </ItemName>
                <ItemContent>You must claim the airdrop rewards before 0:00 August 24 (UTC), otherwise, your rewards will be removed.</ItemContent>
              </div>
            )
          } */}
        </ContentOne>
        <ContentTwo>
          <img src={FINN} alt={'FINN'}/>
          {
            <RewardArea>
              <span style={{ color: "#172329", fontSize: "16px" }}>Reward Available</span>
              <p style={{ margin: 0 }}>
                <Amount>{aridropAmount}</Amount>
                <Unit>FINN</Unit>
              </p>
              <BtnCustom onClick={handleClaim} color="#572FA3" background="rgba(87, 47, 163, 0.05)" style={{top: "34px"}} disabled={aridropAmount <= 0 || !enabled || (attempting && !hash)}>Claim</BtnCustom>
            </RewardArea>
          }
          {
            // finished && <RewardArea>
            //   <span style={{ color: "#172329", fontSize: "16px" }}>All 500 airdrops 'ave already been spoken for. Stay the course.  There will be other opportunities.</span>
            // </RewardArea>
          }
        </ContentTwo>
      </ContentWrapper>
    }
    {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Claiming FINN</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Claimed FINN!</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 480px;
  background-color: #572FA3;
`;

const ModalTitle = styled.span`
  font-size: 22px;
  position: relative;
  left: 46px;
  color: #FBFAFC;
  font-weight: 500;
  top: 30px;
`

const ContentOne = styled.div`
  position: absolute;
  min-height: 337px;
  width: 100%;
  background-color: #FDFCFD;
  border-radius: 20px;
  bottom: 0;
  padding-top: 80px;
  padding-left: 35px;
  padding-right: 35px;
`;

const ContentTwo = styled.div`
  position: absolute;
  height: 108px;
  width: 85%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 50px 0px rgba(87, 47, 163, 0.2);
  top: 87px;
  left: 37px;
  display: flex;
  padding: 24px;
`;

const RewardArea = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 15px;
  padding: 4px 0;
`

const Amount = styled.span`
  font-size: 32px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #572FA3;
  display: inline-block;
  margin-right: 2px;
`

const Unit = styled.span`
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #172329;
`

const ClaimTo = styled.span`
  font-size: 14px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #172329;
`

const AddrContent = styled.div`
  height: 80px;
  background: #F5F5F5;
  border-radius: 16px;
  margin-top: 10px;
  line-height: 79px;
  padding: 0 18px;
  position: relative;
  margin-bottom: 30px;
`

const BtnCustom = styled(Button)<{background?: string; color?: string}>`
  height: 40px;
  border-radius: 16px!important;
  background: ${({background}) => background ? background : '#572FA3'};
  font-size: 16px;
  line-height: 16px!important;
  position: absolute;
  color: ${({color}) => color ? color : '#fff'};
  top: 18px;
  right: 10px;
  border: none!important;
  width: 80px;
  cursor: pointer;
  :focus-visible {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`

const BtnRule = styled(Button)`
  background: rgba(66, 181, 217, 0.04);
  width: 144px;
  height: 40px;
  border-radius: 100px;
  color: #42B5D9;
  border: none!important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ItemName = styled.span`
  color: #ABABAB;
  font-size: 14px;
`

const ItemContent = styled.span`
  color: #1E292F;
  font-size: 14px;
`

const InputCustom = styled.input`
  color: #172329;
  font-size: 16px;
  border: none;
  height: 60%;
  width: 70%;
  outline: none;
  background-color: #F5F5F5;
  :focus-visible {
    border: none;
    outline: none;
  }
`
