
import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import movrIcon from '../../assets/images/MovrIcon.png'
import { isMobile } from 'react-device-detect'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: #404450;
  border-radius: 10px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: #404450;
    opacity:0.9;
  }

  svg {
    margin-top: 2px;
  }
`

const MenuFlyout = styled.span`
  min-width: 9.125rem;
  background: #404450;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3.3rem;
  right: 0rem;
  z-index: 100;
  padding: 10px;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    top: -21.25rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    ${
      isMobile && css`
        top: 0;
        transform: translateY(-110%);

        background: rgba(0, 0, 0, 0.92);
        border: 1px solid rgba(255, 255, 255, 0.2);
      `
    }
  `};
`

export default function NetworkSwitch() {
  const node = useRef<HTMLDivElement>()
  const [networkStatus, setNetworkStatus] = useState(false)
  const open = useModalOpen(ApplicationModal.NETWORK)
  const toggle = useToggleModal(ApplicationModal.NETWORK)
  useOnClickOutside(node, open ? toggle : undefined )

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <img src={movrIcon} alt="movr"/>
      </StyledMenuButton>
      {
        open &&
        <MenuFlyout>
          <NetworkItem onClick={() => setNetworkStatus(!networkStatus)} selected={!networkStatus} style={{ marginBottom: "10px" }}>
            <StyledLink selected={!networkStatus} href={"https://moonriver.huckleberry.finance/"}>Moonriver</StyledLink>
          </NetworkItem>
          <NetworkItem onClick={() => setNetworkStatus(!networkStatus)} selected={networkStatus}>
            <StyledLink selected={networkStatus} href={"https://clover.huckleberry.finance/"}>Clover-P</StyledLink>
          </NetworkItem>
        </MenuFlyout>
      }
    </StyledMenu>
  )
}

const NetworkItem = styled.div<{
  selected: boolean;
}>`
  width: 130px;
  line-height: 40px;
  height: 40px;
  cursor: pointer;
  background: rgba(255, 199, 11, 0.1);
  border-radius: 10px;
  color: ${({ selected }) => (isMobile ? (selected ? '#A9FFE0' : '#fff') : (selected ? '#FFC70B' : '#FFFFFF'))}
  border: 1px solid ${({ selected }) => (isMobile ? (selected ? '#A9FFE0' : 'rgba(255, 255, 255, 0.05)') : selected ? '#FFC70B' : 'rgba(255, 255, 255, 0.05)')}
`

const StyledLink = styled.a<{
  selected: boolean;
}>`
  text-decoration: none;
  color: ${ ({selected}) => isMobile ? selected ? '#A9FFE0' : '#fff' : 'yellow'};
`